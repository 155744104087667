/* eslint-disable vue/no-unused-components */
<template>
  <div :class="{ 'container-fluid': !hideHeader }">
    <div class="container- mt-1">
      <template v-if="!hideHeader">
        <p-progress-bar v-if="mxLoading" style="height: 0.33em" mode="indeterminate" />
        <div class="bg-white p-2 rounded-4">
          <form class="bg-" method="post" accept-charset="utf-8" action="">
            <div class="row py-0 p-fluid grid formgrid">
              <div class="col field">
                <form class="d-flex">
                  <div class="input-group me-4">
                    <div class="p-inputgroup">
                      <PInputText
                        type="text"
                        v-model="mxSearch"
                        :placeholder="'Rechercher par'"
                      />
                      <p-multi-select
                        v-model="mxSearchFields"
                        :options="searchFieldsOptions.filter((el) => el.visible)"
                        optionLabel="label"
                        optionValue="value"
                        :editable="true"
                      />
                    </div>
                  </div>
                </form>
              </div>
              <div class="col-md-6 col-sm-12 d-flex">
                <div class="input-group me-4">
                  <div class="p-inputgroup">
                    <p-dropdown
                      class="flex-grow-1 me-2"
                      v-model="statutParcours"
                      :options="statusOptions"
                      optionLabel="label"
                      optionValue="value"
                      :placeholder="'Statut parcours DE'"
                      display="chip"
                    />
                  </div>
                </div>
              </div>
              <div class="col-auto">
                <div class="d-grid gap-2">
                  <a
                    href="#"
                    @click.prevent="mxSubmitQuery"
                    class="style_btn btn btn-primary"
                    id="button-query"
                    ><i class="bi bi-search"></i
                  ></a>
                </div>
              </div>
              <div class="col-auto">
                <button
                  type="button"
                  @click.prevent="showAdvancedFilter = !showAdvancedFilter"
                  class="style_btn btn btn-warning"
                  id="button-query"
                >
                  <i class="bi bi-filter"></i>
                </button>
              </div>
            </div>
          </form>
          <transition>
            <div class="border p-3 rounded shadow-0 mt-2" v-if="showAdvancedFilter">
              <div class="row p-fluid grid">
                <div class="field col-12 col-md-3 col-sm-12 mb-3">
                  <label for="dateDeb">Date de début</label>
                  <p-calendar
                    placeholder="Date de début"
                    v-model="dateDeb"
                    locale="fr"
                    :showIcon="true"
                    :manualInput="false"
                  />
                </div>
                <div class="field col-12 col-md-3 col-sm-12 mb-3">
                  <label for="dateFin">Date de fin</label>
                  <p-calendar
                    placeholder="Date de fin"
                    class="flex-grow-1"
                    v-model="dateFin"
                    :showIcon="true"
                    :manualInput="false"
                  />
                </div>
                <template v-for="(option, index) in filterOptions">
                  <div
                    class="col-12 col-sm-6 col-md-3 col-sm-12 mb-3"
                    v-if="option.visible"
                    :key="index"
                  >
                    <label :for="option.labelField">{{ option.label }}</label>

                    <AsyncMultiSelect
                      v-model="option.value"
                      :multiple="true"
                      :queryUrl="option.queryUrl"
                      :optionLabel="option.labelField"
                      :placeholder="option.label"
                      :emitObject="false"
                      :displayMode="option.displayMode"
                      :searchOptions="[option.labelField]"
                    />
                  </div>
                </template>
              </div>
              <div class="d-flex justify-content-end">
                <PButton
                  class="p-button-danger p-button- py-1 p-button-sm ms-1"
                  @click.prevent.stop="reinitialiserFiltres"
                >
                  Réinitialiser les filtres
                </PButton>
              </div>
            </div>
          </transition>
        </div>
        <p-divider />
        <div class="d-flex flex-wrap mb-4 justify-content-between align-items-center">
          <div class="my-1 flex-fill">
            <h4>Liste des parcours de demandeur</h4>
          </div>

          <div v-if="$can('export', 'Azoli')" class="btn-group ms-4">
            <PSplitButton
              label="Exporter"
              :model="exportActions"
              class="p-button-outlined p-button-primary"
            />
          </div>
          <div class="btn-group ms-4">
            <button
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title="Affichage mode tableau"
              @click.prevent="viewMode = 'card'"
              class="btn style_btn btn-md"
              :class="{
                'btn-success': viewMode == 'card',
                'btn-light': viewMode == 'table',
              }"
            >
              <i class="bi-grid" />
            </button>
            <button
              @click.prevent="viewMode = 'table'"
              class="btn style_btn"
              :class="{
                'btn-light': viewMode == 'card',
                'btn-success': viewMode == 'table',
              }"
            >
              <i class="bi-table" />
            </button>
          </div>
        </div>
      </template>
      <template v-if="!mxLoading">
        <div class="bg-">
          <div class="bg-white p-3 border">
            <div class="table-responsive">
              <b-table
                show-empty
                empty-text="Aucune donnée à afficher"
                :fields="tableFields"
                class="align-middle"
                striped
                select-mode="single"
                ref="table"
                selectable
                @row-selected="onRowSelected"
                :sort-by.sync="mxPagination.sortBy"
                :sort-desc.sync="mxPagination.sortDesc"
                responsive="sm"
                sort-icon-left
                :items="tableData"
              >
                <template #head()="data">
                  <span>{{ data.label }} </span>
                </template>
                <template #head(#)="data">
                  <div class="form-check form-check-inline">
                    <input
                      @change="selectAll"
                      class="form-check-input"
                      type="checkbox"
                      :id="`${data.index}`"
                      name="disponible"
                      :value="false"
                    />
                  </div>
                </template>
                <!-- A virtual column -->
                <template #cell(index)="data">
                  {{ data.index + 1 }}
                </template>

                <template #cell(#)="data">
                  <div class="form-check form-check-inline">
                    <input
                      @input="selectionChanged($event, data)"
                      :checked="data.rowSelected"
                      class="form-check-input"
                      type="checkbox"
                      :id="`${data.index}`"
                      name="disponible"
                    />
                  </div>
                </template>

                <template #cell(statutParcours)="{ item: pc }">
                  <div class="d-flex justify- mb-3">
                    <PBadge
                      :value="pc.statutParcours"
                      :severity="getStatusColor(pc.statutParcours)"
                    />
                  </div>
                </template>
                <template #cell(createdDate)="{ item: de }">
                  <div class="d-flex justify- mb-3">
                    {{ $dayjs(de.createdDate).format("DD/MM/YYYY") }}
                  </div>
                </template>

                <template #row-details="row">
                  <div class="bg- pa-2">
                    <PTabView :activeIndex.sync="activeTab">
                      <PTabPanel header="Prise En Charge" v-if="activeTab == 0">
                        <PriseEnCharge
                          :demandeurId="row.item.demandeurId"
                          :priseEnChargeId="row.item.priseEnChargeId"
                        />
                      </PTabPanel>
                    </PTabView>
                  </div>
                </template>

                <template #cell(actions)="row">
                  <div class="d-flex justify-content-end">
                    <button
                      class="btn btn-sm py-1 btn-outline-primary me-1"
                      @click="mxToggleDetails(row, tableData)"
                    >
                      <i class="bi bi-journal-check"></i>
                    </button>
                  </div>
                </template>
                <!-- Optional default data cell scoped slot -->
                <template #cell()="data">
                  <span>{{ data.value }}</span>
                </template>
              </b-table>
            </div>
            <div class="d-flex flex-wrap justify-content-between align-items-center">
              <p>
                Affichage de l’élément
                {{
                  (mxPagination.page <= 0 ? 0 : mxPagination.page - 1) *
                  mxPagination.rowsPerPage
                }}
                à
                {{
                  (mxPagination.page <= 0 ? 1 : mxPagination.page) *
                  mxPagination.rowsPerPage
                }}
                de {{ mxTotalCount }} éléments
              </p>
              <b-pagination
                v-model="mxPagination.page"
                :total-rows="mxTotalCount"
                :per-page="mxPagination.rowsPerPage"
                aria-controls="my-table"
              ></b-pagination>
            </div>
          </div>
        </div>
      </template>
      <template v-else>
        <div>
          <PDataTable
            :value="
              [1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((e) => {
                e;
              })
            "
          >
            <PColumn
              v-for="f in tableFields"
              :key="f.key"
              :field="f.key"
              :header="f.label"
            >
              <template #body>
                <PSkeleton></PSkeleton>
              </template>
            </PColumn>
          </PDataTable>
        </div>
      </template>
      <div class="mb-4 bg-light" v-if="!mxLoading && mxFilteredItems.length == 0">
        <div
          style="min-height: 200px"
          class="no-content pa-4 d-flex flex-column justify-content-center align-items-center"
        >
          <span class="text-muted mb-3">Votre porte-feuille est vide</span>
          <a
            v-if="$can('create', 'Azoli')"
            @click.prevent="$router.push({ name: 'espace.ce.de.add' })"
            href=""
            class="style_btn btn btn-sm btn-outline-primary"
            >Ajouter un PPI</a
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { paginatorMixin } from "@/mixins/paginator-mixin";
import CAutoComplete from "../../../components/common/CAutoComplete.vue";
import AsyncMultiSelect from "../../../components/common/AsyncMultiSelect.vue";

// import * as XLSX from 'xlsx'
import PriseEnCharge from "./PriseEnCharge.vue";

export default {
  props: {
    agentId: {
      type: Number,
      default: () => null,
    },
    extraQueryArg: {
      type: String,
      default: "",
    },
    hideStatistic: {
      type: Boolean,
      default: false,
    },
    hideHeader: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    PriseEnCharge,
    CAutoComplete,
    AsyncMultiSelect,
  },
  mixins: [paginatorMixin],
  data() {
    return {
      selected: [],
      search: "",
      dateDeb: null,
      dateFin: null,
      activeTab: 0,
      showAdvancedFilter: false,
      viewMode: localStorage.getItem("viewMode") || "card", //card, table
      exportActions: [
        {
          label: "PDF",
          icon: "pi pi-file-pdf",
          command: () => {},
        },
        {
          label: "Excel",
          icon: "pi  pi-file-excel",
          command: () => {},
        },
      ],
      searchFields: ["reference"],
      searchFieldsOptions: [
        {
          label: "Titre",
          value: "titre",
          visible: true,
        },
      ],
      filterOptions: [
        {
          column: "programme.id",
          value: [],
          label: "Programme",
          dropdown: true,
          labelField: "libelle",
          displayMode: "mega-menu",
          valueKey: "id",
          queryUrl: "programmes",
          visible: true,
        },
        {
          column: "secteur.id",
          value: [],
          label: "Secteur d'Activité",
          dropdown: true,
          labelField: "libelle",
          displayMode: "classic",
          valueKey: "id",
          queryUrl: "naemas",
          visible: true,
        },
        {
          column: "metier.id",
          value: "",
          label: "Métier",
          valueKey: "id",
          dropdown: true,
          dropdown: true,
          displayMode: "classic",
          labelField: "libelle",
          queryUrl: "romes",
          visible: true,
        },
        {
          column: "departement.id",
          value: "",
          labelField: "nom",
          label: "Départements",
          queryUrl: "departements",
          displayMode: "mega-menu",
          multiple: true,
          valueKey: "id",
          dropdown: true,
          visible: true,
        },
        {
          column: "commune.id",
          value: "",
          labelField: "nom",
          multiple: true,
          valueKey: "id",
          label: "Communes",
          displayMode: "classic",
          queryUrl: "communes",
          dropdown: true,
          visible: true,
        },
        {
          column: "arrondissement.id",
          value: "",
          labelField: "nom",
          label: "Arrondissements",
          multiple: true,
          valueKey: "id",
          queryUrl: "arrondissements",
          displayMode: "classic",
          dropdown: true,
          visible: true,
        },
        {
          column: "ville.id",
          value: "",
          labelField: "nom",
          multiple: true,
          label: "Village/Quartier(s)",
          valueKey: "id",
          displayMode: "classic",
          queryUrl: "villes",
          dropdown: true,
          visible: true,
        },
      ],
      filters: {
        skills: [],
        availabilities: [],
        price_min: null,
        price_max: null,
        departement: null,
        _queryOptions: [
          {
            column: "departement.nom",
            value: null,
            label: "Département",
          },
          {
            column: "commune.nom",
            value: null,
            label: "Commune",
          },
        ],
        ordering: "",
        // _orderingOptions: {
        // }
      },
    };
  },
  created() {
    this.queryStatistics();
  },
  watch: {
    viewMode() {
      localStorage.setItem("viewMode", this.viewMode);
    },
    extraQueryArg() {
      this.queryStatistics();
    },
  },
  computed: {
    ...mapGetters({
      dossier: "demandeur/demandeur",
    }),
    tableFields() {
      let fields = [
        "#",
        // 'index',
        { key: "reference", label: "Ref" },
        { key: "raisonAnnulation", label: "Raison annulation" },
        { key: "statutParcours", label: "Status." },
        { key: "createdDate", label: "Date Création" },
      ];

      fields = fields.concat(["actions"]);

      return fields;
    },
    tableData() {
      return this.mxFilteredItems.map((item) => {
        return { ...item };
      });
    },
  },
  methods: {
    ...mapActions({}),
    getStatusColor(status) {
      let color = "primary";
      if (status == "GELE") {
        color = "danger";
      }
      return color;
    },
    priseEnCharge(de) {
      this.$router.push({
        name: "espace.common.de.followup",
        params: { userRole: this.$route.params.userRole, demandeurId: de.id },
      });
    },
    statisticBlockSelected(status) {
      this.mxSetOptionValue("status", status, true);
    },
    queryStatistics() {
      this.mxInitializePaginator({
        queryModel: "parcoursDemandeur",
        search: this.search,
        fetcherMethod: "parcoursDemandeur/fetchParcoursDemandeurs",
        dataGetter: "parcoursDemandeur/parcoursDemandeurs",
        paginationGetter: "parcoursDemandeur/pagination",
        autoSubmitQuery: false,
        pagination: {
          sortBy: "id",
          descending: false,
          page: 0,
          rowsPerPage: 8,
          sortingParam: `sort=id,desc`,
        },
        filterOptions: this.filterOptions,
        extraQueryArgsParamBuilder: () => {
          const dateDeb = (this.dateDeb || new Date("2021-12-31"))
            .toISOString()
            .substring(0, 10);
          const dateFin = (this.dateFin || new Date("2080-12-31"))
            .toISOString()
            .substring(0, 10);

          let paramPlus = "";

          let params = `dateDeb=${dateDeb}&dateFin=${dateFin}&${this.extraQueryArg}&${paramPlus}`;
          return params;
        },
        searchFields: this.searchFields,
      });
    },

    selectionChanged(evt, data) {
      console.log(evt.target.checked, data);

      if (evt.target.checked) {
        this.$refs.table.selectRow(data.index);
      } else {
        this.$refs.table.unselectRow(data.index);
      }
    },
    onRowSelected(items) {
      this.selected = items;
    },
    selectAll(evt) {
      if (evt.target.checked) {
        this.$refs.table.selectAllRows();
      } else {
        this.$refs.table.clearSelected();
      }
    },
    onProgress(e) {
      console.log(e);
    },
  },
};
</script>

<style></style>

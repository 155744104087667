<template>
  <div class="container-">
    <div class="row">
      <div class="col-md-12 mb-4">
        <template v-if="loading">
          <div class="card shadow-0 border-0 px-3">
            <div class="field col-12 md:col-6 mt-2" v-for="i in 3" :key="i">
              <PSkeleton class="mb-2"></PSkeleton>
              <PSkeleton width="10rem" class="mb-2"></PSkeleton>
              <PSkeleton width="5rem" class="mb-2"></PSkeleton>
              <PSkeleton height="2rem" class="mb-2"></PSkeleton>
              <PSkeleton width="10rem" class="mb-2" height="2rem"></PSkeleton>
            </div>
          </div>
        </template>
        <div
          v-else
          class="card-booking-completed pa-5 mb-5 d-flex flex-column justify-content-center"
          :height="550"
        >
          <FilePondUploaderDialog ref="logoUploader" @save="handleFileSaved" />
          <div class="card mb-3 border-1">
            <div class="card-header bg-white">
              <h5 class="card-title d-flex align-items-center justify-content-between">
                Meta information
                <div>
                  <!-- <span
                    ><b-btn
                      class="me-2"
                      size="xs"
                      @click.prevent="downloadFiche(entreprise)"
                      variant="success"
                      ><i class="bi bi-camera"></i></b-btn
                  ></span> -->
                  <!-- <span
                    ><b-btn
                      size="xs"
                      @click.prevent="remplirFiche(de)"
                      variant="success"
                      ><i class="bi bi-pencil"></i></b-btn
                  ></span> -->
                </div>
              </h5>
            </div>
            <div class="card-body">
              <div class="row">
                <div class="col-sm-3 d-flex justify-content-center">
                  <a href="#">
                    <div
                      class="rounded-circle style_bg_img border"
                      :style="`background-image: url(${
                        entreprise.fullLogoUrl
                          ? entreprise.fullLogoUrl
                          : '/assets/img/company-placeholder.png'
                      }); height: 120px; width: 120px;`"
                    >
                      <!-- <profile-photo-drag-and-drop
                        :autoUpload="true"
                        :hidePreview="true"
                        :method="'POST'"
                        @fileSelected="fileChanged"
                        @completed="logoUpdated"
                        :uploadUrl="API_URL + `/entreprises/${entrepriseId}/updateLogo`"
                        icon="fa fa-3x fa-cloud-upload-alt"
                        action-label=""
                      /> -->
                      <button
                        style="
                          position: absolute;
                          bottom: 0px;
                          right: 0px;
                          border-radius: 50%;
                        "
                        class="btn round btn-warning btn-sm"
                        @click.prevent="$refs.logoUploader.show()"
                        v-tooltip.top="'Changer le logo'"
                      >
                        <i class="bi bi-pencil"></i>
                      </button>
                    </div>
                  </a>
                </div>
                <div class="col">
                  <div class="row">
                    <div class="col-sm-5">
                      <h6 class="mb-0">Inscrit le</h6>
                    </div>
                    <div class="col-sm-7 text-secondary">
                      {{ $dayjs(entreprise.lastLogin).format("DD-MM-YYYY") }}
                    </div>
                  </div>
                  <hr />
                  <div class="row">
                    <div class="col-sm-5">
                      <h6 class="mb-0">Statut</h6>
                    </div>
                    <div class="col-sm-7 text-secondary"></div>
                  </div>
                  <hr />
                  <div class="row">
                    <div class="col-sm-5">
                      <h6 class="mb-0">Enregistré par:</h6>
                    </div>
                    <div v-if="entreprise" class="col-sm-7 text-secondary">
                      {{
                        entreprise.creePar ? entreprise.creePar.nomPrenom : "ELLE-MEME"
                      }}
                    </div>
                  </div>
                </div>
                <div class="col-sm-3">
                  <div
                    class="d-flex flex-column align-items-center justify-content-center"
                  >
                    <qrcode-vue :value="'00000' + entreprise.id" />
                    <span>Ref:{{ "00000" + entreprise.id }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="card mb-3 border-1">
            <div class="card-header bg-white">
              <h5 class="card-title d-flex align-items-center justify-content-between">
                Identification
                <slot name="title">
                  <div>
                    <!-- <span><b-btn class="me-2" size="xs" @click.prevent="downloadFiche(de)" variant="outline-success"><i class="bi bi-printer"></i></b-btn></span> -->
                    <span
                      ><b-btn size="xs" @click.prevent="editerDossier" variant="success"
                        ><i class="bi bi-pencil"></i></b-btn
                    ></span>
                  </div>
                </slot>
              </h5>
            </div>
            <div class="card-body">
              <div class="row">
                <div class="col">
                  <div class="row">
                    <div class="col-sm-5">
                      <h6 class="mb-0">Raison Sociale :</h6>
                    </div>
                    <div class="col-sm-7 text-secondary">
                      {{ entreprise.raisonSocial || "-" }}
                    </div>
                  </div>

                  <hr />
                  <div class="row">
                    <div class="col-sm-5">
                      <h6 class="mb-0">IFU :</h6>
                    </div>
                    <div class="col-sm-7 text-secondary">
                      {{ entreprise.ifu || "-" }}
                    </div>
                  </div>
                  <hr />
                  <div class="row">
                    <div class="col-sm-5">
                      <h6 class="mb-0">Forme juridique :</h6>
                    </div>
                    <div class="col-sm-7 text-secondary">
                      {{
                        entreprise.formeJuridique != null
                          ? entreprise.formeJuridique.code +
                            " - " +
                            entreprise.formeJuridique.libelle
                          : "-"
                      }}
                    </div>
                  </div>
                  <hr />
                  <div class="row">
                    <div class="col-sm-5">
                      <h6 class="mb-0">RCCM :</h6>
                    </div>
                    <div class="col-sm-7 text-secondary">
                      {{ entreprise.rccm || "-" }}
                    </div>
                  </div>
                  <hr />
                  <div class="row">
                    <div class="col-sm-5">
                      <h6 class="mb-0">Adresse du siège :</h6>
                    </div>
                    <div class="col-sm-7 text-secondary">
                      {{ entreprise.adresseSiege || "-" }}
                    </div>
                  </div>
                  <hr />
                  <div class="row">
                    <div class="col-sm-5">
                      <h6 class="mb-0">Commune :</h6>
                    </div>
                    <div class="col-sm-7 text-secondary">
                      {{ entreprise.commune.nom || "-" }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="card mb-3 border-1">
            <div class="card-header bg-white">
              <h5 class="card-title d-flex align-items-center">
                Contact
                <span
                  ><b-btn size="xs" v-if="false" variant="outline-warning"
                    ><i class="bi bi-edit"></i></b-btn
                ></span>
              </h5>
            </div>
            <div class="card-body">
              <div class="row">
                <div class="col">
                  <div class="row">
                    <div class="col-sm-5">
                      <h6 class="mb-0">Téléphone :</h6>
                    </div>
                    <div class="col-sm-7 text-secondary">
                      {{ entreprise.phone || "-" }}
                    </div>
                  </div>

                  <hr />
                  <div class="row">
                    <div class="col-sm-5">
                      <h6 class="mb-0">Whatsapp :</h6>
                    </div>
                    <div class="col-sm-7 text-secondary">
                      {{ entreprise.whatsapp || "-" }}
                    </div>
                  </div>
                  <hr />
                  <div class="row">
                    <div class="col-sm-5">
                      <h6 class="mb-0">Site web :</h6>
                    </div>
                    <div class="col-sm-7 text-secondary">
                      {{ entreprise.siteWeb || "-" }}
                    </div>
                  </div>

                  <hr />
                  <div class="row">
                    <div class="col-sm-5">
                      <h6 class="mb-0">E-mail :</h6>
                    </div>
                    <div class="col-sm-7 text-secondary">
                      {{ entreprise.email || "-" }}
                    </div>
                  </div>

                  <hr />
                  <div class="row">
                    <div class="col-sm-5">
                      <h6 class="mb-0">Nom dirigeant :</h6>
                    </div>
                    <div class="col-sm-7 text-secondary">
                      {{ entreprise.nomDirigeant || "-" }}
                    </div>
                  </div>

                  <hr />
                  <div class="row">
                    <div class="col-sm-5">
                      <h6 class="mb-0">Contact du dirigeant :</h6>
                    </div>
                    <div class="col-sm-7 text-secondary">
                      {{ entreprise.telephoneDirigeant || "-" }}
                    </div>
                  </div>

                  <hr />
                  <div class="row">
                    <div class="col-sm-5">
                      <h6 class="mb-0">Numéro whatsapp :</h6>
                    </div>
                    <div class="col-sm-7 text-secondary">
                      {{ entreprise.numeroWhatsappDirigent || "-" }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="card mb-3 border-1">
            <div class="card-header bg-white">
              <h5 class="card-title d-flex align-items-center">
                Personne à contacter
                <span
                  ><b-btn size="xs" v-if="false" variant="outline-warning"
                    ><i class="bi bi-edit"></i></b-btn
                ></span>
              </h5>
            </div>
            <div class="card-body">
              <div class="row">
                <div class="col">
                  <div class="row">
                    <div class="col-sm-5">
                      <h6 class="mb-0">Nom :</h6>
                    </div>
                    <div class="col-sm-7 text-secondary">
                      {{ entreprise.nomContact || "-" }}
                    </div>
                  </div>

                  <hr />
                  <div class="row">
                    <div class="col-sm-5">
                      <h6 class="mb-0">Fonction :</h6>
                    </div>
                    <div class="col-sm-7 text-secondary">
                      {{ entreprise.fonction || "-" }}
                    </div>
                  </div>
                  <hr />
                  <div class="row">
                    <div class="col-sm-5">
                      <h6 class="mb-0">E-mail :</h6>
                    </div>
                    <div class="col-sm-7 text-secondary">
                      {{ entreprise.emailContact || "-" }}
                    </div>
                  </div>
                  <hr />
                  <div class="row">
                    <div class="col-sm-5">
                      <h6 class="mb-0">Téléphone :</h6>
                    </div>
                    <div class="col-sm-7 text-secondary">
                      {{ entreprise.telephonePersonneContact || "-" }}
                    </div>
                  </div>
                  <hr />
                  <div class="row">
                    <div class="col-sm-5">
                      <h6 class="mb-0">Numéro whatsapp :</h6>
                    </div>
                    <div class="col-sm-7 text-secondary">
                      {{ entreprise.numeroWhatsappPersonneContact || "-" }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="card mb-3 border-1">
            <div class="card-header bg-white">
              <h5 class="card-title d-flex align-items-center">
                ACTIVITES DE L'ENTREPRISE
                <span
                  ><b-btn size="xs" v-if="false" variant="outline-warning"
                    ><i class="bi bi-edit"></i></b-btn
                ></span>
              </h5>
            </div>
            <div class="card-body">
              <div class="row">
                <div class="col">
                  <div class="row">
                    <div class="col-sm-5">
                      <h6 class="mb-0">Activité pricipale :</h6>
                    </div>
                    <div class="col-sm-7 text-secondary">
                      <PChip
                        v-if="entreprise.activitePrincipale !== null"
                        :label="entreprise.activitePrincipale.libelle"
                      />
                      <span v-else>-</span>
                    </div>
                  </div>

                  <hr />
                  <div class="row">
                    <div class="col-sm-5">
                      <h6 class="mb-0">Activités secondaires :</h6>
                    </div>
                    <div class="col-sm-7 text-secondary">
                      <PChip
                        v-for="(act, index) in entreprise.activiteSecondaires"
                        :key="index"
                        :label="act.libelle"
                      />
                      <span v-if="entreprise.activiteSecondaires.length == 0">-</span>
                    </div>
                  </div>
                  <hr />
                  <div class="row">
                    <div class="col-sm-5">
                      <h6 class="mb-0">Produits et services :</h6>
                    </div>
                    <div class="col-sm-7 text-secondary">
                      {{ entreprise.produitService || "-" }}
                    </div>
                  </div>
                  <hr />
                  <div class="row">
                    <div class="col-sm-5">
                      <h6 class="mb-0">Chiffre d'affaire :</h6>
                    </div>
                    <div class="col-sm-7 text-secondary">
                      {{ entreprise.chiffreAffaire || "-" }}
                    </div>
                  </div>
                  <hr />
                  <div class="row">
                    <div class="col-sm-5">
                      <h6 class="mb-0">Cycle de production :</h6>
                    </div>
                    <div class="col-sm-7 text-secondary">
                      {{ entreprise.cycleProduction || "-" }}
                    </div>
                  </div>
                  <hr />
                  <div class="row">
                    <div class="col-sm-5">
                      <h6 class="mb-0">Production en rotation :</h6>
                    </div>
                    <div class="col-sm-7 text-secondary">
                      <yes-or-no-badge :value="entreprise.productionRotation == 'OUI'" />
                      <!-- {{ entreprise.productionRotation || "-" }} -->
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="card mb-3 border-1" v-if="entreprise.isCandidatAzoli">
            <div class="card-header bg-white">
              <h5 class="card-title d-flex align-items-center">
                EFFECTIFS DU PERSONNEL
                <span
                  ><b-btn size="xs" v-if="false" variant="outline-warning"
                    ><i class="bi bi-edit"></i></b-btn
                ></span>
              </h5>
            </div>
            <div class="card-body">
              <div class="col">
                <div class="row">
                  <div class="col-sm-5">
                    <h6 class="mb-0">Effectif total</h6>
                  </div>
                  <div class="col-sm-7 text-secondary">
                    <table class="table table-borderless">
                      <thead class="border-bottom border-top">
                        <tr>
                          <th scope="col">Total</th>
                          <th scope="col">Homme</th>
                          <th scope="col">Femme</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            <span> {{ entreprise.effectifTotal || "-" }}</span>
                          </td>
                          <td>
                            <span> {{ entreprise.effectifTotalHomme || "-" }}</span>
                          </td>
                          <td>
                            <span> {{ entreprise.effectifTotalFemme || "-" }}</span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>

                <hr />
                <div class="row">
                  <div class="col-sm-5">
                    <h6 class="mb-0">Effectif total permanent</h6>
                  </div>
                  <div class="col-sm-7 text-secondary">
                    <table class="table table-borderless">
                      <thead class="border-bottom border-top">
                        <tr>
                          <th scope="col">Total</th>
                          <th scope="col">Homme</th>
                          <th scope="col">Femme</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            <span> {{ entreprise.effectifPermanent || "-" }}</span>
                          </td>
                          <td>
                            <span> {{ entreprise.effectifPermanentHomme || "-" }}</span>
                          </td>
                          <td>
                            <span> {{ entreprise.effectifPermanentFemme || "-" }}</span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <hr />
                <div class="row">
                  <div class="col-sm-5">
                    <h6 class="mb-0">Effectif total temporaire</h6>
                  </div>
                  <div class="col-sm-7 text-secondary">
                    <table class="table table-borderless">
                      <thead class="border-bottom border-top">
                        <tr>
                          <th scope="col">Total</th>
                          <th scope="col">Homme</th>
                          <th scope="col">Femme</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            <span> {{ entreprise.effectifTemporaire || "-" }}</span>
                          </td>
                          <td>
                            <span> {{ entreprise.effectifTemporaireHomme || "-" }}</span>
                          </td>
                          <td>
                            <span> {{ entreprise.effectifTemporaireFemme || "-" }}</span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <hr />
                <div class="row">
                  <div class="col-sm-5">
                    <h6 class="mb-0">Effectif total stagiaire</h6>
                  </div>
                  <div class="col-sm-7 text-secondary">
                    <table class="table table-borderless">
                      <thead class="border-bottom border-top">
                        <tr>
                          <th scope="col">Total</th>
                          <th scope="col">Homme</th>
                          <th scope="col">Femme</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            <span> {{ entreprise.effectifStagiaire || "-" }}</span>
                          </td>
                          <td>
                            <span> {{ entreprise.effectifStagiaireHomme || "-" }}</span>
                          </td>
                          <td>
                            <span> {{ entreprise.effectifStagiaireFemme || "-" }}</span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <hr />
                <div class="row">
                  <div class="col-sm-5">
                    <h6 class="mb-0">Formation des personnels :</h6>
                  </div>
                  <div class="col-sm-7 text-secondary">
                    <yes-or-no-badge :value="entreprise.formationPersonnel" />
                  </div>
                </div>

                <hr />
                <div class="row">
                  <div class="col-sm-5">
                    <h6 class="mb-0">Garderie personnel :</h6>
                  </div>
                  <div class="col-sm-7 text-secondary">
                    <yes-or-no-badge :value="entreprise.garderiePersonnel" />
                  </div>
                </div>

                <hr />
                <div class="card mb-3 border-1">
                  <div class="card-header bg-white">
                    <h5 class="card-title d-flex align-items-center">
                      BESOIN EN PERSONNEL
                      <span
                        ><b-btn size="xs" v-if="false" variant="outline-warning"
                          ><i class="bi bi-edit"></i></b-btn
                      ></span>
                    </h5>
                  </div>
                  <div class="card-body">
                    <div class="row">
                      <div class="col-sm-12">
                        <div class="col-sm-12 text-secondary">
                          <table class="table table-borderless">
                            <thead class="border-bottom border-top">
                              <tr>
                                <th scope="col">Qualification</th>
                                <th scope="col">Metier</th>
                                <!-- <th scope="col">Autre Metier</th> -->
                                <th scope="col">Total</th>
                                <th scope="col">Homme</th>
                                <th scope="col">Femme</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr
                                v-for="(besoin, index) in entreprise.besoinPersonnels"
                                :key="index"
                              >
                                <td>
                                  <span> {{ besoin.qualification || "-" }}</span>
                                </td>
                                <td>
                                  <span> {{ besoin.poste.libelle || "-" }}</span>
                                </td>
                                <!-- <td>
                                  <span> {{ besoin.autreMetier || "-" }}</span>
                                </td> -->
                                <td>
                                  <span> {{ besoin.homme + besoin.femme || "-" }}</span>
                                </td>
                                <td>
                                  <span> {{ besoin.homme || "-" }}</span>
                                </td>
                                <td>
                                  <span> {{ besoin.femme || "-" }}</span>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <div
          class="card-booking-completed pa-5 mb-5 mx-5 d-flex flex-column justify-content-center"
          :height="550"
        ></div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import YesOrNoBadge from "@/components/common/YesOrNoBadge.vue";
import FilePondUploaderDialog from "../../../components/uploader/FilePondUploaderDialog.vue";

export default {
  props: ["entrepriseId", "value"],
  components: {
    YesOrNoBadge,
    FilePondUploaderDialog,
  },
  data() {
    return {
      entreprise: this.value,
      loading: true,
    };
  },
  created() {
    // this.entreprise = localStorage.getItem('subscription') != undefined? JSON.parse(localStorage.getItem('subscription')) : this.entreprise
    // this.currentStep = localStorage.getItem('currentStep') != undefined? localStorage.getItem('currentStep') : this.currentStep
  },
  watch: {
    entrepriseId: {
      handler() {
        if (this.entrepriseId) {
          this.loadData();
        }
      },
      immediate: true,
    },
    // dossier(val) {
    //   if (val.azoli) {
    //     this.entreprise = {
    //       ...val,
    //       ...val.azoli,
    //     };
    //   }
    // },
    // value(val) {
    //   this.entreprise = {
    //     ...val,
    //   };
    // },
  },
  computed: {
    ...mapGetters({
      // entreprise: "entreprise/entreprise",
      dossier: "entreprise/entreprise",
      raisonEmploisMap: "setting/raisonEmploisMap",
      plageSalairesMap: "setting/plageSalairesMap",
      typeEmploisMap: "setting/typeEmploisMap",
    }),
    de() {
      return this.entreprise;
      // eslint-disable-next-line no-unreachable
      // console.log(this.entreprise);
    },
  },
  methods: {
    ...mapActions({
      fetchDossier: "entreprise/fetchDossier",
      updateLogoEntreprise: "entreprise/updateLogoEntreprise",
    }),
    loadData() {
      this.loading = true;
      this.fetchDossier({
        entrepriseId: this.entrepriseId,
      }).then(() => {
        this.loading = false;
        this.entreprise = { ...this.dossier };
      });
    },
    fileChanged(e) {
      console.log(e);
    },
    editerDossier() {
      this.$emit("edit");
    },
    handleFileSaved(fileUrl) {
      this.loading = true;
      this.updateLogoEntreprise({
        entrepriseId: this.entrepriseId,
        logoUrl: fileUrl,
      })
        .then(() => {
          this.loadData();
          this.this.$toast.success("Opération réussie!", {
            position: "bottom-right",
            duration: 5000,
          });
        })
        .catch(() => {
          this.$toast.error("Une erreur s'est produite lors de l'opération.", {
            position: "bottom-right",
            duration: 5000,
          });
        });
    },
  },
};
</script>

<style>
.card-title {
  color: #1a32c8 !important;
  /* color: #1A32C8 !important; */
  text-transform: uppercase;
}
hr {
  color: #bfa9a9;
  margin: 10px 5px;
}
</style>

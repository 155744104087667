<template>
  <div class="">
    <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
      <form ref="form" method="post" @submit.prevent="handleSubmit()">
        <ValidationProvider rules="required" v-slot="{ errors }">
          <label for="activites">Activité principale</label>
          <div class="form-floating mb-3">
            <naema-select
              placeholder="Activité principale"
              v-model="editableItem.activitePrincipale"
              :options="naemas"
            />
            <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
              err
            }}</span>
            <small class="text-help text-muted"
              >Saisissez pour rechercher le nom de votre domaine d'activité ex:
              ELEVAGE</small
            >
          </div>
        </ValidationProvider>
        <ValidationProvider rules="required" v-slot="{ errors }">
          <label for="activiteSecondaires">Activité secondaire</label>
          <div class="form-floating mb-3">
            <naema-select
              placeholder="Activité(s) secondaire(s)"
              v-model="editableItem.activiteSecondaires"
              multiple
              :options="naemas"
            />
            <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
              err
            }}</span>
            <small class="text-help text-muted"
              >Saisissez pour rechercher le nom de votre domaine d'activité ex: ELEVAGE DE
              BOVINS</small
            >
          </div>
        </ValidationProvider>
        <ValidationProvider rules="required" v-slot="{ errors }">
          <div class="form-floating mb-3">
            <textarea
              :value="editableItem.produitService"
              @input="handleInput"
              type="text"
              class="form-control"
              id="produitService"
              name="produitService"
              placeholder="Produit service"
              style="height: 100px"
            ></textarea>
            <label for="roduitService">Produit/service</label>
            <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
              err
            }}</span>
          </div>
        </ValidationProvider>
        <ValidationProvider rules="required" v-slot="{ errors }">
          <div class="form-floating mb-3">
            <c-select
              id="chiffreAffaires"
              name="chiffreAffaires"
              option-label="label"
              option-value="code"
              v-model="editableItem.chiffreAffaire"
              :options="chiffreAffaires"
              :emit-object="false"
              class="form-select"
            >
            </c-select>
            <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
              err
            }}</span>
            <label for="chiffreAffaire">Chiffre d'affaire</label>
          </div>
        </ValidationProvider>
        <div class="row">
          <div class="col-sm-6">
            <ValidationProvider rules="required" v-slot="{ errors }">
              <div class="form-floating mb-3">
                <c-select
                  id="cycleProduction"
                  name="cycleProduction"
                  option-label="label"
                  option-value="code"
                  v-model="editableItem.cycleProduction"
                  :options="cycleProductions"
                  :emit-object="false"
                  class="form-select"
                >
                </c-select>
                <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
                  err
                }}</span>
                <label for="cycleProduction">Cycle de production</label>
              </div>
            </ValidationProvider>
          </div>
          <div class="col-sm-6">
            <ValidationProvider rules="required" v-slot="{ errors }">
              <div class="form-floating mb-3">
                <c-select
                  id="productionRotation"
                  name="productionRotation"
                  option-label="label"
                  option-value="code"
                  :emit-object="false"
                  v-model="editableItem.productionRotation"
                  :options="productionRotations"
                  class="form-select"
                >
                </c-select>

                <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
                  err
                }}</span>
                <label for="productionRotation">Production en rotation</label>
              </div>
            </ValidationProvider>
          </div>
        </div>
      </form>
    </ValidationObserver>
  </div>
</template>

<script>
// import CYesOrNoInput from '../../../../CYesOrNoInput.vue'
// import BlockMaitriseLangues from '../BlockMaitriseLangues.vue'
import Multiselect from "vue-multiselect";
import { mapGetters, mapActions } from "vuex";
import NaemaSelect from "../../../../common/NaemaSelect.vue";

export default {
  props: ["value"],
  components: {
    Multiselect,
    NaemaSelect,
  },
  data() {
    return {
      hasNip: null,
      pieces: [],
      editableItem: this.value,
      dateNais: this.value.dateNais,
      situationMatrimoniales: [
        { value: "CELIBATAIRE", label: "Célibataire" },
        { value: "MARIE_MONOGAME", label: "Marié monogame" },
        { value: "MARIE_POLYGAME", label: "Marié polygame" },
        { value: "UNION_LIBRE", label: "Union libre" },
        { value: "VEUF", label: "Veuf(ve)" },
        { value: "DIVORCE", label: "Divorcé" },
        { value: "SEPARE", label: "Séparé" },
        { value: "AUTRE", label: "Non classé(e)" },
      ],
      gressesseResponses: [
        { value: true, label: "Oui" },
        { value: false, label: "Non" },
        { value: null, label: "Je ne sais pas" },
      ],
    };
  },
  created() {
    this.fetchNaemas();
  },
  computed: {
    ...mapGetters({
      chiffreAffaires: "setting/chiffreAffaires",
      naemas: "naema/naemas",
      cycleProductions: "setting/cycleProductions",
      productionRotations: "setting/productionRotations",
    }),
    maxDate() {
      const now = new Date();
      const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
      today.setFullYear(now.getFullYear() - 18);
      return today;
    },
  },
  watch: {
    dateNais(val) {
      this.editableItem.dateNais = this.$dayjs(val).format("DD-MM-YYYY");
      this.$emit("input", this.editableItem);
    },
  },
  methods: {
    ...mapActions({
      fetchNaemas: "naema/fetchNaemas",
    }),
    handleInput(e) {
      this.editableItem[e.target.name] = e.target.value;
      this.$emit("input", this.editableItem);
    },
    async isCompleted() {
      const valid = await this.$refs.observer.validate();
      return valid;
    },
  },
};
</script>

<style scoped></style>

<template>
  <div class="">
    <PDialog
      :header="title"
      :modal="true"
      :visible.sync="display"
      :containerStyle="{ width: '60vw' }"
    >
      <searchable-item-selector
        :listTitle="`Liste des auditeurs`"
        :referentielName="referentielName"
        :tableFields="tableFields"
        v-model="editableItem.auditeurs"
        :extraQueryArgsParamBuilder="extraQueryArgsParamBuilder"
        :emptyReferentielItem="emptyReferentielItem"
      />
      <template #footer>
        <PButton
          label="Annuler"
          icon="pi pi-times"
          @click="cancel"
          class="p-button-text"
        />
        <PButton label="Valider" icon="pi pi-check" @click="save" autofocus />
      </template>
    </PDialog>
  </div>
</template>

<script>
import SearchableItemSelector from "@/components/common/SearchableItemAuditeurSelector.vue";

export default {
  props: {
    title: {
      type: String,
      required: false,
    },
  },
  components: {
    SearchableItemSelector,
  },
  data() {
    return {
      display: false,
      editableItem: {
        auditeurs: [],
      },
    };
  },
  created() {},
  watch: {
    item() {
      this.editableItem = { ...this.item };
    },
  },
  computed: {
    referentielName() {
      return "auditeur";
    },
    emptyReferentielItem() {
      return {
        titre: null,
        code: null,
      };
    },
    tableFields() {
      return [
        "#",
        "index",
        { key: "nom", label: "Nom" },
        { key: "prenom", label: "Prénom" },
        { key: "phone", label: "Téléphone" },
        { key: "title", label: "Titre" },
        { key: "email", label: "Email" },
        { key: "roles", label: "Role" },
      ];
    },
    options() {
      return this.structures.map((item) => ({ value: item, text: item.libelle }));
    },
  },
  methods: {
    extraQueryArgsParamBuilder() {
      return ``;
    },
    show() {
      this.display = true;
    },
    hide() {
      this.display = false;
    },
    ok() {
      this.$emit("save", this.editableItem);
      this.editableItem = {};
    },
    cancel() {
      this.hide();
      this.$emit("canceled");
    },
    async save() {
      this.$emit("save", this.editableItem);
      this.hide();
    },
  },
};
</script>

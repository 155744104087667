<template>
  <div>
    <PDialog :header="'Editeur de mise en stage/formation'" :modal="true" :visible.sync="display" :contentStyle="{overflow: 'auto'}" :containerStyle="{width: '40vw'}">
     <ValidationObserver ref="observer"  v-slot="{ handleSubmit }">
          <!-- <a href="#" @click="$refs.participantSelector.show()" class="btn btn-primary">Sélectionner les selectedDemandeurs</a> -->
        <div class="card mt border-0">
          <form ref="form" method="post" @submit.prevent="handleSubmit()" >
              <template>
                <h5>Information de base</h5>
              <div class="row">
                  <!-- <div class="col-sm-12">
                    <h4 class="mt-4 mb-4">Quel type de placement voulez-vous effectuer?</h4>
                    <div v-for="(typePlacement, index) in typePlacements" :key="index" class="field-radiobutton">
                      <PRadioButton name="typePlacement" :value="typePlacement.id" v-model="editableItem.typePlacement" @change="selectTypePlacement(typePlacement)" />
                      <label for="typePlacement">{{ typePlacement.libelle }}</label>
                    </div>
                  </div> -->
                  <div class="col-sm-12 field">
                    <ValidationProvider rules="required" v-slot="{ errors }">
                      <div class="form-floating mb-3">
                        <c-select id="typePlacement" name="typePlacement" option-label="libelle" option-value="id" v-model="editableItem.typePlacement" :options="typePlacements" class="form-select">
                        </c-select>
                        <label for="typePlacement">Type de placement (Stage/Formation)</label>
                        <span v-for="(err, index) in errors" :key="index" class="text-danger">{{ err }}</span>
                      </div>
                    </ValidationProvider>
                  </div>
                  <div class="col-sm-12 field">
                    <ValidationProvider rules="required" v-slot="{ errors }">
                      <div class="form-floating mb-3">
                        <c-select id="natureContrat" name="natureContrat" option-label="description" option-value="id" v-model="editableItem.natureContrat" :options="natureContrats" class="form-select">
                        </c-select>
                        <label for="natureContrat">Nature contrat</label>
                        <span v-for="(err, index) in errors" :key="index" class="text-danger">{{ err }}</span>
                      </div>
                    </ValidationProvider>
                  </div>
                  <div class="col-sm-12 field">
                    <ValidationProvider rules="required" v-slot="{ errors }">
                      <div>Date de début</div>
                        <p-calendar
                          class="col-12 md:col-12"
                          :showIcon="true"
                          size="sm"
                          locale="fr"
                          name="dateNais"
                          :manualInput="false"
                          v-model="editableItem.dateDebut"
                          :showWeek="true"
                          />  
                      <span v-for="(err, index) in errors" :key="index" class="text-danger">{{ err }}</span>
                    </ValidationProvider>
                  </div>
                  <div class="col-sm-12 field">
                    <ValidationProvider rules="required" v-slot="{ errors }">
                      <div>Date de fin</div>
                        <p-calendar
                          class="col-12 md:col-12"
                          :showIcon="true"
                          size="sm"
                          locale="fr"
                          name="dateNais"
                          :manualInput="false"
                          v-model="editableItem.dateFin"
                          :showWeek="true"
                          />  
                      <span v-for="(err, index) in errors" :key="index" class="text-danger">{{ err }}</span>
                    </ValidationProvider>
                  </div>
                </div>
              </template>
              <template>
                <h5 class="mt-4">Choix de l'entreprise</h5>
                <div class="row">
                  <div class="col-sm-12">
                    <!-- <div class="form-floating mb-3">
                      <input v-model="mxSearch" type="text" class="form-control" id="search" name="search" placeholder="Rechercher">
                      <label for="name">Rechercher un conseiller ou un ulp</label>
                      <span v-for="(err, index) in errors" :key="index" class="text-danger">{{ err }}</span>
                    </div> -->
                    <div class="input-group me-4">
                      <input v-model="mxSearch" type="text" placeholder="Rechercher une entreprise par son nom ou son IFU" aria-describedby="button-query" class="form-control"/>
                      <button type="submit" id="button-query" class="style_btn btn btn-primary"><i class="bi bi-search"></i></button>
                    </div>
                  </div>
                  </div>
                  <div class="col-sm-12 my-3 text-muted">Cliquez sur le profil de l'entreprise dans laquelle aurait lieu la formation/stage.</div>
                  <span v-if="error"  class="text-danger">Vous devez sélectionner au moins un profil</span>
                  <div class="row mb-4" v-if="viewMode=='card'">
                    <div class="col-sm-6 col-lg-6 mb-3" v-for="profile in mxFilteredItems" :key="profile.id">
                      <div :class="{ 'border card-primary border-primary': selectedProfile.id == profile.id }" class="style_profil_card p-3" @click="selectProfile(profile)">
                        <!-- <PAvatar label="P" /> -->
                        <div class="d-flex justify-content-start flex-column align-items-start">
                          <div class="d-flex no-wrap justify-content- align-items- text-muted">
                            <small class="mt-1">{{profile.ifu}}</small>
                          </div>
                          <h6 class="my-1"><a href="#" class="text-dark">{{profile.raisonSocial}}</a></h6>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div v-else class="bg-white p-3 border">
                    <div class="table-responsive">
                      <b-table selectable show-empty empty-text="Aucune donnée à afficher" :fields="tableFields" class="align-middle" striped :items="tableData">
                        <template #head()="data">
                          <span>{{ data.label }} </span>
                        </template>
                        <template #head(#)="data">
                          <div class="form-check form-check-inline">
                            <input class="form-check-input" type="checkbox" :id="`${data.index}`" name="disponible" :value="data.rowSelected">
                          </div>
                        </template>
                        <!-- A virtual column -->
                        <template #cell(index)="data">
                          {{ data.index + 1 }}
                        </template>
        
                        <template #cell(#)="data">
                          <div class="form-check form-check-inline">
                            <input @change="()=> data.rowSelected=true" class="form-check-input" type="checkbox" :id="`${data.index}`" name="disponible" :value="data.index">
                          </div>
                        </template>
        
                        <template #cell(lastLogin)="data">
                            <span>{{data.item.lastLogin | dayjs('DD-MM-YYYY') }}</span>
                        </template>
                        <!-- Optional default data cell scoped slot -->
                        <template #cell()="data">
                          <span>{{ data.value }}</span>
                        </template>
                      </b-table>
                    </div>
                    <div class="d-flex flex-wrap justify-content-between align-items-center">
                      <p>Affichage de l’élément {{ mxPagination.page *mxPagination.rowsPerPage }} à {{ mxPagination.page*mxPagination.rowsPerPage }} de {{ mxTotalCount }} éléments</p>
                      <b-pagination
                        v-model="mxPagination.page"
                        :total-rows="mxPageCount"
                        :per-page="mxPagination.rowsPerPage"
                        aria-controls="my-table"
                      ></b-pagination>
                    </div>
                  </div>
              </template>

              <h5 class="mt-4">Choix des bénéficiaires</h5>
              <ParticipantSelectorDialog ref="participantSelector" :items="selectedDemandeurs" @change="participantSelectionChanged" :title="'Selection de DE'"  :inline="true"/>

            </form>
        </div>
      </ValidationObserver>
      <template #footer>
        <PButton label="Quitter" icon="pi pi-times" @click="cancel" class="p-button-text"/>
        <PButton label="Valider" icon="pi pi-check" @click="save" autofocus />
      </template>
    </PDialog>
  </div>
</template>

<script>
import { paginatorMixin } from '@/mixins/paginator-mixin'
import ParticipantSelectorDialog from '../../ParticipantSelectorDialog.vue'
import { mapActions, mapGetters } from 'vuex'
export default {
  components: {
    ParticipantSelectorDialog,
  },
  props: {
    title: {
      type:  String,
      required: true
    },
    size: {
      type:  String,
      default: 'md'
    },
    item: {
      type:  Object,
      default: () => ({ })
    }
  },
  mixins: [paginatorMixin],
  data () {
    return {
      display: false,
      editableItem: {},
      search: '',
      viewMode: 'card',//card, table
      filterOptions: [],
      selectedProfile: {},
      selectedDemandeurs: [],
      error: false,
      activeStepIndex: 0
    }
  },
  watch: {
    item () {
      this.editableItem = { ...this.item }

    },
    // 'editableItem.typePlacement': {
    //   handler(val){
    //   }
    // }
  },
  created () {
    this.fetchNatureContrats()
    this.fetchTypePlacements()
  },
  computed: {
    ...mapGetters({
      typePlacements: 'typePlacement/typePlacements',
      natureContrats: 'natureContrat/natureContrats',
    }),
    steps(){
      return [
        {
          label: 'SELECTION TYPE DE PLACEMENT',
          subtitle: 'Choix Formation/Stage',
          to: '#/1',
          completed: this.editableItem != null && this.editableItem.typePlacement  != null,
          command:() => {
            this.activeStepIndex = 0
          }
        },
        {
          label: 'SELECTION DE L\'ENTREPRISE',
          subtitle: 'Sélection de l\'entreprise(s)',
          to: '#/2',
          completed: this.editableItem != null && this.editableItem.entreprise != null,
          command:() => {
            if(this.autonomieCompleted) this.activeStepIndex = 1
          }
            
        },
        {
          label: 'SELECTION DU(S) DEMANDEUR(S)',
          to: '#/3',
          subtitle: 'Sélection du(s) demandeur(s) concerné(s)',
          completed: this.editableItem != null && this.editableItem.demandeur != null,
          command:() => {
            if(this.autonomieCompleted) this.activeStepIndex = 2
          }
        }
      ]
    },
    options () {
      return this.structures.map(item => ({ value: item, text: item.libelle }) )
    },
    tableFields () {
      return [
        '#',
        'index',
        { key: 'nom', label: 'Nom' },
        { key: 'prenom', label: 'Prénom' },
        { key: 'sexe', label: 'Sexe' },
        { key: 'phone', label: 'Téléphone' },
        { key: 'commune.nom', label: 'Commune' },
      ]
    },
    tableData () {
      return this.mxFilteredItems.map(item => {
        return { ...item }
      })
    },
    sexeOptions () {
      return ['M', 'F'].map(item => ({ value: item, text: item }) )
    },
  },
  methods: {
    ...mapActions({
      fetchTypePlacements: 'typePlacement/fetchTypePlacements',
      fetchNatureContrats: 'natureContrat/fetchNatureContrats',
    }),
    resetContent(){
      this.mxInitializePaginator({
        queryModel: 'entreprise',
        search: this.search,
        fetcherMethod: 'entreprise/fetchEntreprises',
        dataGetter: 'entreprise/entreprises',
        paginationGetter: 'entreprise/pagination',
        pagination: {
          sortBy: 'desc',
          descending: false,
          page: 0,
          rowsPerPage: 6
        },
        filterOptions: this.filterOptions,
        searchFields: [ 'search', 'ifu', 'raisonSocial' ]
      })
    },
    selectProfile(profile){
      this.error = false
      this.selectedProfile = profile
      this.activeStepIndex = 2
    },
    selectTypePlacement(){
      this.error = false
      this.activeStepIndex = 1
    },
    participantSelectionChanged (items) {
      this.editableItem = {
        ...this.editableItem,
        beneficiaires: items
      }
    },
    show () {
      this.resetContent()
        this.display = true
    },
    hide () {
        this.display = false
    },
    ok () {
      this.$emit('save', this.selectedProfile)
      this.editableItem = {}
    },
    cancel () {
      this.hide()
      this.$emit('canceled')
    },
    handleInput(e){
      this.editableItem[e.target.name] = e.target.value
      this.$emit('input', this.editableItem)
    },
    async isCompleted () {
      const valid = await this.$refs.observer.validate()
      return valid
    },
    async save(){
      if(!await this.isCompleted()) return;
      
      if(this.selectedProfile.id == null){
        this.error = true
        return
      }
      
      this.$emit('save', {
        ...this.editableItem,
        entreprise: this.selectedProfile,
        // dossiers: this.selectedDemandeurs
      })
      this.editableItem = {}
      this.hide()
    },
  }
  
}
</script>
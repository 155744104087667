/* eslint-disable vue/no-unused-components */
<template>
  <div :class="{ 'container-fluid': !hideHeader }">
    <CDetailViewer
      v-if="activeProjet"
      title="Détail du microprojet"
      ref="detailViewer"
      :item="activeProjet"
    >
      <PTabView :activeIndex.sync="activeViewerTab">
        <PTabPanel header="Microprojet">
          <MicroprojetDetail
            ref="microprojetDetailViewer"
            v-if="activeProjet != null && activeProjet.id != null"
            :microprojetId="activeProjet.id"
            @edit="editMicroprojet"
            @validate="showDossierValidationDialog"
          />
        </PTabPanel>
        <PTabPanel header="Bénéficiaire">
          <FicheAzoli
            :readOnly="true"
            v-if="activeProjet != null && activeProjet.id != null"
            :demandeurId="activeProjet.beneficiaireId"
          />
        </PTabPanel>
        <PTabPanel header="Screening">
          <Screening :microprojetId="activeProjet.id" />
        </PTabPanel>
      </PTabView>
    </CDetailViewer>
    <MicroprojetStatistic
      ref="microprojetStatistic"
      :extraQueryArg="statisticsQueryParams"
    />
    <MicroprojetEditor
      :title="
        activeProjet && activeProjet.id
          ? 'Traiter le microprojet'
          : 'Traiter le  microprojet'
      "
      :item="activeProjet"
      :beneficiaire="activeProjet.beneficiare"
      ref="microprojetEditor"
      @save="saveMicroprojet"
      @change="submitQuery"
    />
    <MicroprojetClonageDialog ref="clonageDialog"> </MicroprojetClonageDialog>
    <div class="container- mt-1">
      <template v-if="!hideHeader">
        <p-progress-bar v-if="mxLoading" style="height: 0.33em" mode="indeterminate" />
        <div class="bg-white p-2 rounded-4">
          <form class="bg-" method="post" accept-charset="utf-8" action="">
            <div class="row py-0 p-fluid grid formgrid">
              <div class="col col-sm-12 field">
                <form class="d-flex">
                  <div class="input-group me-4">
                    <div class="p-inputgroup">
                      <PInputText
                        type="text"
                        v-model="mxSearch"
                        :placeholder="'Rechercher par'"
                      />
                      <p-multi-select
                        v-model="mxSearchFields"
                        :options="searchFieldsOptions.filter((el) => el.visible)"
                        optionLabel="label"
                        optionValue="value"
                        :editable="true"
                      />
                    </div>
                  </div>
                </form>
              </div>
              <div class="col-md-10 col-sm-12 d-flex">
                <div class="d-flex flex-wrap">
                  <p-dropdown
                    class="flex-grow- me-2"
                    v-model="sexeDe"
                    :options="sexeOptions"
                    optionLabel="label"
                    optionValue="value"
                    :placeholder="'Sexe DE'"
                    v-tooltip.top="'Sexe DE'"
                    display="chip"
                  />
                  <p-dropdown
                    class="flex-grow-1 me-2"
                    v-model="demandeurVulnerabilite"
                    :options="demandeurVulnerabiliteOptions"
                    optionLabel="label"
                    optionValue="value"
                    :placeholder="'Vulnérabilité'"
                    v-tooltip.top="'Vulnérabilité'"
                    display="chip"
                  />
                  <p-dropdown
                    class="flex-grow-1 me-2"
                    v-model="demandeurType"
                    :options="demandeurTypeOptions"
                    optionLabel="label"
                    optionValue="value"
                    :placeholder="'Type de DE'"
                    display="chip"
                  />
                  <div>
                    <p-dropdown
                      class="flex-grow-1 me-2"
                      v-model="status"
                      :options="statusOptions"
                      optionLabel="label"
                      optionValue="value"
                      :placeholder="'Statut Complétion'"
                      v-tooltip.top="'Statut Complétion Microprojet'"
                      display="chip"
                    />
                  </div>
                  <div>
                    <p-dropdown
                      class="flex-grow-1 me-2"
                      v-model="statutValidation"
                      :options="statutValidationOptions"
                      optionLabel="label"
                      optionValue="value"
                      v-tooltip.top="'Statut Validation Microprojet'"
                      :placeholder="'Statut Validation'"
                      display="chip"
                    />
                  </div>

                  <div>
                    <p-dropdown
                      class="flex-grow-1 me-2"
                      v-model="statutTransmission"
                      :options="statutTransmissionOptions"
                      optionLabel="label"
                      optionValue="value"
                      v-tooltip.top="'Statut Transmission Microprojet'"
                      :placeholder="'Statut Transmission'"
                      display="chip"
                    />
                  </div>

                  <p-dropdown
                    class="flex-grow-1 me-2"
                    v-model="statusScreening"
                    :options="statusScreeningOptions"
                    optionLabel="label"
                    optionValue="value"
                    v-tooltip.top="'Statut Screening Microprojet'"
                    :placeholder="'Statut Screening'"
                    display="chip"
                  />
                </div>
              </div>
              <div class="col-auto">
                <div class="d-grid gap-2">
                  <a
                    href="#"
                    @click.prevent="submitQuery"
                    class="style_btn btn btn-primary"
                    id="button-query"
                    ><i class="bi bi-search"></i
                  ></a>
                </div>
              </div>
              <div class="col-auto">
                <button
                  type="button"
                  @click.prevent="showAdvancedFilter = !showAdvancedFilter"
                  class="style_btn btn btn-warning"
                  id="button-query"
                >
                  <i class="bi bi-filter"></i>
                </button>
              </div>
            </div>
          </form>
          <transition>
            <div class="border p-3 rounded shadow-0 mt-2" v-if="showAdvancedFilter">
              <div class="row p-fluid grid">
                <div class="field col-12 col-md-3 col-sm-12 mb-3">
                  <label for="dateDeb">Date de début</label>
                  <p-calendar
                    placeholder="Date de début"
                    v-model="dateDeb"
                    locale="fr"
                    :showIcon="true"
                    :manualInput="false"
                  />
                </div>
                <div class="field col-12 col-md-3 col-sm-12 mb-3">
                  <label for="dateFin">Date de fin</label>
                  <p-calendar
                    placeholder="Date de fin"
                    class="flex-grow-1"
                    v-model="dateFin"
                    :showIcon="true"
                    :manualInput="false"
                  />
                </div>
                <template v-for="(option, index) in filterOptions">
                  <div
                    class="col-12 col-sm-6 col-md-3 col-sm-12 mb-3"
                    v-if="option.visible"
                    :key="index"
                  >
                    <label :for="option.labelField">{{ option.label }}</label>

                    <AsyncMultiSelect
                      v-model="option.value"
                      :multiple="true"
                      :queryUrl="option.queryUrl"
                      :optionLabel="option.labelField"
                      :placeholder="option.label"
                      :emitObject="false"
                      :displayMode="option.displayMode"
                      :searchOptions="[option.labelField]"
                    />
                  </div>
                </template>
              </div>
              <div class="d-flex justify-content-end">
                <!-- <PButton class="p-button-primary p-button- py-1 p-button-sm ms-1" @click.prevent.stop="resetFilterWithFromOffre">
            Matching avec les critères de l'offre
          </PButton> -->
                <PButton
                  class="p-button-danger p-button- py-1 p-button-sm ms-1"
                  @click.prevent.stop="reinitialiserFiltres"
                >
                  Réinitialiser les filtres
                </PButton>
              </div>
            </div>
          </transition>
        </div>
        <p-divider />
        <div class="d-flex flex-wrap mb-4 justify-content-between align-items-center">
          <div class="my-1 flex-fill">
            <h4>Liste des microprojets</h4>
          </div>
          <div v-if="$can('create', 'Azoli')" class="btn-group">
            <a
              @click.prevent="showClonageMicroprojetDialog"
              href=""
              v-if="$can('CREATE_CLONE', 'MICRO_PROJET')"
              class="style_btn btn btn-warning"
              >Clonnage Microprojets</a
            >
          </div>
          <div class="dropdown">
            <button
              v-if="$can('transfer', 'FinOc')"
              class="btn ms-4 style_btn btn-primary dropdown-toggle"
              type="button"
              id="dropdownMenuButton1"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              Affectation
            </button>
            <ul
              class="dropdown-menu dropdown-menu-end py-0"
              aria-labelledby="dropdownMenuButton1"
              style="margin: 0px"
            >
              <li v-if="$can('transferToAgent', 'FinOc')" class="border">
                <conseiller-selector
                  size="lg"
                  title=""
                  @saved="transfertDemandeur"
                  ref="conseillerSelector"
                >
                  <template #action="{ show }">
                    <a class="dropdown-item" @click="show" href="#"
                      >Affecter à des conseillers</a
                    >
                  </template>
                </conseiller-selector>
              </li>
              <li v-if="$can('transferToAntenne', 'FinOc')" class="border">
                <antenne-selector-dialog
                  size="lg"
                  title=""
                  @saved="transfertDemandeur"
                  ref="antenneSelector"
                >
                  <template #action="{ show }">
                    <a class="dropdown-item" @click="show" href="#"
                      >Affecter vers une antenne</a
                    >
                  </template>
                </antenne-selector-dialog>
                <!-- <a class="dropdown-item" href="#">Affecter vers une antenne</a> -->
              </li>
            </ul>
          </div>
          <div v-if="$can('export', 'Azoli')" class="btn-group ms-4">
            <PSplitButton
              label="Exporter"
              :model="exportActions"
              class="p-button-outlined p-button-primary"
            />
          </div>
          <div class="btn-group ms-4">
            <button
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title="Affichage mode tableau"
              @click.prevent="viewMode = 'card'"
              class="btn style_btn btn-md"
              :class="{
                'btn-success': viewMode == 'card',
                'btn-light': viewMode == 'table',
              }"
            >
              <i class="bi-grid" />
            </button>
            <button
              @click.prevent="viewMode = 'table'"
              class="btn style_btn"
              :class="{
                'btn-light': viewMode == 'card',
                'btn-success': viewMode == 'table',
              }"
            >
              <i class="bi-table" />
            </button>
          </div>
        </div>
      </template>
      <template v-if="!mxLoading">
        <div class="bg-">
          <div class="bg-white p-3 border">
            <div class="table-responsive">
              <b-table
                show-empty
                empty-text="Aucune donnée à afficher"
                :fields="tableFields"
                class="align-middle"
                striped
                select-mode="single"
                ref="table"
                selectable
                @row-selected="onRowSelected"
                :sort-by.sync="mxPagination.sortBy"
                :sort-desc.sync="mxPagination.sortDesc"
                responsive="sm"
                sort-icon-left
                :items="tableData"
              >
                <template #head()="data">
                  <span>{{ data.label }} </span>
                </template>
                <template #head(#)="data">
                  <div class="form-check form-check-inline">
                    <input
                      @change="selectAll"
                      class="form-check-input"
                      type="checkbox"
                      :id="`${data.index}`"
                      name="disponible"
                      :value="false"
                    />
                  </div>
                </template>
                <!-- A virtual column -->
                <template #cell(index)="data">
                  {{ data.index + 1 }}
                </template>

                <template #cell(#)="data">
                  <div class="form-check form-check-inline">
                    <input
                      @input="selectionChanged($event, data)"
                      :checked="data.rowSelected"
                      class="form-check-input"
                      type="checkbox"
                      :id="`${data.index}`"
                      name="disponible"
                    />
                  </div>
                </template>

                <template #cell(status)="{ item: de }">
                  <div class="d-flex justify- mb-3">
                    <span
                      v-if="
                        de.statutValidation == null || de.statutValidation == 'NON_SOUMIS'
                      "
                      v-badge.danger="'NS'"
                      v-tooltip.top="'NON_SOUMIS'"
                    ></span>
                    <span
                      v-if="de.statutValidation == 'SOUMIS'"
                      v-badge.warning="'SOU'"
                      v-tooltip.top="'SOUMIS'"
                    ></span>
                    <span
                      v-if="de.statutValidation == 'APPROUVE'"
                      v-badge.info="'APP'"
                      v-tooltip.top="'APPROUVE'"
                    ></span>
                    <span
                      v-if="de.statutValidation == 'VALIDE'"
                      v-badge.success="'VAL'"
                      v-tooltip.top="'VALIDE'"
                    ></span>
                    <PBadge
                      v-tooltip.top="
                        `${de.status} | ${de.statutValidation || 'NON_SOUMIS'} | ${
                          de.statutTransmission || 'NON_TRANSMIS'
                        }`
                      "
                      :value="`${de.status}`"
                      :severity="getStatusColor(de.status)"
                    />
                    <span
                      v-if="
                        de.statutTransmission == null ||
                        de.statutTransmission == 'NON_TRANSMIS'
                      "
                      v-badge.warning="'NT'"
                      v-tooltip.top="'NON_TRANSMIS'"
                    ></span>
                    <span
                      v-if="de.statutTransmission == 'TRANSMIS'"
                      v-badge.success="'TRA'"
                      v-tooltip.top="'TRANSMIS'"
                    ></span>
                  </div>
                </template>
                <template #cell(createdDate)="{ item: de }">
                  <div class="d-flex justify- mb-3">
                    {{ $dayjs(de.createdDate).format("DD/MM/YYYY") }}
                  </div>
                </template>

                <template #cell(tauxRemplissage)="{ item }">
                  <span v-if="!item.hasScreening">{{ item.tauxRemplissage }}%</span>
                  <template v-else>
                    <span
                      v-if="item.statutScreening == 'TERMINE'"
                      class="text"
                      v-tooltip.top="
                        'Screening ' +
                        (item.statutScreening == null ? 'EN_COURS' : item.statutScreening)
                      "
                      v-badge.primary="'Scr'"
                      >{{ item.tauxRemplissage }}%</span
                    >
                    <span
                      v-else-if="item.statutScreening == 'VALIDE'"
                      class="text"
                      v-tooltip.top="'Screening VALIDE'"
                      v-badge.success="'Scr'"
                      >{{ item.tauxRemplissage }}%</span
                    >
                    <span
                      v-else
                      class="text"
                      v-tooltip.top="
                        'Screening ' +
                        (item.statutScreening == null ? 'EN_COURS' : item.statutScreening)
                      "
                      v-badge.danger="'Scr'"
                      >{{ item.tauxRemplissage }}%</span
                    >
                  </template>
                </template>
                <template #cell(npi)="{ item: de }">
                  <span v-if="de.npiValide == null">{{ de.npi }}</span>
                  <span
                    v-else
                    class="text-uppercase"
                    v-tooltip.top="
                      `NPI validé ${de.validePar != null ? de.validePar.nomPrenom : ''}`
                    "
                    style="font-size: 1.2rem"
                    v-badge.primary="de.npiValide ? 'Ok' : ''"
                    >{{ de.npi }}</span
                  >
                </template>
                <template #cell(nom)="{ item: de }">
                  <span class="text-uppercase">{{ de.nom }}</span>
                </template>
                <template #cell(prenom)="{ item: de }">
                  <span class="text-uppercase">{{ de.prenom }}</span>
                </template>

                <template #row-details="row">
                  <div class="bg- pa-2">
                    <PTabView :activeIndex.sync="activeTab">
                      <!-- <PTabPanel header="Prise En Charge">
                        <PriseEnCharge :demandeurId="row.item.beneficiaireId" />
                      </PTabPanel>
                      <PTabPanel header="CV">
                        <DemandeurMonCv :checkHasCv="false" :demandeurId="row.item.beneficiaireId" />
                      </PTabPanel>
                      <PTabPanel header="Fiche d'enquête">
                        <FicheAzoli
                          :demandeurId="row.item.beneficiaireId"
                        />
                      </PTabPanel>
                      <PTabPanel header="Tâches plannifiées">
                        <ActiviteAnpeList
                          :extraQueryArg="`participantId=${row.item.beneficiaireId}`"
                        />
                      </PTabPanel>
                      <PTabPanel header="Liste programmes">
                        <ParticipationProgrammeList
                          :extraQueryArg="`participantId=${row.item.beneficiaireId}`"
                        />
                      </PTabPanel>
                      <PTabPanel header="Consignes">
                        <ConsigneList :extraQueryArg="`participantId=${row.item.beneficiaireId}`" />
                      </PTabPanel>
                      <PTabPanel header="Prescriptions">
                      </PTabPanel>
                      <PTabPanel header="Placements"> Content III </PTabPanel> -->
                      <PTabPanel header="Screening">
                        <Screening :microprojetId="row.item.id" />
                      </PTabPanel>
                      <!-- <PTabPanel header="Candiatures"> </PTabPanel> -->
                      <!-- <PTabPanel header="Payements"> </PTabPanel> -->
                      <!-- <PTabPanel header="Statistiques"> Content III </PTabPanel> -->
                    </PTabView>
                  </div>
                </template>

                <template #cell(actions)="row">
                  <div class="d-flex justify-content-end">
                    <button
                      class="btn btn-sm py-1 btn-outline-primary me-1"
                      @click="mxToggleDetails(row, tableData)"
                    >
                      <i class="bi bi-journal-check"></i>
                    </button>
                    <a
                      class="btn btn-danger rounded-0 btn-sm me-1"
                      title="Générer PDF"
                      @click.stop.prevent="downloadFiche(row.item)"
                      ><i class="bi bi-printer"></i
                    ></a>
                    <button
                      v-tooltip.left="'Valider le microprojet'"
                      @click.prevent="showDetailDialog(row.item)"
                      class="btn btn-light btn-sm"
                    >
                      <i
                        :class="{
                          'text-danger': row.item.status == 'VALIDE',
                        }"
                        class="bi bi-clipboard-check"
                      ></i>
                    </button>
                    <button
                      v-tooltip.left="'Voir détail du microprojet'"
                      @click.prevent="showDetailDialog(row.item)"
                      class="btn btn-outline-info btn-sm"
                    >
                      <i
                        :class="{
                          'text-danger': row.item.status == 'VALIDE',
                        }"
                        class="bi bi-eye"
                      ></i>
                    </button>
                  </div>
                </template>
                <!-- Optional default data cell scoped slot -->
                <template #cell()="data">
                  <span>{{ data.value }}</span>
                </template>
              </b-table>
            </div>
            <div class="d-flex flex-wrap justify-content-between align-items-center">
              <p>
                Affichage de l’élément
                {{
                  (mxPagination.page <= 0 ? 0 : mxPagination.page - 1) *
                  mxPagination.rowsPerPage
                }}
                à
                {{
                  (mxPagination.page <= 0 ? 1 : mxPagination.page) *
                  mxPagination.rowsPerPage
                }}
                de {{ mxTotalCount }} éléments
              </p>
              <b-pagination
                v-model="mxPagination.page"
                :total-rows="mxTotalCount"
                :per-page="mxPagination.rowsPerPage"
                aria-controls="my-table"
              ></b-pagination>
            </div>
          </div>
        </div>
      </template>
      <template v-else>
        <div>
          <PDataTable
            :value="
              [1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((e) => {
                e;
              })
            "
          >
            <PColumn
              v-for="f in tableFields"
              :key="f.key"
              :field="f.key"
              :header="f.label"
            >
              <template #body>
                <PSkeleton></PSkeleton>
              </template>
            </PColumn>
          </PDataTable>
        </div>
      </template>
      <div class="mb-4 bg-light" v-if="!mxLoading && mxFilteredItems.length == 0">
        <div
          style="min-height: 200px"
          class="no-content pa-4 d-flex flex-column justify-content-center align-items-center"
        >
          <span class="text-muted mb-3">Votre porte-feuille est vide</span>
          <a
            v-if="$can('MICRO_PROJET', 'CREATE_CLONE')"
            href=""
            class="style_btn btn btn-sm btn-outline-primary"
            >Clonnage Microprojets</a
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { paginatorMixin } from "@/mixins/paginator-mixin";
import DeStatistic from "../../../components/espace/demandeur/statistic/DeStatistic.vue";
import FicheAzoliReport from "../../../components/report/FicheAzoliReport.vue";
import ReportHeader from "../../../components/report/ReportHeader.vue";
import AzoliStatusBadge from "../../../components/common/AzoliStatusBadge.vue";
import CAutoComplete from "../../../components/common/CAutoComplete.vue";
import CDetailViewer from "../../../components/common/CDetailViewer.vue";
import AsyncMultiSelect from "../../../components/common/AsyncMultiSelect.vue";

import ConseillerSelector from "../../../components/espace/antenne/transfert/ConseillerSelector.vue";
import AntenneSelectorDialog from "../../../components/espace/antenne/transfert/AntenneSelectorDialog.vue";
// import * as XLSX from 'xlsx'
import ActiviteAnpeList from "./ActiviteAnpeList.vue";
import ConsigneList from "./ConsigneList.vue";
import PrescriptionList from "./PrescriptionList.vue";
import ParticipationProgrammeList from "./ParticipationProgrammeList.vue";
import PriseEnCharge from "./PriseEnCharge.vue";
import DemandeurMonCv from "../demandeur/DemandeurMonCv.vue";
import FicheAzoli from "../conseiller/DemandeurDetailAzoli.vue";
import MicroprojetDetail from "../common/MicroprojetDetail.vue";

import { FILE_UPLOAD_API_URL } from "../../../config/api-config";
// import MicroprojetValidationDialog from "../../../components/espace/demandeur/DemandeurDossierValidationDialog.vue";
import MicroprojetEditor from "../../../components/espace/common/prise-en-charge/editor/MicroprojetEditor.vue";
import MicroprojetClonageDialog from "../../../components/espace/common/prise-en-charge/editor/MicroprojetClonageDialog.vue";
import Screening from "@/views/espace/entreprise/screening/Screening.vue";
import MicroprojetStatistic from "../../../components/statistic/microprojet/MicroprojetStatistic.vue";

export default {
  props: {
    agentId: {
      type: Number,
      default: () => null,
    },
    extraQueryArg: {
      type: String,
      default: "",
    },
    hideStatistic: {
      type: Boolean,
      default: false,
    },
    hideHeader: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    DeStatistic,
    Screening,
    FicheAzoliReport,
    FicheAzoli,
    MicroprojetDetail,
    ReportHeader,
    ConseillerSelector,
    DemandeurMonCv,
    AntenneSelectorDialog,
    ActiviteAnpeList,
    ConsigneList,
    CDetailViewer,
    PrescriptionList,
    ParticipationProgrammeList,
    AzoliStatusBadge,
    PriseEnCharge,
    CAutoComplete,
    MicroprojetEditor,
    MicroprojetClonageDialog,
    AsyncMultiSelect,
    MicroprojetStatistic,
  },
  mixins: [paginatorMixin],
  data() {
    return {
      selected: [],
      search: "",
      dateDeb: null,
      dateFin: null,
      activeTab: 0,
      activeViewerTab: 0,
      showAdvancedFilter: false,
      femmesAvecEnfantsCount: 0,
      nombreEnfants3Mois3AnsCount: 0,
      femmeEnceinteCount: 0,
      viewMode: localStorage.getItem("viewMode") || "card", //card, table
      parDepartementEnregistres: [],
      parDepartementFemmesEtEnfantsEntre3MoisEt3Ans: [],
      parCommuneEnregistres: [],
      parAeEnregistres: [],
      demandeurTypeOptions: [
        { value: null, label: "Tous" },
        { value: "PPI", label: "PPI" },
        { value: "CI", label: "CI" },
        { value: "INSTRUIT", label: "INSTRUIT" },
      ],
      demandeurType: null,
      statusOptions: [
        { value: null, label: "Tous" },
        { value: "NOUVEAU", label: "NOUVEAU" },
        { value: "EN_COURS", label: "EN_COURS" },
        { value: "TERMINE", label: "TERMINE" },
        { value: "VALIDE", label: "VALIDE" },
      ],
      statutValidationOptions: [
        { value: null, label: "Tous" },
        { value: "NON_SOUMIS", label: "NON_SOUMIS" },
        { value: "SOUMIS", label: "SOUMIS" },
        { value: "APPROVE", label: "APPROVE" },
        { value: "VALIDE", label: "VALIDE" },
      ],
      statutTransmissionOptions: [
        { value: null, label: "Tous" },
        { value: "NON_TRANSMIS", label: "NON_TRANSMIS" },
        { value: "TRANSMIS", label: "TRANSMIS" },
      ],
      statusScreening: null,
      statutTransmission: null,
      statutValidation: null,
      statusScreeningOptions: [
        { value: null, label: "Tous" },
        { value: "EN_COURS", label: "EN_COURS" },
        { value: "TERMINE", label: "TERMINE" },
        { value: "VALIDE", label: "VALIDE" },
      ],
      status: null,
      statutPlacementOptions: [
        { value: null, label: "Tous" },
        { value: true, label: "PLACE" },
        { value: false, label: "NON PLACE" },
      ],
      statutPlacement: null,
      sexeOptions: [
        { value: null, label: "Tous" },
        { value: "M", label: "Masculin" },
        { value: "F", label: "Féminin" },
      ],
      sexeDe: null,
      demandeurVulnerabiliteOptions: [
        { value: null, label: "Tous" },
        { value: true, label: "VULNERABLE" },
        { value: false, label: "NON VULNERABLE" },
      ],
      demandeurVulnerabilite: null,
      exportActions: [
        {
          label: "PDF",
          icon: "pi pi-file-pdf",
          command: () => {
            this.mxExportToPDF({
              meta: {
                title: "Liste des microprojets",
                subtitle: "Liste des microprojets",
              },
              pathParams: {
                name: "common.export",
                query: {
                  apiPath: "microprojets/exportToPdf",
                  queryParams: this.mxGetQueryBuilder(),
                  fileName: "Liste des microprojets.pdf",
                },
              },
            });
          },
        },
        {
          label: "Excel",
          icon: "pi  pi-file-excel",
          command: () => {
            this.mxExportToExcel({
              meta: {
                title: "Liste des microprojets",
                subtitle: "Liste des microprojets",
              },
              pathParams: {
                name: "common.export",
                query: {
                  apiPath: "microprojets/exportToExcel",
                  queryParams: this.mxGetQueryBuilder(),
                  fileName: "Liste des microprojets.xlsx",
                },
              },
            });
          },
        },
      ],
      searchFields: ["titre"],
      searchFieldsOptions: [
        {
          label: "Titre",
          value: "titre",
          visible: true,
        },
        {
          label: "Bénéficiaire",
          value: "beneficiaire.nomPrenom",
          visible: true,
        },
        {
          label: "NPI",
          value: "beneficiaire.npi",
          visible: true,
        },
      ],
      filterOptions: [
        {
          column: "programme.id",
          value: [],
          label: "Programme",
          dropdown: true,
          labelField: "libelle",
          displayMode: "mega-menu",
          valueKey: "id",
          queryUrl: "programmes",
          visible: true,
        },
        {
          column: "secteur.id",
          value: [],
          label: "Secteur d'Activité",
          dropdown: true,
          labelField: "libelle",
          displayMode: "classic",
          valueKey: "id",
          queryUrl: "naemas",
          visible: true,
        },
        {
          column: "metier.id",
          value: "",
          label: "Métier",
          valueKey: "id",
          dropdown: true,
          dropdown: true,
          displayMode: "classic",
          labelField: "libelle",
          queryUrl: "romes",
          visible: true,
        },
        {
          column: "departement.id",
          value: "",
          labelField: "nom",
          label: "Départements",
          queryUrl: "departements",
          displayMode: "classic",
          multiple: true,
          valueKey: "id",
          dropdown: true,
          visible: true,
        },
        {
          column: "commune.id",
          value: "",
          labelField: "nom",
          multiple: true,
          valueKey: "id",
          label: "Communes",
          displayMode: "classic",
          queryUrl: "communes",
          dropdown: true,
          visible: true,
        },
        {
          column: "arrondissement.id",
          value: "",
          labelField: "nom",
          label: "Arrondissements",
          multiple: true,
          valueKey: "id",
          queryUrl: "arrondissements",
          displayMode: "classic",
          dropdown: true,
          visible: true,
        },
        {
          column: "ville.id",
          value: "",
          labelField: "nom",
          multiple: true,
          label: "Village/Quartier(s)",
          valueKey: "id",
          displayMode: "classic",
          queryUrl: "villes",
          dropdown: true,
          visible: true,
        },
      ],
      filters: {
        skills: [],
        availabilities: [],
        price_min: null,
        price_max: null,
        departement: null,
        _queryOptions: [
          {
            column: "departement.nom",
            value: null,
            label: "Département",
          },
          {
            column: "commune.nom",
            value: null,
            label: "Commune",
          },
        ],
        ordering: "",
        // _orderingOptions: {
        // }
      },
      activeProjet: {},
      breadcrumbs: [
        {
          text: "Admin",
          href: "#",
        },
        {
          text: "Paramètres",
          href: "#",
        },
        {
          text: "Demandeurs",
          active: true,
        },
      ],
    };
  },
  created() {
    this.queryStatistics();
  },
  watch: {
    dossier(val) {
      if (val.azoli) {
        // this.activeProjet = {
        //   ...val,
        //   ...val.azoli, // écrase id du demandeur par id azoli
        //   id: val.id, //remettre Id du demandeur
        // };
      }
    },
    viewMode() {
      localStorage.setItem("viewMode", this.viewMode);
    },
    extraQueryArg() {
      this.queryStatistics();
    },
    // dateDeb(){
    //   this.queryStatistics()
    // },
    // dateFin(){
    //   this.queryStatistics()
    // }
  },
  computed: {
    ...mapGetters({
      dossier: "demandeur/demandeur",
      statutDossiers: "setting/statutDossiers",
      // demandeurs: 'demandeur/demandeurs',
      pagination: "demandeur/pagination",
    }),
    tableFields() {
      let fields = [
        "#",
        // 'index',
        { key: "reference", label: "Ref" },
        { key: "titre", label: "Microprojet" },
        { key: "beneficiaireNom", label: "Nom" },
        { key: "beneficiairePrenom", label: "Prénom" },
        { key: "beneficiaireSexe", label: "Sexe" },
        { key: "departement.nom", label: "Département" },
        { key: "commune.nom", label: "Commune" },
        { key: "programme.nom", label: "Programme" },
        // { key: "accompagnateur.nomPrenom", label: "Accompagnateur" },
        { key: "status", label: "Statut" },

        { key: "tauxRemplissage", label: "TauxRemp." },
        // { key: "fnmAccessCount", label: "Nbre.Access" },
      ];

      fields = fields.concat([
        // { key: "status", label: "Statut" },
        // { key: 'validePar', label: 'Validé par' },
        "actions",
      ]);

      return fields;
    },
    tableData() {
      return this.mxFilteredItems.map((item) => {
        return { ...item };
      });
    },
    fileApiUrl() {
      return FILE_UPLOAD_API_URL;
    },
    statisticsQueryParams() {
      return this.mxBuildQueryParams();
    },
  },
  methods: {
    ...mapActions({
      validerMicroprojet: "microprojet/validerMicroprojet",
      createOrUpdateMicroprojet: "microprojet/createOrUpdateMicroprojet",
      telechargerMicroprojet: "microprojet/telechargerMicroprojet",
    }),
    submitQuery() {
      this.mxSubmitQuery();
      this.$refs.microprojetStatistic.reload();
    },
    getStatusColor(status) {
      let color = "primary";
      if (status == "NOUVEAU") {
        color = "danger";
      }
      if (status == "VALIDE") {
        color = "success";
      }
      if (status == "EN_COURS") {
        color = "warning";
      }
      if (status == "TERMINE") {
        color = "primary";
      }
      if (status == "SUSPENDU") {
        color = "danger";
      }
      return color;
    },
    editMicroprojet(stepName) {
      this.$refs.microprojetEditor.showFromStep(stepName, false);
    },
    saveMicroprojet(item) {
      this.createOrUpdateMicroprojet(item)
        .then((data) => {
          this.$toast.success("Opération réussie", {
            position: "top-right",
            duration: 10000,
          });
          this.$refs.microprojetDetailViewer.setMicroprojet(data);
        })
        .catch((err) => this.$toast.error("Une erreur s'est produite." + err));
    },
    priseEnCharge(de) {
      this.$router.push({
        name: "espace.common.de.followup",
        params: {
          userRole: this.$route.params.userRole,
          demandeurId: de.id,
        },
      });
    },
    transfertDemandeur(agent) {
      console.log("Agent: ", agent);
      if (this.selected.length == 0) {
        this.$toast.error(
          "Aucun demandeur sélectionné. Passez en mode tableau puis sélectionnez le(s) demandeur(s) avant de continuer. ",
          {
            position: "top-right",
            duration: 8000,
          }
        );
        return;
      }
      this.transfererVersConseiller({
        agent,
        demandeur: this.selected[0],
      }).then((data) => {
        console.log(data);
        this.$toast.success("Opération réussie!", {
          position: "top-right",
          duration: 8000,
        });
      });
    },
    statisticBlockSelected(status) {
      this.mxSetOptionValue("status", status, true);
    },
    queryStatistics() {
      this.mxInitializePaginator({
        queryModel: "microprojet",
        search: this.search,
        fetcherMethod: "microprojet/fetchMicroprojets",
        dataGetter: "microprojet/microprojets",
        paginationGetter: "microprojet/pagination",
        autoSubmitQuery: false,
        pagination: {
          sortBy: "id",
          descending: false,
          page: 0,
          rowsPerPage: 8,
          sortingParam: `sort=id,desc`,
        },
        filterOptions: this.filterOptions,
        extraQueryArgsParamBuilder: () => {
          const dateDeb = (this.dateDeb || new Date("2021-12-31"))
            .toISOString()
            .substring(0, 10);
          const dateFin = (this.dateFin || new Date("2080-12-31"))
            .toISOString()
            .substring(0, 10);

          let paramPlus = this.mxAddQueryParam(
            "beneficiaire.categorieDemandeur",
            this.demandeurType
          );
          paramPlus = this.mxAddQueryParam(
            "beneficiaire.metaInfo.estVulnerable",
            this.demandeurVulnerabilite,
            paramPlus
          );
          paramPlus = this.mxAddQueryParam("beneficiaire.sexe", this.sexeDe, paramPlus);
          paramPlus = this.mxAddQueryParam("status", this.status, paramPlus);
          paramPlus = this.mxAddQueryParam(
            "statutValidation",
            this.statutValidation,
            paramPlus
          );
          paramPlus = this.mxAddQueryParam(
            "statutTransmission",
            this.statutTransmission,
            paramPlus
          );
          paramPlus = this.mxAddQueryParam(
            "statutScreening",
            this.statusScreening,
            paramPlus
          );
          paramPlus = this.mxAddQueryParam(
            "beneficiaire.estPlace",
            this.statutPlacement,
            paramPlus
          );

          let params = `dateDeb=${dateDeb}&dateFin=${dateFin}&${this.extraQueryArg}&${paramPlus}`;
          return params;
        },
        searchFields: this.searchFields,
      });
    },
    formatToDataTable(data, { keyName }) {
      let summary = {};
      return Object.keys(data).map((k) => {
        let { key, M = 0, F = 0, total = 0 } = data[k];
        summary.M += M;
        summary.F += F;
        summary.total += total;
        if (data[k]["TOTAL"] == undefined) {
          return {
            [keyName]: key,
            M,
            F,
            total,
          };
        } else {
          return {
            [keyName]: "TOTAL",
            ...summary,
          };
        }
      });
    },
    selectionChanged(evt, data) {
      console.log(evt.target.checked, data);

      if (evt.target.checked) {
        this.$refs.table.selectRow(data.index);
      } else {
        this.$refs.table.unselectRow(data.index);
      }
    },
    onRowSelected(items) {
      this.selected = items;
    },
    selectAll(evt) {
      if (evt.target.checked) {
        this.$refs.table.selectAllRows();
      } else {
        this.$refs.table.clearSelected();
      }
    },
    uploadFile(de) {
      console.log(de);
      this.$refs[`pond2`].click();
    },
    onProgress(e) {
      console.log(e);
    },
    downloadFiche(microprojet) {
      this.telechargerMicroprojet({
        ...microprojet,
      }).then(() => {
        this.$toast.success("Téléchargement terminé!");
      });
    },
    editDemandeur(demandeur) {
      console.log(demandeur);

      this.activeProjet = { ...demandeur };
      this.$router.push({ name: "espace.ce.de.edit" });
    },
    showDetailDialog(demandeur) {
      this.activeProjet = { ...demandeur };
      this.$refs.detailViewer.show();
    },
    showClonageMicroprojetDialog() {
      this.$refs.clonageDialog.show();
    },
    remplirFiche(demandeur) {
      if (!demandeur.estPPI) {
        this.$toast.info(
          "Vous ne pouvez pas remplir de fiche pour ce demandeur INSTRUIT.",
          {
            position: "top-right",
            duration: 8000,
          }
        );
      } else {
        this.$router.push({
          name: "espace.ce.azoli.edit",
          params: { demandeurId: demandeur.id },
        });
      }
    },
    async showDossierValidationDialog(micro) {
      this.$refs.detailViewer.hide();
      await this.$confirm.require({
        group: "confirmDialog",
        message: `Voulez-vous vraiment le microprojet ${micro.titre.toUpperCase()}?`,
        header: "Confirmation",
        icon: "pi pi-exclamation-triangle",
        accept: () => {
          this.validerMicroprojet({
            ...micro,
            status: "VALIDE",
          }).then((data) => {
            this.$toast.success("Microprojet validé avec succès!", {
              position: "top-right",
              duration: 10000,
            });
            this.mxReplayQuery();
          });
        },
      });
      // this.activeProjet = { ...demandeur }
      // this.$refs.microprojetValidator.show()
    },
    async validerFiche(demandeur) {
      if (demandeur.status == "VALIDE") {
        this.$toast.info(demandeur.estPPI ? "Fiche déjà validée!" : "CV déjà validé", {
          position: "top-right",
          duration: 8000,
        });
        return;
      }

      if (demandeur.status != "TERMINE") {
        this.$toast.error(demandeur.estPPI ? "Fiche non terminée" : "CV non terminé", {
          position: "top-right",
          duration: 8000,
        });
        return;
      }

      await this.$confirm.require({
        group: "confirmDialog",
        message: demandeur.estPPI
          ? `Voulez-vous vraiment valider  la fiche Azoli de ${demandeur.nom} ${demandeur.prenom}?`
          : `Voulez-vous vraiment valider le CV de ${demandeur.nom} ${demandeur.prenom}?`,
        header: "Confirmation",
        icon: "pi pi-exclamation-triangle",
        accept: () => {
          this.validerDossier({
            ...demandeur,
            status: "VALIDE",
          }).then((data) => {
            console.log(data);
            this.$refs.deStatistic.reload();
            this.$toast.success("Microprojet validé avec succès!", {
              position: "top-right",
              duration: 10000,
            });
          });
        },
      });
    },
    async destroyDemandeur(demandeur) {
      console.log(demandeur);
      await this.$confirm.require({
        group: "confirmDialog",
        message: "Voulez-vous vraiment supprimer cet élément?",
        header: "Confirmation",
        icon: "pi pi-exclamation-triangle",
        accept: () => {
          this.deleteDemandeur(demandeur);
        },
      });
    },
    updateOrCreateDemandeur(demandeur) {
      console.log(demandeur);
      this.createOrUpdateDemandeur(demandeur).then((data) => {
        console.log(data);
        this.$toast.success("Opération réussie!", {
          position: "top-right",
          duration: 5000,
        });
      });
    },
  },
};
</script>

<style></style>

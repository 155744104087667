/* eslint-disable vue/no-unused-components */
<template>
  <div :class="{ 'container-fluid': !hideHeader || noPadding }">
    <div class="statistic-box" v-if="!hideStatistic">
      <de-statistic
        ref="deStatistic"
        :extraQueryArg="statisticsQueryParams"
        @selectionChange="statisticBlockSelected"
        :dateDeb="dateDeb"
        :dateFin="dateFin"
      />
    </div>
    <CDetailViewer
      v-if="activeDemandeur"
      title="Fichier/CV du DE"
      ref="detailViewer"
      :item="activeDemandeur"
    >
      <PTabView :activeIndex.sync="detailViewerActiveTab">
        <PTabPanel header="CV">
          <DemandeurMonCv :checkHasCv="false" :demandeurId="activeDemandeur.id" />
        </PTabPanel>
        <PTabPanel header="Fiche d'enquête">
          <FicheAzoli v-if="activeDemandeur.estPPI" :demandeurId="activeDemandeur.id" />
          <div v-else>Ce demandeur n'a pas de fiche d'enquête</div>
        </PTabPanel>
      </PTabView>
    </CDetailViewer>
    <DossierValidationDialog
      ref="dossierValidator"
      :demandeur="activeDemandeur"
      @save="validerFiche"
    >
    </DossierValidationDialog>
    <PriseEnChargeAutoDialog ref="autoPecDialog" @save="validerFiche">
    </PriseEnChargeAutoDialog>
    <div class="container- mt-1">
      <template v-if="!hideHeader">
        <p-progress-bar v-if="mxLoading" style="height: 0.33em" mode="indeterminate" />
        <div class="bg-white p-2 rounded-4">
          <form class="bg-" method="post" accept-charset="utf-8" action="">
            <div class="row py-0 p-fluid grid formgrid">
              <div class="col field">
                <form class="d-flex" @submit.prevent="submitQuery">
                  <div class="input-group me-4">
                    <div class="p-inputgroup">
                      <PInputText
                        type="text"
                        v-model="mxSearch"
                        :placeholder="'Rechercher par'"
                      />
                      <!-- <p-multi-select v-model="mxSearchFields" :options="searchFieldsOptions.filter(el => el.visible)" optionLabel="label" optionValue="value" :editable="true"/> -->
                    </div>
                  </div>
                </form>
              </div>
              <div class="col-md-6 col-sm-12 d-flex">
                <div class="input-group me-4">
                  <div class="p-inputgroup">
                    <p-dropdown
                      class="flex-grow- me-2"
                      v-model="sexeDe"
                      :options="sexeOptions"
                      optionLabel="label"
                      optionValue="value"
                      :placeholder="'Sexe DE'"
                      display="chip"
                    />
                    <p-dropdown
                      class="flex-grow-1 me-2"
                      v-model="demandeurVulnerabilite"
                      :options="demandeurVulnerabiliteOptions"
                      optionLabel="label"
                      optionValue="value"
                      :placeholder="'Vulnérabilité'"
                      display="chip"
                    />
                    <p-dropdown
                      class="flex-grow-1 me-2"
                      v-model="demandeurType"
                      :options="demandeurTypeOptions"
                      optionLabel="label"
                      optionValue="value"
                      :placeholder="'Type de DE'"
                      display="chip"
                    />
                    <p-dropdown
                      class="flex-grow-1 me-2"
                      v-model="statutPec"
                      :options="statutPecOptions"
                      optionLabel="label"
                      optionValue="value"
                      :placeholder="'Statut PEC'"
                      display="chip"
                    />
                    <p-dropdown
                      class="flex-grow-1 me-2"
                      v-model="statutPlacement"
                      :options="statutPlacementOptions"
                      optionLabel="label"
                      optionValue="value"
                      :placeholder="'Statut PLACE'"
                      display="chip"
                    />
                  </div>
                </div>
              </div>
              <div class="col-md-auto col-sm-12 d-flex">
                <div class="d-grid gap-2">
                  <a
                    href="#"
                    @click.prevent="submitQuery"
                    class="style_btn btn btn-primary"
                    id="button-query"
                    ><i class="bi bi-search"></i
                  ></a>
                </div>
              </div>
              <div class="col-auto">
                <button
                  type="button"
                  @click.prevent="showAdvancedFilter = !showAdvancedFilter"
                  class="style_btn btn btn-warning"
                  id="button-query"
                >
                  <i class="bi bi-filter"></i>
                </button>
              </div>
            </div>
          </form>
          <transition>
            <div class="card mx- border-radius-5 mt-2 hadow-5" v-if="showAdvancedFilter">
              <div class="row p-fluid p-3 grid">
                <div class="field col-12 col-md-3">
                  <label for="basic">Date de début</label>
                  <p-calendar
                    placeholder="Date de début"
                    v-model="dateDeb"
                    locale="fr"
                    :showIcon="true"
                    :manualInput="false"
                  />
                </div>
                <div class="field col-12 col-md-3">
                  <label for="basic">Date de fin</label>
                  <p-calendar
                    placeholder="Date de fin"
                    class="flex-grow-1"
                    v-model="dateFin"
                    :showIcon="true"
                    :manualInput="false"
                  />
                </div>
                <template v-for="(option, index) in filterOptions">
                  <div class="col-12 col-md-3 mb-4" v-if="option.visible" :key="index">
                    <label for="basic">{{ option.label }}</label>
                    <c-auto-complete
                      v-if="option.url"
                      display="chip"
                      v-model="option.value"
                      chips
                      :option="option"
                      :force-selection="true"
                    />
                    <p-multi-select
                      v-else-if="option.items && option.items.length != 0"
                      v-model="option.value"
                      :options="option.items"
                      :optionLabel="option.key"
                      :placeholder="option.label"
                      display="chip"
                    />
                    <p-input-text
                      v-else
                      v-model="option.value"
                      :placeholder="option.label"
                    />
                  </div>
                </template>
              </div>
            </div>
          </transition>
        </div>
        <template v-if="!hideTitle">
          <p-divider />
          <div class="d-flex flex-wrap mb-4 justify-content-between align-items-center">
            <div class="my-1 flex-fill">
              <h4>Liste des demandeurs</h4>
            </div>
            <div v-if="$can('create', 'Azoli')" class="btn-group">
              <a
                @click.prevent="$router.push({ name: 'espace.ce.de.add' })"
                href=""
                class="style_btn btn btn-warning"
                >Inscrire un PPI</a
              >
            </div>
            <div v-if="$can('AUTO_PEC', 'PRISE_EN_CHARGE')" class="btn-group">
              <a
                @click.prevent="showPriseEnChargeAutoDialog()"
                href=""
                class="style_btn btn btn-info"
                >Prise en charge automatique</a
              >
            </div>
            <div class="dropdown">
              <button
                v-if="$can('transfer', 'FinOc')"
                class="btn ms-4 style_btn btn-primary dropdown-toggle"
                type="button"
                id="dropdownMenuButton1"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                Affectation
              </button>
              <ul
                class="dropdown-menu dropdown-menu-end py-0"
                aria-labelledby="dropdownMenuButton1"
                style="margin: 0px"
              >
                <li v-if="$can('transferToAgent', 'FinOc')" class="border">
                  <conseiller-selector
                    size="lg"
                    title=""
                    @saved="transfertDemandeur"
                    ref="conseillerSelector"
                  >
                    <template #action="{ show }">
                      <a class="dropdown-item" @click="show" href="#"
                        >Affecter à des conseillers</a
                      >
                    </template>
                  </conseiller-selector>
                </li>
                <li v-if="$can('transferToAntenne', 'FinOc')" class="border">
                  <antenne-selector-dialog
                    size="lg"
                    title=""
                    @saved="transfertDemandeur"
                    ref="antenneSelector"
                  >
                    <template #action="{ show }">
                      <a class="dropdown-item" @click="show" href="#"
                        >Affecter vers une antenne</a
                      >
                    </template>
                  </antenne-selector-dialog>
                  <!-- <a class="dropdown-item" href="#">Affecter vers une antenne</a> -->
                </li>
              </ul>
            </div>
            <div v-if="$can('export', 'Azoli')" class="btn-group ms-4">
              <PSplitButton
                label="Exporter"
                :model="exportActions"
                class="p-button-outlined p-button-primary"
              />
            </div>
            <div class="btn-group ms-4">
              <button
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                v-tooltip.top="'Affichage mode avatar'"
                @click.prevent="viewMode = 'card'"
                class="btn style_btn btn-md"
                :class="{
                  'btn-success': viewMode == 'card',
                  'btn-light': viewMode == 'table',
                }"
              >
                <i class="bi-grid" />
              </button>
              <button
                @click.prevent="viewMode = 'table'"
                v-tooltip.top="'Affichage mode tableau'"
                class="btn style_btn"
                :class="{
                  'btn-light': viewMode == 'card',
                  'btn-success': viewMode == 'table',
                }"
              >
                <i class="bi-table" />
              </button>
            </div>
          </div>
        </template>

        <vue-html2pdf
          :show-layout="false"
          :float-layout="true"
          :enable-download="true"
          :preview-modal="true"
          :filename="`Fiche_azoli_${activeDemandeur.nom}_${activeDemandeur.prenom}.pdf`"
          :pdf-quality="4"
          :paginate-elements-by-height="800"
          :manual-pagination="true"
          pdf-format="a4"
          height="800px"
          @progress="onProgress($event)"
          @hasStartedGeneration="hasStartedGeneration()"
          @hasGenerated="hasGenerated($event)"
          ref="html2Pdf"
        >
          <section slot="pdf-content">
            <!-- PDF Content Here -->
            <!-- <demandeur-detail  :demandeur="activeDemandeur"/> -->
            <report-header>
              <template #title>
                <h4 class="text-uppercase px-2">Fiche enregistrement bénéficiaire</h4>
              </template>
            </report-header>
            <fiche-azoli-report :demandeur="activeDemandeur" />
          </section>
        </vue-html2pdf>
      </template>
      <template v-if="!mxLoading">
        <PDivide v-if="hideTitle" />
        <div class="bg-">
          <div class="row mb-4" v-if="viewMode == 'card'">
            <div
              class="col-sm-4 col-lg-3 mb-3"
              v-for="de in mxFilteredItems"
              :key="de.id"
            >
              <div class="style_profil_card p-3 pt-3">
                <div
                  @click.stop="showDemandeur(de)"
                  class="d-flex justify-content-center mb-3"
                >
                  <azoli-status-badge :de="de" :key="'_statut_c_' + de.id" />
                </div>
                <div class="mx-auto">
                  <a href="#">
                    <div
                      @click="uploadFile(de)"
                      class="rounded-circle style_bg_img border"
                      :style="`background-image: url(${
                        de.fullPhotoUrl != null
                          ? de.fullPhotoUrl
                          : '/assets/img/profilMan.jpg'
                      }); height: 100px; width: 100px;`"
                    ></div>
                  </a>
                </div>
                <div class="py-3 text-center border-bottom">
                  <div
                    class="d-flex no-wrap justify-content-center align-items-center text-muted"
                  >
                    <PBadge
                      :severity="de.estPlace ? 'success' : 'danger'"
                      :value="
                        (de.estPlace ? 'PLACE' : 'NON PLACE') +
                        ' | ' +
                        de.nombreMER +
                        ' MER'
                      "
                    />
                  </div>
                  <h6 class="my-1">
                    <a href="#" class="text-dark text-uppercase"
                      >{{ de.nom }} {{ de.prenom }}</a
                    >
                  </h6>

                  <small v-if="de.validePar" class="text-muted text-bold"
                    >Validé par: {{ de.validePar.nom }} {{ de.validePar.prenom }}
                    <span class="text-uppercase"
                      >, le {{ de.validationDate | formatDate }}</span
                    ></small
                  >
                  <small v-else-if="de.creePar" class="text-muted"
                    >Réalisé par: {{ de.creePar.nom }} {{ de.creePar.prenom }}
                    <span class="text-uppercase"
                      >, le {{ de.lastLogin | formatDate }}</span
                    ></small
                  >
                  <small v-else class="text-muted"
                    >Réalisé par: <span class="text-bold">LUI-MEME</span>
                    <span class="text-uppercase"
                      >, le {{ de.lastLogin | formatDate }}</span
                    ></small
                  >
                </div>
                <div class="d-flex justify-content-center mt-2">
                  <!-- <a class="btn btn-success rounded-0 btn-sm me-1" ><i class="bi bi-pencil-square"></i></a> -->
                  <a
                    class="btn btn-info rounded-0 btn-sm me-1"
                    data-bs-toggle="tooltip"
                    data-bs-placement="bottom"
                    title="Consulter et modifier"
                    @click.stop="showDemandeur(de)"
                    ><i class="bi bi-info-square"></i
                  ></a>
                  <a
                    class="btn btn-danger rounded-0 btn-sm me-1"
                    title="Générer PDF"
                    @click.stop.prevent="downloadFiche(de)"
                    ><i class="bi bi-printer"></i
                  ></a>
                  <a
                    @click.stop.prevent="remplirFiche(de)"
                    title="Saisir la fiche"
                    class="btn btn-warning rounded-0 btn-sm me-1"
                    ><i class="bi bi-file-earmark-check"></i
                  ></a>
                  <button
                    :title="'Valider la fiche/cv'"
                    @click.prevent="showDossierValidationDialog(de)"
                    class="btn btn-light btn-sm me-1"
                  >
                    <i
                      :class="{ 'text-danger': de.status == 'VALIDE' }"
                      class="bi bi-clipboard-check"
                    ></i>
                  </button>
                  <button
                    title="Prise en charge"
                    @click.prevent="priseEnCharge(de)"
                    class="btn btn-primary btn-sm me-1"
                  >
                    <i class="bi bi-file-earmark-medical"></i>
                  </button>
                </div>
              </div>
            </div>
            <div class="col-sm-12">
              <div
                class="d-flex flex-wrap justify-content-center align-items-center mt-3"
              >
                <b-pagination
                  v-model="mxPagination.page"
                  :total-rows="mxTotalCount"
                  :per-page="mxPagination.rowsPerPage"
                  aria-controls="my-table"
                ></b-pagination>
              </div>
            </div>
          </div>
          <div v-else class="bg-white p-3 border">
            <div class="table-responsive">
              <b-table
                show-empty
                empty-text="Aucune donnée à afficher"
                :fields="tableFields"
                class="align-middle"
                striped
                select-mode="single"
                ref="table"
                selectable
                @row-selected="onRowSelected"
                :sort-by.sync="mxPagination.sortBy"
                :sort-desc.sync="mxPagination.sortDesc"
                responsive="sm"
                sort-icon-left
                :items="tableData"
              >
                <template #head()="data">
                  <span>{{ data.label }} </span>
                </template>
                <template #head(#)="data">
                  <div class="form-check form-check-inline">
                    <input
                      @change="selectAll"
                      class="form-check-input"
                      type="checkbox"
                      :id="`${data.index}`"
                      name="disponible"
                      :value="false"
                    />
                  </div>
                </template>
                <!-- A virtual column -->
                <template #cell(index)="data">
                  {{ data.index + 1 }}
                </template>

                <template #cell(#)="data">
                  <div class="form-check form-check-inline">
                    <input
                      @input="selectionChanged($event, data)"
                      :checked="data.rowSelected"
                      class="form-check-input"
                      type="checkbox"
                      :id="`${data.index}`"
                      name="disponible"
                    />
                  </div>
                </template>

                <template #cell(status)="{ item: de }">
                  <div class="d-flex justify- mb-3">
                    <azoli-status-badge :de="de" />
                  </div>
                </template>
                <template #cell(createdDate)="{ item: de }">
                  <div
                    v-if="!perideMode"
                    v-tooltip.top="$dayjs(de.createdDate).format('DD/MM/YYYY à HH:mm')"
                    class="d-flex justify- mb-3"
                  >
                    {{ $dayjs(de.createdDate).format("DD/MM/YYYY") }}
                  </div>
                  <div
                    v-else
                    v-tooltip.top="$dayjs(de.createdDate).format('DD/MM/YYYY à HH:mm')"
                    class="d-flex justify- mb-3"
                    :class="{
                      'text-danger fw-bold':
                        $dayjs().diff($dayjs(de.createdDate), 'days', true) >= 7 &&
                        !de.estSuivi,
                    }"
                  >
                    {{ $dayjs(de.createdDate).fromNow() }}
                  </div>
                </template>
                <template #cell(tauxRemplissageProfil)="{ item: de }">
                  {{ de.tauxRemplissageProfil }}%
                </template>
                <template #cell(estSuivi)="{ item }">
                  <div class="position-relative">
                    <PTag
                      v-tooltip.left="
                        item.estSuiviPar != null
                          ? 'Suivi par ' + item.estSuiviPar
                          : 'Pas encore suivi'
                      "
                      rounded
                      :severity="
                        item.estSuivi
                          ? item.estAutoAffecte
                            ? 'info'
                            : 'primary'
                          : 'danger'
                      "
                      size="large"
                      >{{
                        item.estSuivi
                          ? item.estAutoAffecte
                            ? "Auto.Affecté"
                            : "Affecté"
                          : "Non.Affecté"
                      }}</PTag
                    >
                  </div>
                </template>
                <template #cell(estPlace)="{ item: de }">
                  <!-- <span class="text-uppercase" v-tooltip.top="`NPI validé ${de.validePar != null? de.validePar.nomPrenom : ''}` "  v-badge.primary="de.nombreMER">{{ de.estPlace? 'PLACE' : 'NON PLACE' }}</span> -->
                  <PBadge
                    :severity="de.estPlace ? 'success' : 'danger'"
                    :value="(de.estPlace ? 'OUI' : 'NON') + '/' + de.nombreMER"
                  />
                </template>
                <template #cell(npi)="{ item: de }">
                  <div
                    @mouseenter="de.displayDemandeurId = true"
                    @mouseleave="de.displayDemandeurId = false"
                  >
                    <span v-if="de.npiValide == null">{{ de.npi }}</span>
                    <span
                      v-else
                      class="text-uppercase"
                      v-tooltip.top="
                        `NPI validé ${de.validePar != null ? de.validePar.nomPrenom : ''}`
                      "
                      style="font-size: 1.2rem"
                      v-badge.primary="de.npiValide ? 'Ok' : ''"
                      >{{ de.npi }}</span
                    >
                  </div>

                  <span v-if="de.displayDemandeurId" class="text-muted">{{ de.id }}</span>
                </template>
                <template #cell(prenom)="{ item: de }">
                  <span v-if="de.statutDoublon == null" class="text-uppercase">{{
                    de.prenom
                  }}</span>
                  <span
                    v-else
                    class="text-uppercase"
                    v-tooltip.top="
                      `A fait objet d'inscription multiple. Mais seul celle-ci a été acceptée.`
                    "
                    style="font-size: 1.2rem"
                    v-badge.warning="de.statutDoublon ? 'A' : ''"
                    >{{ de.prenom }}</span
                  >
                </template>
                <template #cell(nom)="{ item: de }">
                  <span class="text-uppercase">{{ de.nom }}</span>
                </template>
                <template #cell(phone)="{ item: de }">
                  <div
                    @mouseenter="de.displayDemandeurPersonneContact = true"
                    @mouseleave="de.displayDemandeurPersonneContact = false"
                    v-tooltip.top="'Cliquez pour afficher la personne contact'"
                  >
                    <span
                      v-if="de.personneContact"
                      class="text-uppercase"
                      v-badge.danger
                      >{{ de.phone }}</span
                    >
                    <span v-else class="text-uppercase">{{ de.phone }}</span>
                    <br />
                  </div>
                  <span v-if="de.displayDemandeurPersonneContact" class="text-muted">{{
                    de.personneContact || "Pas de personne de contact"
                  }}</span>
                </template>
                <template #cell(creePar)="{ item: de }">
                  <span class="text-uppercase" v-if="de.creePar"
                    >{{ de.creePar.nom }} {{ de.creePar.prenom }}</span
                  >
                </template>
                <template #cell(validePar)="{ item: de }">
                  <span class="text-uppercase" v-if="de.validePar"
                    >{{ de.validePar.nom }} {{ de.validePar.prenom }}</span
                  >
                </template>

                <template #row-details="row">
                  <div class="bg- pa-2">
                    <PTabView :activeIndex.sync="activeTab">
                      <PTabPanel header="Prise En Charge">
                        <PriseEnCharge v-if="activeTab == 0" :demandeurId="row.item.id" />
                      </PTabPanel>
                      <PTabPanel header="CV">
                        <DemandeurMonCv
                          v-if="activeTab == 1"
                          :checkHasCv="false"
                          :demandeurId="row.item.id"
                        />
                      </PTabPanel>
                      <PTabPanel header="Fiche d'enquête">
                        <FicheAzoli
                          v-if="row.item.estPPI && activeTab == 2"
                          :demandeurId="row.item.id"
                        />
                        <div v-else>Ce demandeur n'a pas de fiche d'enquête</div>
                      </PTabPanel>
                      <PTabPanel header="Tâches plannifiées">
                        <ActiviteAnpeList
                          v-if="activeTab == 3"
                          :extraQueryArg="`participantId=${row.item.id}`"
                        />
                      </PTabPanel>
                      <PTabPanel header="Liste programmes">
                        <ParticipationProgrammeList
                          v-if="activeTab == 4"
                          :extraQueryArg="`participantId=${row.item.id}`"
                        />
                      </PTabPanel>
                      <PTabPanel header="Consignes">
                        <ConsigneList
                          v-if="activeTab == 5"
                          :extraQueryArg="`participantId=${row.item.id}`"
                        />
                      </PTabPanel>
                      <PTabPanel header="Prescriptions">
                        <PrescriptionList
                          v-if="activeTab == 6"
                          :extraQueryArg="`participantId=${row.item.id}`"
                        />
                      </PTabPanel>
                      <PTabPanel header="Placements">
                        <ParticipationPlacementList
                          v-if="activeTab == 7"
                          :extraQueryArg="`candidature.candidat.id=${row.item.id}`"
                        />
                      </PTabPanel>
                      <PTabPanel header="Candiatures">
                        <CandidatureList
                          v-if="activeTab == 8"
                          :extraQueryArg="`candidatId=${row.item.id}`"
                        />
                      </PTabPanel>
                      <PTabPanel header="MicroProjets">
                        <MicroprojetList
                          v-if="activeTab == 9"
                          :extraQueryArg="`beneficiaireId=${row.item.id}`"
                        />
                      </PTabPanel>
                      <PTabPanel header="Affectations">
                        <TransfertDossierList
                          :extraQueryArg="`dossier.id=${row.item.id}`"
                          v-if="activeTab == 10"
                        />
                      </PTabPanel>
                      <PTabPanel header="Parcours">
                        <ParcoursDemandeurList
                          :extraQueryArg="`demandeurId=${row.item.id}`"
                          v-if="activeTab == 11"
                        />
                      </PTabPanel>
                      <!-- <PTabPanel header="Payements"> </PTabPanel>
                      <PTabPanel header="Statistiques"> Content III </PTabPanel> -->
                    </PTabView>
                  </div>
                </template>

                <template #cell(actions)="row">
                  <div class="d-flex justify-content-end">
                    <button
                      v-tooltip.top="'Voir les détails'"
                      class="btn btn-sm py-1 btn-outline-primary me-1"
                      @click="mxToggleDetails(row, tableData)"
                    >
                      <i class="bi bi-journal-check"></i>
                    </button>
                    <!-- <a class="btn btn-success rounded-0 btn-sm me-1" title><i class="bi bi-pencil-square"></i></a> -->
                    <!-- <a class="btn btn-info rounded-0 btn-sm me-1" title="Consulter et modifier"   @click="row.toggleDetails" ><i class="bi bi-info-square"></i></a> -->
                    <!-- <a class="btn btn-danger rounded-0 btn-sm me-1"><i class="bi bi-trash"></i></a> -->
                    <a
                      class="btn btn-danger rounded-0 btn-sm me-1"
                      title="Générer PDF"
                      @click.stop.prevent="downloadFiche(row.item)"
                      ><i class="bi bi-printer"></i
                    ></a>
                    <a
                      @click.stop.prevent="remplirFiche(row.item)"
                      title="Saisir la fiche"
                      target="_blank"
                      class="btn btn-warning rounded-0 btn-sm me-1"
                      ><i class="bi bi-file-earmark-check"></i
                    ></a>

                    <!-- <a @click.stop.prevent="transfertSingleDemandeur(row.item)" title="Affecter" class="btn btn-warning rounded-0 btn-sm me-1"><i class="bi bi-box-arrow-up-right"></i></a> -->
                    <button
                      title="Valider la fiche/cv"
                      @click.prevent="showDossierValidationDialog(row.item)"
                      class="btn btn-light btn-sm"
                    >
                      <i
                        :class="{ 'text-danger': row.item.status == 'VALIDE' }"
                        class="bi bi-clipboard-check"
                      ></i>
                    </button>
                    <a
                      href="#"
                      title="Prise en charge"
                      @click.prevent="priseEnCharge(row.item)"
                      class="btn btn-primary btn-sm me-1"
                      target="_blank"
                    >
                      <i class="bi bi-file-earmark-medical"></i>
                    </a>
                  </div>
                </template>
                <!-- Optional default data cell scoped slot -->
                <template #cell()="data">
                  <span>{{ data.value }}</span>
                </template>
              </b-table>
            </div>
            <div class="d-flex flex-wrap justify-content-between align-items-center">
              <p>
                Affichage de l’élément
                {{
                  (mxPagination.page <= 0 ? 0 : mxPagination.page - 1) *
                  mxPagination.rowsPerPage
                }}
                à
                {{
                  (mxPagination.page <= 0 ? 1 : mxPagination.page) *
                  mxPagination.rowsPerPage
                }}
                de {{ mxTotalCount }} éléments
              </p>
              <b-pagination
                v-model="mxPagination.page"
                :total-rows="mxTotalCount"
                :per-page="mxPagination.rowsPerPage"
                aria-controls="my-table"
              ></b-pagination>
            </div>
          </div>
        </div>
      </template>
      <template v-else>
        <div class="row mb-4" v-if="viewMode == 'card'">
          <div
            class="col-sm-4 col-lg-3 mb-3"
            v-for="i in mxPagination.rowsPerPage"
            :key="i"
          >
            <div class="style_profil_card p-3 pt-3">
              <div class="custom-skeleton p-3">
                <div
                  class="d-flex flex-column mb-2 align-items-center justify-content-center"
                >
                  <PSkeleton width="10rem" class="mb-2"></PSkeleton>
                  <PSkeleton shape="circle" size="6rem" class="mr-2 mt-2"></PSkeleton>
                  <div class="mt-4">
                    <PSkeleton width="12rem" class="mb-2"></PSkeleton>
                    <PSkeleton height=".5rem"></PSkeleton>
                  </div>
                </div>
                <div class="d-flex justify-content-between mt-3">
                  <PSkeleton width="2.5rem" height="2rem"></PSkeleton>
                  <PSkeleton width="2.5rem" height="2rem"></PSkeleton>
                  <PSkeleton width="2.5rem" height="2rem"></PSkeleton>
                  <PSkeleton width="2.5rem" height="2rem"></PSkeleton>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-else>
          <PDataTable
            :value="
              [1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((e) => {
                e;
              })
            "
          >
            <PColumn
              v-for="f in tableFields"
              :key="f.key"
              :field="f.key"
              :header="f.label"
            >
              <template #body>
                <PSkeleton></PSkeleton>
              </template>
            </PColumn>
          </PDataTable>
        </div>
      </template>
      <div class="mb-4 bg-light" v-if="!mxLoading && mxFilteredItems.length == 0">
        <div
          style="min-height: 200px"
          class="no-content pa-4 d-flex flex-column justify-content-center align-items-center"
        >
          <span class="text-muted mb-3">Votre porte-feuille est vide</span>
          <a
            v-if="$can('create', 'Azoli')"
            @click.prevent="$router.push({ name: 'espace.ce.de.add' })"
            href=""
            class="style_btn btn btn-sm btn-outline-primary"
            >Ajouter un PPI</a
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { paginatorMixin } from "@/mixins/paginator-mixin";
import DeStatistic from "../../../components/espace/demandeur/statistic/DeStatistic.vue";
import FicheAzoliReport from "../../../components/report/FicheAzoliReport.vue";
import ReportHeader from "../../../components/report/ReportHeader.vue";
import AzoliStatusBadge from "../../../components/common/AzoliStatusBadge.vue";
import CAutoComplete from "../../../components/common/CAutoComplete.vue";
import CDetailViewer from "../../../components/common/CDetailViewer.vue";

import ConseillerSelector from "../../../components/espace/antenne/transfert/ConseillerSelector.vue";
import AntenneSelectorDialog from "../../../components/espace/antenne/transfert/AntenneSelectorDialog.vue";
import ActiviteAnpeList from "./ActiviteAnpeList.vue";
import ConsigneList from "./ConsigneList.vue";
import PrescriptionList from "./PrescriptionList.vue";
import MicroprojetList from "./MicroprojetList.vue";
import TransfertDossierList from "./TransfertDossierList.vue";
import ParcoursDemandeurList from "./ParcoursDemandeurList.vue";
import ParticipationProgrammeList from "./ParticipationProgrammeList.vue";
import PriseEnCharge from "./PriseEnCharge.vue";
import DemandeurMonCv from "../demandeur/DemandeurMonCv.vue";
import FicheAzoli from "../conseiller/DemandeurDetailAzoli.vue";
import ActiviteDetail from "./activite/ActiviteDetail";

import ParticipationPlacementList from "./ParticipationPlacementList.vue";
import CandidatureList from "../entreprise/CandidatureList.vue";

import { FILE_UPLOAD_API_URL } from "../../../config/api-config";
import DossierValidationDialog from "../../../components/espace/demandeur/DemandeurDossierValidationDialog.vue";
import PriseEnChargeAutoDialog from "../../../components/espace/common/prise-en-charge/PriseEnChargeAutoDialog.vue";
export default {
  props: {
    agentId: {
      type: Number,
      default: () => null,
    },
    extraQueryArg: {
      type: String,
      default: "",
    },
    hideStatistic: {
      type: Boolean,
      default: false,
    },
    hideHeader: {
      type: Boolean,
      default: false,
    },
    noPadding: {
      type: Boolean,
      default: false,
    },
    hideTitle: {
      type: Boolean,
      default: false,
    },
    perideMode: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    DeStatistic,
    FicheAzoliReport,
    FicheAzoli,
    TransfertDossierList,
    ParcoursDemandeurList,
    ReportHeader,
    ConseillerSelector,
    DemandeurMonCv,
    AntenneSelectorDialog,
    ActiviteAnpeList,
    ConsigneList,
    CDetailViewer,
    PrescriptionList,
    ParticipationProgrammeList,
    AzoliStatusBadge,
    PriseEnCharge,
    CAutoComplete,
    ParticipationPlacementList,
    CandidatureList,
    MicroprojetList,
    DossierValidationDialog,
    PriseEnChargeAutoDialog,
    ActiviteDetail,
  },
  mixins: [paginatorMixin],
  data() {
    return {
      selected: [],
      search: "",
      dateDeb: null,
      dateFin: null,
      activeTab: 0,
      detailViewerActiveTab: 0,
      showAdvancedFilter: false,
      femmesAvecEnfantsCount: 0,
      nombreEnfants3Mois3AnsCount: 0,
      femmeEnceinteCount: 0,
      viewMode: localStorage.getItem("viewMode") || "card", //card, table
      parDepartementEnregistres: [],
      parDepartementFemmesEtEnfantsEntre3MoisEt3Ans: [],
      parCommuneEnregistres: [],
      parAeEnregistres: [],
      demandeurTypeOptions: [
        { value: null, label: "Tous" },
        { value: "PPI", label: "PPI" },
        { value: "CI", label: "CI" },
        { value: "INSTRUIT", label: "INSTRUIT" },
      ],
      demandeurType: null,
      statutPecOptions: [
        { value: null, label: "Tous" },
        { value: true, label: "PEC" },
        { value: false, label: "NPEC" },
      ],
      statutPec: null,
      statutPlacementOptions: [
        { value: null, label: "Tous" },
        { value: true, label: "PLACE" },
        { value: false, label: "NON PLACE" },
      ],
      statutPlacement: null,
      sexeOptions: [
        { value: null, label: "Tous" },
        { value: "M", label: "Masculin" },
        { value: "F", label: "Féminin" },
      ],
      sexeDe: null,
      demandeurVulnerabiliteOptions: [
        { value: null, label: "Tous" },
        { value: "VULNERABLE", label: "VULNERABLE" },
        { value: "NON_VULNERABLE", label: "NON VULNERABLE" },
      ],
      demandeurVulnerabilite: null,
      exportActions: [
        {
          label: "PDF",
          icon: "pi pi-file-pdf",
          command: () => {
            this.mxExportToPDF({
              meta: {
                title: "Liste des demandeurs d'emploi",
                subtitle: "Liste des demandeurs d'emploi",
              },
              pathParams: {
                name: "common.export",
                query: {
                  apiPath: "demandeurs/exportToPdf",
                  queryParams: this.mxGetQueryBuilder(),
                  fileName: "Liste des bénéficiaires Azoli.xlsx",
                },
              },
            });
          },
        },
        {
          label: "Excel",
          icon: "pi pi-file-excel",
          command: () => {
            let params = this.mxGetQueryBuilder();
            let route = this.$router.resolve({
              name: "common.export",
              query: {
                fetcherMethod: "demandeurs/exportToExcel",
                queryParams: params,
                fileName: "Liste des bénéficiaires Azoli.xlsx",
              },
            });
            window.open(route.href, "_blank");
          },
        },
      ],
      searchFields: ["nomPrenom"],
      searchFieldsOptions: [
        // {
        //   label: "Bénéficiaire",
        //   value: "nomPrenom",
        //   visible: this.$can("filter.beneficiaire", "Azoli"),
        // },
        {
          label: "Téléphone",
          value: "phone",
          visible: true,
        },
        {
          label: "NPI",
          value: "npi",
          visible: true,
        },
        {
          label: "Whatsapp",
          value: "whatsapp",
          visible: this.$can("filter.whatsapp", "Azoli"),
        },
        {
          label: "Commune",
          value: "commune.nom",
          visible: true,
        },
        {
          label: "Département",
          value: "departement.nom",
          visible: true,
        },
        // {
        //   label: "AE (Nom & prénom)",
        //   value: "ae",
        //   visible: this.$can("filter.ae", "Azoli"),
        // },
        {
          label: "Arrondissement",
          value: "arrondissement.nom",
          visible: true,
        },
        {
          label: "Village/Quartier de ville",
          value: "ville.nom",
          visible: true,
        },
        {
          label: "Métier Appris",
          value: "metierAppris",
          visible: true,
        },
        {
          label: "Métier Désiré",
          value: "metierDesire",
          visible: true,
        },
      ],
      filterOptions: [
        {
          column: "phone",
          value: "",
          label: "Téléphone",
          dropdown: false,
          visible: true,
        },
        {
          column: "npi",
          value: "",
          label: "NPI",
          dropdown: false,
          visible: true,
        },
        {
          column: "creePar",
          value: "",
          label: "AE (Nom & prénom)",
          dropdown: false,
          visible: true,
        },
        {
          column: "departement.nom",
          key: "nom",
          valueKey: "nom",
          value: "",
          label: "Département",
          dropdown: true,
          url: "/departements",
          visible: true,
        },
        {
          column: "commune.nom",
          key: "nom",
          valueKey: "nom",
          value: "",
          label: "Commune",
          dropdown: true,
          url: "/communes",
          visible: true,
        },
        {
          column: "arrondissement.nom",
          key: "nom",
          valueKey: "nom",
          value: "",
          dropdown: true,
          url: "/arrondissements",
          label: "Arrondissement",
          visible: true,
        },
        {
          column: "ville.nom",
          key: "nom",
          valueKey: "nom",
          value: "",
          dropdown: true,
          url: "/villes",
          label: "Village/Quartier de ville",
          visible: true,
        },
        {
          column: "status",
          key: "label",
          value: "",
          label: "Statut du dossier",
          multiple: true,
          valueKey: "value",
          items: [
            { value: "NOUVEAU", label: "Sans dossier" },
            { value: "EN_COURS", label: "En cours" },
            { value: "TERMINE", label: "Terminé" },
            { value: "VALIDE", label: "Validé" },
          ],
          visible: true,
        },
        {
          column: "metierAppris",
          key: "libelle",
          valueKey: "libelle",
          value: "",
          label: "Metier appris",
          dropdown: true,
          url: "/romes",
          visible: true,
        },
        {
          column: "metierDesire",
          key: "libelle",
          valueKey: "libelle",
          value: "",
          label: "Metier recherché",
          dropdown: true,
          url: "/romes",
          visible: this.$can("filter.metierDesire", "Azoli"),
        },
        {
          column: "azoli.typeEmploi",
          key: "label",
          valueKey: "value",
          value: "",
          label: "Type D'emploi recherché",
          dropdown: true,
          // url: "/romes",
          items: [
            { value: "SALARIE", label: "SALARIE" },
            { value: "AUTO_EMPLOI", label: "AUTO_EMPLOI" },
            { value: "INDIFERENT", label: "INDIFERENT" },
          ],
          visible: true,
        },
        {
          column: "antenne.id",
          key: "intitule",
          valueKey: "id",
          value: "",
          label: "Antenne",
          dropdown: true,
          url: "/antennes",
          visible: true,
        },
        {
          column: "estSuiviPar",
          key: "nomPrenom",
          valueKey: "nomPrenom",
          value: "",
          label: "Suivi par agent",
          dropdown: true,
          url: "/agents",
          visible: true,
        },
        {
          column: "creePar.id",
          key: "nomPrenom",
          valueKey: "id",
          value: "",
          label: "Inscrit par agent",
          dropdown: true,
          url: "/agents",
          visible: true,
        },
        {
          column: "validePar.id",
          key: "nomPrenom",
          valueKey: "id",
          value: "",
          label: "Validé par agent",
          dropdown: true,
          url: "/agents",
          visible: true,
        },
        {
          column: "affectabilite",
          key: "value",
          valueKey: "label",
          value: "",
          label: "Affectabilité du DE",
          dropdown: true,
          items: [
            { value: "AFFECTABLE", label: "AFFECTABLE" },
            { value: "NON_AFFECTABLE", label: "NON AFFECTABLE" },
          ],
          visible: true,
        },
        {
          column: "statutAffectation",
          key: "label",
          valueKey: "value",
          value: "",
          label: "Statut d'affectation du DE",
          dropdown: true,
          items: [
            { value: "ACTIF", label: "AFFECTE" },
            { value: "AUTO_ACTIF", label: "AUTO AFFECTE" },
            { value: "NON_AFFECTE", label: "NON AFFECTE" },
          ],
          visible: true,
        },
      ],
      activeDemandeur: {},
    };
  },
  created() {
    this.queryStatistics();
  },
  watch: {
    dossier(val) {
      if (val.azoli) {
        this.activeDemandeur = {
          ...val,
          ...val.azoli, // écrase id du demandeur par id azoli
          id: val.id, //remettre Id du demandeur
        };
      }
    },
    viewMode() {
      localStorage.setItem("viewMode", this.viewMode);
    },
    extraQueryArg() {
      this.queryStatistics();
    },
    // dateDeb(){
    //   this.queryStatistics()
    // },
    // dateFin(){
    //   this.queryStatistics()
    // }
  },
  computed: {
    ...mapGetters({
      dossier: "demandeur/demandeur",
      statutDossiers: "setting/statutDossiers",
      // demandeurs: 'demandeur/demandeurs',
      pagination: "demandeur/pagination",
    }),
    statisticsQueryParams() {
      return this.mxBuildQueryParams();
    },
    tableFields() {
      let fields = [
        "#",
        // 'index',
        { key: "npi", label: "NPI" },
        { key: "nom", label: "Nom" },
        { key: "prenom", label: "Prénom" },
        { key: "sexe", label: "Sexe" },
        { key: "phone", label: "Téléphone" },
        { key: "departement.nom", label: "Département" },
        { key: "commune.nom", label: "Commune" },
      ];

      if (this.$can("seeAffectationStatus", "Azoli")) {
        fields.push({ key: "estSuivi", label: "Affectation" });
      }

      //   if (this.$can("AFFICHER_DEMANDEUR_ID", "FIN_OC")) {
      //     fields.push({ key: "id", label: "ID" });
      //   }

      fields = fields.concat([
        { key: "tauxRemplissageProfil", label: "Taux" },
        { key: "status", label: "Statut" },
        { key: "estPlace", label: "Place" },
        { key: "createdDate", label: "Inscrit le" },
        // { key: 'validePar', label: 'Validé par' },
        "actions",
      ]);

      return fields;
    },
    parDepartementEnregistresTableFields() {
      return [
        // 'index',
        { key: "departement", label: "Département" },
        { key: "M", label: "Hommes" },
        { key: "F", label: "Femmes" },
        { key: "total", label: "Total" },
      ];
    },
    parCommuneEnregistresTableFields() {
      return [
        // 'index',
        { key: "commune", label: "Commune" },
        { key: "M", label: "Hommes" },
        { key: "F", label: "Femmes" },
        { key: "total", label: "Total" },
      ];
    },
    parAeEnregistresTableFields() {
      return [
        // 'index',
        { key: "ae", label: "AE" },
        { key: "M", label: "Hommes" },
        { key: "F", label: "Femmes" },
        { key: "total", label: "Total" },
      ];
    },
    parDepartementFemmesEtEnfantsEntre3MoisEt3AnsTableFields() {
      return [
        // 'index',
        { key: "departement", label: "Département" },
        { key: "femmesCount", label: "Nombre femmes" },
        { key: "enfantsCount", label: "Nombre enfants" },
        // { key: 'total', label: 'Total' },
      ];
    },
    parDepartementEnregistresTableData() {
      return this.parDepartementEnregistres;
    },
    parCommuneEnregistresTableData() {
      return this.parCommuneEnregistres;
    },
    parAeEnregistresTableData() {
      return this.parAeEnregistres;
    },
    parDepartementFemmesEtEnfantsEntre3MoisEt3AnsTableData() {
      return this.parDepartementFemmesEtEnfantsEntre3MoisEt3Ans;
    },
    tableData() {
      return this.mxFilteredItems.map((item) => {
        return { ...item };
      });
    },
    fileApiUrl() {
      return FILE_UPLOAD_API_URL;
    },
  },
  methods: {
    ...mapActions({
      fetchAzoliStatistics: "statistic/fetchAzoliStatistics",
      fetchDemandeurs: "demandeur/fetchDemandeurs",
      createOrUpdateDemandeur: "demandeur/createOrUpdateDemandeur",
      deleteDemandeur: "demandeur/deleteDemandeur",
      downloadAzoli: "demandeur/downloadAzoli",
      fetchDossier: "demandeur/fetchDossier",
      validerDossier: "demandeur/validerDossier",
      transfererVersConseiller: "transfert/transfererVersConseiller",
    }),
    priseEnCharge(de) {
      this.$router.push({
        name: "espace.common.de.followup",
        params: { userRole: this.$route.params.userRole, demandeurId: de.id },
      });
    },
    transfertDemandeur(agent) {
      console.log("Agent: ", agent);
      if (this.selected.length == 0) {
        this.$toast.error(
          "Aucun demandeur sélectionné. Passez en mode tableau puis sélectionnez le(s) demandeur(s) avant de continuer. ",
          {
            position: "top-right",
            duration: 8000,
          }
        );
        return;
      }
      this.transfererVersConseiller({
        agent,
        demandeur: this.selected[0],
      }).then((data) => {
        console.log(data);
        this.$toast.success("Opération réussie!", {
          position: "top-right",
          duration: 8000,
        });
      });
    },
    statisticBlockSelected(status) {
      this.mxSetOptionValue("status", status, true);
    },
    submitQuery() {
      this.mxSubmitQuery();
      this.$refs.deStatistic.reload();
    },
    queryStatistics() {
      this.mxInitializePaginator({
        queryModel: "demandeur",
        search: this.search,
        fetcherMethod: "demandeur/fetchDemandeurs",
        dataGetter: "demandeur/demandeurs",
        paginationGetter: "demandeur/pagination",
        autoSubmitQuery: false,
        pagination: {
          sortBy: "id",
          descending: false,
          page: 0,
          rowsPerPage: 8,
          sortingParam: `sort=id,desc`,
        },
        filterOptions: this.filterOptions,
        extraQueryArgsParamBuilder: () => {
          const dateDeb = (this.dateDeb || new Date("2021-12-31"))
            .toISOString()
            .substring(0, 10);
          const dateFin = (this.dateFin || new Date("2080-12-31"))
            .toISOString()
            .substring(0, 10);

          let paramPlus = this.mxAddQueryParam("categorieDemandeur", this.demandeurType);
          paramPlus = this.mxAddQueryParam(
            "vulnerabilite",
            this.demandeurVulnerabilite,
            paramPlus
          );
          paramPlus = this.mxAddQueryParam("sexe", this.sexeDe, paramPlus);
          paramPlus = this.mxAddQueryParam("estPriseEnCharge", this.statutPec, paramPlus);
          paramPlus = this.mxAddQueryParam("estPlace", this.statutPlacement, paramPlus);

          let params = `createdDate=${dateDeb}&createdDate=${dateFin}&${this.extraQueryArg}&${paramPlus}`;
          return params;
        },
        searchFields: this.searchFields,
      });
    },
    formatToDataTable(data, { keyName }) {
      let summary = {};
      return Object.keys(data).map((k) => {
        let { key, M = 0, F = 0, total = 0 } = data[k];
        summary.M += M;
        summary.F += F;
        summary.total += total;
        if (data[k]["TOTAL"] == undefined) {
          return {
            [keyName]: key,
            M,
            F,
            total,
          };
        } else {
          return {
            [keyName]: "TOTAL",
            ...summary,
          };
        }
      });
    },
    selectionChanged(evt, data) {
      console.log(evt.target.checked, data);

      if (evt.target.checked) {
        this.$refs.table.selectRow(data.index);
      } else {
        this.$refs.table.unselectRow(data.index);
      }
    },
    onRowSelected(items) {
      this.selected = items;
    },
    selectAll(evt) {
      if (evt.target.checked) {
        this.$refs.table.selectAllRows();
      } else {
        this.$refs.table.clearSelected();
      }
    },
    uploadFile(de) {
      console.log(de);
      this.$refs[`pond2`].click();
    },
    onProgress(e) {
      console.log(e);
    },
    downloadFiche(de) {
      // FileService.downloadFile(`demandeurs${de.id}/pdf`, `fichier_${de.id}.pdf`).then(()=>{})
      // this.downloadAzoli(de).then(()=>{})
      this.fetchDossier({
        demandeurId: de.id,
      }).then(() => {
        console.log(this.$refs.html2Pdf);
        this.$refs.html2Pdf.generatePdf();
      });
    },
    editDemandeur(demandeur) {
      console.log(demandeur);
      this.activeDemandeur = { ...demandeur };
      this.$router.push({ name: "espace.ce.de.edit" });
    },
    showDemandeur(demandeur) {
      this.activeDemandeur = { ...demandeur };
      this.$refs.detailViewer.show();
    },
    showPriseEnChargeAutoDialog() {
      this.$refs.autoPecDialog.show();
    },
    remplirFiche(demandeur) {
      if (!demandeur.estPPI) {
        this.$toast.info(
          "Vous ne pouvez pas remplir de fiche pour ce demandeur INSTRUIT.",
          {
            position: "top-right",
            duration: 8000,
          }
        );
      } else {
        this.$router.push({
          name: "espace.ce.azoli.edit",
          params: { demandeurId: demandeur.id },
        });
      }
    },
    async showDossierValidationDialog(demandeur) {
      this.activeDemandeur = { ...demandeur };
      this.$refs.dossierValidator.show();
    },
    async validerFiche(demandeur) {
      if (demandeur.status == "VALIDE") {
        this.$toast.info(demandeur.estPPI ? "Fiche déjà validée!" : "CV déjà validé", {
          position: "top-right",
          duration: 8000,
        });
        return;
      }

      if (demandeur.status != "TERMINE") {
        this.$toast.error(demandeur.estPPI ? "Fiche non terminée" : "CV non terminé", {
          position: "top-right",
          duration: 8000,
        });
        return;
      }

      await this.$confirm.require({
        group: "confirmDialog",
        message: demandeur.estPPI
          ? `Voulez-vous vraiment valider  la fiche Azoli de ${demandeur.nom} ${demandeur.prenom}?`
          : `Voulez-vous vraiment valider le CV de ${demandeur.nom} ${demandeur.prenom}?`,
        header: "Confirmation",
        icon: "pi pi-exclamation-triangle",
        accept: () => {
          this.validerDossier({
            ...demandeur,
            status: "VALIDE",
          }).then((data) => {
            console.log(data);
            this.$refs.deStatistic.reload();
            this.$toast.success("Fiche validée avec succès!", {
              position: "top-right",
              duration: 10000,
            });
          });
        },
      });
    },
    async destroyDemandeur(demandeur) {
      console.log(demandeur);
      await this.$confirm.require({
        group: "confirmDialog",
        message: "Voulez-vous vraiment supprimer cet élément?",
        header: "Confirmation",
        icon: "pi pi-exclamation-triangle",
        accept: () => {
          this.deleteDemandeur(demandeur);
        },
      });
    },
    updateOrCreateDemandeur(demandeur) {
      console.log(demandeur);
      this.createOrUpdateDemandeur(demandeur).then((data) => {
        console.log(data);
        this.$toast.success("Opération réussie!", {
          position: "top-right",
          duration: 5000,
        });
      });
    },
  },
};
</script>

<style></style>

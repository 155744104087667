<template>
  <div class="pb-5">
    <div
      class="row bg-white me-2 pt-2 pb-1 shadow-light"
      style="position: absolute; top: 65px; z-index: 100; width: 99.6%"
    >
      <div class="col-md-6 col-sm-12">
        Sélectionnées <PTag>{{ saved.length }}</PTag>
        <button @click.prevent="clearSelection" class="btn btn-sm btn-danger">
          <i class="bi bi-close"></i>Vider
        </button>
      </div>
    </div>
    <div class="mt-">
      <div class="bg mt-5" style="position: ">
        <div class="d-flex flex-wrap mb-4 justify-content-between align-items-center">
          <div class="my-1 flex-fill">
            <slot name="listTitle">
              <!-- <h6>{{ listTitle }}</h6> -->
            </slot>
          </div>
        </div>
        <div>
          <slot name="listDescription"></slot>
        </div>
        <div class="bg-white p-3 mt-2 border shadow-0">
          <div class="container- my-1">
            <p-progress-bar
              v-if="mxLoading"
              style="height: 0.33em"
              mode="indeterminate"
            />
            <div class="border- shadow-0 rounded-4">
              <form class="bg-" method="post" accept-charset="utf-8" action="">
                <div class="row">
                  <div class="col">
                    <div class="input-group">
                      <div class="p-inputgroup">
                        <PInputText
                          type="text"
                          v-model="mxSearch"
                          :placeholder="'Rechercher par nom prénom'"
                        />
                        <!-- <p-multi-select
                          :multiple="false"
                          v-model="mxSearchFields"
                          :options="searchFieldsOptions.filter((el) => el.visible)"
                          optionLabel="label"
                          optionValue="value"
                          :editable="true"
                        /> -->
                      </div>
                    </div>
                  </div>
                  <div class="col-auto">
                    <a
                      href="#"
                      @click.prevent="mxSubmitQuery"
                      class="style_btn btn btn-primary"
                      id="button-query"
                      ><i class="bi bi-search me-2"></i>Rechercher</a
                    >
                  </div>
                </div>
              </form>
              <transition>
                <div class="border-0 shadow-0 mt-2">
                  <div class="row p-fluid grid">
                    <template v-for="(option, index) in filterOptions">
                      <div
                        class="col-12 col-sm-6 col-md-3 mb-2"
                        v-if="option.visible"
                        :key="index"
                      >
                        <!-- <label for="basic">{{option.label}}</label> -->
                        <!-- <c-auto-complete v-if="option.url" display="chip" v-model="option.value" chips :option="option" :force-selection="true" /> -->
                        <!-- <p-multi-select v-model="option.value" :options="getItemsOf(option.getterName)" :optionLabel="option.labelField" :selectionLimit="3" :placeholder="option.label" optionValue="id" :filter="true" display="chip"/> -->
                        <template v-if="option.queryUrl || option.items">
                          <AsyncMultiSelect
                            v-model="option.value"
                            :multiple="true"
                            :queryUrl="option.queryUrl"
                            :optionLabel="option.labelField"
                            :placeholder="option.label"
                            :emitObject="false"
                            v-if="option.queryUrl"
                            :displayMode="option.displayMode"
                            :searchOptions="[option.labelField]"
                          />
                          <p-dropdown
                            class="flex-grow-1 me-2"
                            v-model="option.value"
                            :options="option.items"
                            v-else
                            optionLabel="label"
                            optionValue="value"
                            :placeholder="option.label"
                            display="chip"
                          />
                        </template>

                        <p-input-text
                          v-else
                          v-model="option.value"
                          :placeholder="option.label"
                        />
                      </div>
                    </template>
                  </div>
                  <div class="d-flex justify-content-end"></div>
                </div>
              </transition>
            </div>
          </div>
          <div class="d-flex align-items-center flex-wrap text-wrap mb-4">
            <h6 class="text-uppercase" v-if="mxActiveFiltersCount > 0">
              Filtres appliquées:
            </h6>
            <template v-for="(option, index) in filterOptions">
              <!-- <div v-if="option.value != null && option.value.length != 0" :key="index" class="mb-2 border border-primary pt-2"  style="width: 320px"> -->
              <template v-if="option.multiple">
                <PTag
                  severity="warning"
                  class="ms-2 mb-2"
                  v-for="(item, i) in option.value"
                  :key="index + '_' + i"
                >
                  <small class="p-1 text-caption">
                    {{ item[option.labelField] }}
                    <PButton
                      v-tooltip.top="`Retirer du filtre`"
                      icon="pi pi-times cursor-pointer"
                      class="p-button-danger p-button-sm ms-1 p-0"
                      @click.prevent.stop="
                        option.value = option.value.filter((el) => el != item)
                      "
                      style="height: 14px; width: 14px"
                    />
                  </small>
                </PTag>
              </template>

              <template v-else>
                <PTag
                  severity="warning"
                  class="ms-2 mb-2"
                  :key="index + '_' + i"
                  v-if="option.value"
                >
                  <small class="p-1 text-caption">
                    {{ option.value }}
                    <PButton
                      v-tooltip.top="`Retirer du filtre`"
                      icon="pi pi-times cursor-pointer"
                      class="p-button-danger p-button-sm ms-1 p-0"
                      @click.prevent.stop="option.value = option.value = null"
                      style="height: 14px; width: 14px"
                    />
                  </small>
                </PTag>
              </template>
            </template>
            <!-- <PButton v-if="mxActiveFiltersCount > 0" class="p-button-danger p-button-outlined py-1 p-button-sm ms-1" @click.prevent.stop="filterOptions.forEach((option) => option.value = [])">
                Réinitialiser les filtres
              </PButton> -->
          </div>
          <div class="table-responsive">
            <b-table
              show-empaty
              empty-text="Aucune donnée à afficher"
              :fields="tableFields"
              class="align-middle"
              striped
              select-mode="multi"
              ref="table"
              :sort-by.sync="mxPagination.sortBy"
              :sort-desc.sync="mxPagination.sortDesc"
              responsive="sm"
              selectable
              sort-icon-left
              @row-selected="onRowSelected"
              :items="tableData"
            >
              <template #head()="data">
                <span>{{ data.label }} </span>
              </template>
              <template #head(index)="">
                <span> N° </span>
              </template>

              <template #head(#)="data">
                <div class="form-check">
                  <input
                    @change="selectAll"
                    class="form-check-input"
                    type="checkbox"
                    :id="`${data.index}`"
                    name="disponible"
                    :value="false"
                  />
                </div>
              </template>
              <template #cell(#)="data">
                <div class="form-check">
                  <input
                    @input="selectionChanged($event, data)"
                    :checked="data.rowSelected"
                    class="form-check-input"
                    type="checkbox"
                    :id="`${data.index}`"
                    name="disponible"
                  />
                </div>
              </template>

              <!-- A virtual column -->
              <template #cell(index)="data">
                {{ data.index + 1 }}
              </template>
              <template #cell(enabled)="data">
                <div class="form-check form-switch">
                  <input
                    class="form-check-input"
                    v-model="data.item.enabled"
                    type="checkbox"
                    id="flexSwitchCheckChecked"
                    :checked="data.item.enabled"
                  />
                  <label class="form-check-label" for="flexSwitchCheckChecked">{{
                    data.item.enabled ? "Actif" : "Inactif"
                  }}</label>
                </div>
              </template>

              <template #cell(roles)="{ item: cand }">
                <span class="px-1" v-for="(role, i) in cand.roles" :key="i">
                  <PTag
                    class="text-uppercase badge badge-primary mr-1"
                    :key="i"
                    :severity="role == 'AUDITEUR' ? 'primary' : 'warning'"
                    >{{ role }}
                  </PTag>
                </span>
              </template>
              <template #cell(actions)="data">
                <div class="d-flex justify-content-end">
                  <slot name="actions" :data="data">
                    <button class="btn btn-sm py-1 btn-outline-primary">
                      <i class="bi bi-journal-check"></i>
                    </button>
                  </slot>
                </div>
              </template>
              <!-- Optional default data cell scoped slot -->
              <template #cell()="data">
                <span>{{ data.value }}</span>
              </template>
            </b-table>
          </div>
          <div class="d-flex flex-wrap justify-content-between align-items-center">
            <p>
              Affichage de l’élément
              {{ mxPagination.page * mxPagination.rowsPerPage }} à
              {{ (mxPagination.page + 1) * mxPagination.rowsPerPage }}
              de {{ mxTotalCount }} éléments
            </p>
            <b-pagination
              v-model="mxPagination.page"
              :total-rows="mxTotalCount"
              :per-page="mxPagination.rowsPerPage"
              aria-controls="my-table"
            ></b-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { paginatorMixin } from "@/mixins/paginator-mixin";
import { multiPageItemSelectorMixin } from "@/mixins/multipage-item-selector-mixin";
import CAutoComplete from "./CAutoComplete.vue";
import { mapActions, mapGetters } from "vuex";
import AsyncMultiSelect from "./AsyncMultiSelect.vue";
import AzoliStatusBadge from "./AzoliStatusBadge.vue";

export default {
  props: {
    listTitle: {
      type: String,
      default: "Référentiel non précisé",
    },
    referentielName: {
      type: String,
      required: true,
    },
    tableFields: {
      type: Array,
      required: true,
    },
    value: {
      type: Array,
      default: () => [],
    },
    emptyReferentielItem: {
      type: Object,
      required: true,
    },
    dataFormatter: {
      type: Function,
    },
    extraQueryArgsParamBuilder: {
      type: Function,
      default: () => {},
    },
    paginatorConfig: {
      type: Object,
      default: () => {},
      required: false,
    },
  },
  components: {
    CAutoComplete,
    AsyncMultiSelect,
    AzoliStatusBadge,
  },
  mixins: [paginatorMixin, multiPageItemSelectorMixin],
  data() {
    return {
      selected: [],
      selectedItems: [],
      saved: [],
      search: "",
      dateDeb: null,
      dateFin: null,
      inclureAncienBeneficiaire: false,
      updatingTable: false,
      showAdvancedFilter: false,
      matchingType: "MANUEL",
      activeReferentiel: { ...this.emptyReferentielItem },
      searchFields: ["nomPrenom"],
      searchFieldsOptions: [
        {
          label: "Auditeur",
          value: "nomPrenom",
          visible: true,
        },
      ],
      filterOptions: [],
    };
  },
  watch: {},
  mounted() {},
  created() {
    this.makeQuery();
  },
  computed: {
    ...mapGetters({}),
    tableData() {
      return this.mxFilteredItems.map((item) => {
        return { ...item };
      });
    },
    referentielCaptilizedName() {
      let lowerName = this.referentielName;
      return lowerName[0].toUpperCase() + lowerName.slice(1, lowerName.length);
    },
    referentielLowercaseName() {
      return this.referentielName; //Not use toLowerCase
    },
  },
  methods: {
    ...mapActions({}),
    makeQuery() {
      this.mxInitializePaginator({
        queryModel: this.referentielName,
        search: this.search,
        fetcherMethod: `${this.referentielName}/fetch${this.referentielCaptilizedName}s`,
        dataGetter: `${this.referentielName}/${this.referentielName}s`,
        paginationGetter: `${this.referentielName}/pagination`,
        autoSubmitQuery: false,
        submitQueryOnInit: false,
        pagination: {
          sortBy: "nom",
          sortDesc: false,
          descending: false,
          page: 0,
          rowsPerPage: 8,
        },
        filterOptions: this.filterOptions,
        searchFields: this.searchFields,
        extraQueryArgsParamBuilder: () => {
          let params = `${this.extraQueryArg}`;
          return params;
        },
      });
    },
    getItemsOf(getterName) {
      return this.$store.getters[getterName];
    },
    clearSelection() {
      this.saved = [];
      this.selected = [];
      this.$refs.table.clearSelected();
    },
    presenceChange(item) {
      this.$emit("presenceChange", item);
    },
  },
};
</script>

<style>
.shadow-light {
  box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 3%) !important;
}

.msg-cloture.p-message .p-message-wrapper {
  padding: 0.5rem 1.2rem;
}
</style>

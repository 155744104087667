/* eslint-disable vue/no-unused-components */
<template>
  <div class="container-fluid">
    <PresencePosteEditorDialog
      v-if="activePresencePoste != null"
      ref="presencePosteEditorDialog"
      :item="activePresencePoste"
      :title="
        'Ajout de présence au poste ' +
        (activePresencePoste.participationPlacement != null
          ? ' de ' + activePresencePoste.beneficiaire.slice(10) + '...'
          : '')
      "
      @save="savePresencePoste"
    />
    <PriseDeServiceEditorDialog
      ref="priseDeServiceEditorDialog"
      :item="activePriseDeService"
      :title="'Notifier la prise de service'"
      @save="savePriseDeService"
    />
    <CessationServiceEditorDialog
      ref="cessationServiceEditorDialog"
      :item="activeCessationService"
      :title="'Notifier une cessation de service'"
      @save="saveCessationService"
    />
    <div class="row fw-bold bg-white mt-3">
      <div class="col shadow-1">SANS ABSENCE: {{ stat.regulierCount }}</div>
      <div class="col shadow-1">AVEC ABSENCE: {{ stat.semiRegulierCount }}</div>
      <div class="col shadow-1">
        ABANDON: {{ stat.totalCount - stat.semiRegulierCount - stat.regulierCount }}
      </div>
      <div class="col shadow-1">TOTAL: {{ stat.totalCount }}</div>
    </div>
    <PDivider />
    <div class="container- mt-1">
      <p-progress-bar v-if="mxLoading" style="height: 0.33em" mode="indeterminate" />
      <div class="bg-white p-2 rounded-4">
        <form class="bg-" method="post" accept-charset="utf-8" action="">
          <div class="row py-0 p-fluid grid formgrid">
            <div class="col field">
              <form class="d-flex">
                <div class="input-group me-4">
                  <div class="p-inputgroup">
                    <PInputText
                      type="text"
                      v-model="mxSearch"
                      :placeholder="'Rechercher par'"
                    />
                    <p-multi-select
                      v-model="mxSearchFields"
                      :options="searchFieldsOptions.filter((el) => el.visible)"
                      optionLabel="label"
                      optionValue="value"
                      :editable="true"
                    />
                  </div>
                </div>
              </form>
            </div>
            <div class="col-md-6 col-sm-12 d-flex">
              <div class="input-group me-4">
                <div class="p-inputgroup">
                  <p-dropdown
                    class="flex-grow- me-2"
                    v-model="sexeDe"
                    :options="sexeOptions"
                    optionLabel="label"
                    optionValue="value"
                    :placeholder="'Sexe DE'"
                    display="chip"
                  />
                  <p-dropdown
                    class="flex-grow- me-2"
                    v-model="status"
                    :options="statusOptions"
                    optionLabel="label"
                    optionValue="value"
                    :placeholder="'Statut présence'"
                    display="chip"
                  />
                </div>
              </div>
            </div>
            <div class="col-auto">
              <div class="d-grid gap-2">
                <a
                  href="#"
                  @click.prevent="mxSubmitQuery"
                  class="style_btn btn btn-primary"
                  id="button-query"
                  ><i class="bi bi-search"></i
                ></a>
              </div>
            </div>
            <div class="col-auto">
              <button
                type="button"
                @click.prevent="showAdvancedFilter = !showAdvancedFilter"
                class="style_btn btn btn-warning"
                id="button-query"
              >
                <i class="bi bi-filter"></i>
              </button>
            </div>
          </div>
        </form>
        <transition>
          <div class="card mx- border-radius-5 mt-2 hadow-5" v-if="showAdvancedFilter">
            <div class="row p-fluid p-3 grid">
              <template v-for="(option, index) in filterOptions">
                <div
                  class="col-12 col-sm-6 col-md-3 col-sm-12 mb-3"
                  v-if="option.visible"
                  :key="index"
                >
                  <label :for="option.labelField">{{ option.label }}</label>

                  <AsyncMultiSelect
                    v-model="option.value"
                    :multiple="true"
                    :queryUrl="option.queryUrl"
                    :optionLabel="option.labelField"
                    :placeholder="option.label"
                    :emitObject="false"
                    :displayMode="option.displayMode"
                    :searchOptions="[option.labelField]"
                  />
                </div>
              </template>
            </div>
          </div>
        </transition>
      </div>
      <p-divider />
      <div class="d-flex flex-wrap mb-4 justify-content-between align-items-center">
        <div class="my-1 flex-fill">
          <h4>
            Liste des présences au poste

            <span
              v-if="pointPresence != null && pointPresence.dateMoisPointPresence != null"
              >de
            </span>
            <span class="text-uppercase">{{
              pointPresence != null && pointPresence.dateMoisPointPresence != null
                ? $dayjs(pointPresence.dateMoisPointPresence).format("MMM YYYY")
                : ""
            }}</span>
            {{ listTitleSuffix != null ? " - " + listTitleSuffix.toUpperCase() : "" }}
          </h4>
        </div>
        <div class="btn-group">
          <a
            @click.prevent="showCloturerPointPresence(pointPresence)"
            class="ms-2 style_btn btn btn-warning"
            v-if="
              !['CLOTURE', 'VALIDE'].includes(pointPresence.status) &&
              $can('CLOTURER', 'POINT_PRESENCE')
            "
          >
            Clôturer le point de présence
          </a>
          <a
            @click.prevent="showValiderPointPresence(pointPresence)"
            class="ms-2 style_btn btn btn-info"
            v-if="pointPresence.status == 'CLOTURE' && $can('VALIDER', 'POINT_PRESENCE')"
          >
            Valider le point de présence
          </a>
        </div>
        <PMessage
          v-show="errorMessage"
          :severity="'error'"
          @close="errorMessage = null"
          >{{ errorMessage }}</PMessage
        >
        <div class="btn-group ms-4">
          <PSplitButton
            label="Exporter"
            :model="exportActions"
            class="p-button-outlined p-button-primary"
          />
        </div>
        <div class="btn-group ms-4">
          <button
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            title="Affichage mode tableau"
            @click.prevent="viewMode = 'card'"
            class="btn style_btn btn-md"
            :class="{
              'btn-success': viewMode == 'card',
              'btn-light': viewMode == 'table',
            }"
          >
            <i class="bi-grid" />
          </button>
          <button
            @click.prevent="viewMode = 'table'"
            class="btn style_btn"
            :class="{
              'btn-light': viewMode == 'card',
              'btn-success': viewMode == 'table',
            }"
          >
            <i class="bi-table" />
          </button>
        </div>
      </div>

      <template v-if="!mxLoading">
        <div class="bg-">
          <div class="bg-white p-3 border">
            <div class="table-responsive">
              <b-table
                show-empty
                empty-text="Aucune donnée à afficher"
                :fields="tableFields"
                class="align-middle"
                striped
                select-mode="multi"
                ref="table"
                selectable
                @row-selected="onRowSelected"
                :items="tableData"
              >
                <template #head()="data">
                  <span>{{ data.label }} </span>
                </template>

                <template #empty="scope">
                  <h4>{{ scope.emptyText }}</h4>
                  <!-- Vous n'avez pas encore commencé l -->
                  <button class="btn btn-primary" @click="genererListeDePresence">
                    Génér la liste de présence
                  </button>
                </template>

                <template #head(#)="data">
                  <div class="form-check form-check-inline">
                    <input
                      @change="selectAll"
                      class="form-check-input"
                      type="checkbox"
                      :id="`${data.index}`"
                      name="disponible"
                      :value="false"
                    />
                  </div>
                </template>
                <!-- A virtual column -->
                <template #cell(index)="data">
                  {{ data.index + 1 }}
                </template>

                <template #cell(#)="data">
                  <div class="form-check form-check-inline">
                    <input
                      @input="selectionChanged($event, data)"
                      :checked="data.rowSelected"
                      class="form-check-input"
                      type="checkbox"
                      :id="`${data.index}`"
                      name="disponible"
                    />
                  </div>
                </template>

                <template #cell(status)="{ item: cand }">
                  <PTag
                    class="text-uppercase badge badge-primary"
                    :severity="getStatusPresencePosteColor(cand.status)"
                    v-if="cand.status"
                    >{{ cand.status }}
                  </PTag>
                  <!-- <span class="">
                    <i
                      v-if="cand.nombreAbsenceSansMotif != null"
                      class="bi bi-check-circle text-success"
                      style="font-size: 1.5rem"
                    ></i>
                    <i
                      v-else
                      class="bi bi-slash-circle text-danger"
                      style="font-size: 1.5rem"
                    ></i>
                  </span> -->
                </template>
                <template #cell(isEvalue)="{ item: de }">
                  <span class="text-uppercase">
                    <i
                      v-if="de.isEvalue"
                      v-tooltip.left="'Evaluée'"
                      class="bi bi-check-circle text-success"
                      style="font-size: 1.5rem"
                      v-badge.success="de.typeCandidature == 'RECOMMANDE' ? 'R' : 'S'"
                    ></i>
                    <i
                      v-else
                      v-tooltip.left="'Non évaluée'"
                      class="bi bi-slash-circle text-danger"
                      style="font-size: 1.5rem"
                      v-badge.danger="de.typeCandidature == 'RECOMMANDE' ? 'R' : 'S'"
                    ></i>
                  </span>
                </template>
                <template #cell(nom)="{ item: de }">
                  <span class="text-uppercase">{{ de.nom }}</span>
                </template>
                <template #cell(dateDebut)="{ item: de }">
                  <span class="text-uppercase">{{
                    $dayjs(de.dateDebut).format("DD-MM-YYYY")
                  }}</span>
                </template>
                <template #cell(dateFin)="{ item: de }">
                  <span class="text-uppercase">{{
                    $dayjs(de.dateFin).format("DD-MM-YYYY")
                  }}</span>
                </template>
                <template #cell(dateDemarrage)="{ item: de }">
                  <span class="text-uppercase">{{
                    $dayjs(de.dateDemarrage).format("DD-MM-YYYY")
                  }}</span>
                </template>
                <template #cell(prenom)="{ item: de }">
                  <span class="text-uppercase">{{ de.prenom }}</span>
                </template>
                <template #cell(posteOccupe)="{ item: de }">
                  <div class="ellipsis" v-tooltip.top="de.posteOccupe">
                    {{ de.posteOccupe | truncate() }}
                  </div>
                </template>

                <template #cell(actions)="{ item: pp }">
                  <div class="d-flex justify-content-end">
                    <a
                      class="btn btn-primary rounded-0 btn-sm me-1"
                      v-if="
                        pp.datePriseService == null && pp.dateCessationService == null
                      "
                      v-tooltip.top="'Prise de service'"
                      @click.stop="showPriseDeServiceEditorDialog(pp)"
                    >
                      <i class="bi bi-chevron-bar-expand"></i>
                    </a>
                    <a
                      class="btn btn-warning rounded-0 btn-sm me-1"
                      v-tooltip.top="'Présence au poste'"
                      v-if="pp.dateCessationService == null"
                      @click.stop="showPresencePosteEditorDialog(pp)"
                    >
                      <i class="bi bi-person-check"></i>
                    </a>
                    <a
                      class="btn btn-danger rounded-0 btn-sm me-1"
                      v-tooltip.top="'Cessation de service'"
                      v-if="pp.dateCessationService == null"
                      @click.stop="
                        showCessationServiceEditorDialog(pp.participationPlacement)
                      "
                      ><i class="bi bi-journal-x"></i
                    ></a>
                  </div>
                </template>
                <!-- Optional default data cell scoped slot -->
                <template #cell()="data">
                  <span>{{ data.value }}</span>
                </template>
              </b-table>
            </div>
            <div class="d-flex flex-wrap justify-content-between align-items-center">
              <p>
                Affichage de l’élément
                {{ mxPagination.page * mxPagination.rowsPerPage }} à
                {{ (mxPagination.page + 1) * mxPagination.rowsPerPage }} de
                {{ mxTotalCount }} éléments
              </p>
              <b-pagination
                v-model="mxPagination.page"
                :total-rows="mxTotalCount"
                :per-page="mxPagination.rowsPerPage"
                aria-controls="my-table"
              ></b-pagination>
            </div>
          </div>
        </div>
      </template>
      <template v-else>
        <div>
          <PDataTable
            :value="
              [1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((e) => {
                e;
              })
            "
          >
            <PColumn
              v-for="f in tableFields"
              :key="f.key"
              :field="f.key"
              :header="f.label"
            >
              <template #body>
                <PSkeleton></PSkeleton>
              </template>
            </PColumn>
          </PDataTable>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { paginatorMixin } from "@/mixins/paginator-mixin";
import { authMixin } from "../../../../mixins/auth-mixin";
import CAutoComplete from "../../../../components/common/CAutoComplete.vue";

import PresencePosteEditorDialog from "../../../../components/espace/entreprise/editor/PresencePosteEditorDialog.vue";
import CessationServiceEditorDialog from "../../../../components/espace/entreprise/editor/CessationServiceEditorDialog.vue";
import PriseDeServiceEditorDialog from "../../../../components/espace/entreprise/editor/PriseDeServiceEditorDialog.vue";

import DemandeurProfilSelectorDialog from "../../../../components/espace/common/DemandeurProfilSelectorDialog.vue";
import CandidatureTransmissionDialog from "../../../../components/espace/entreprise/candidature/CandidatureTransmissionDialog.vue";
import AsyncMultiSelect from "../../../../components/common/AsyncMultiSelect.vue";

export default {
  props: {
    listTitleSuffix: {
      type: String,
      default: null,
    },
    extraQueryArg: {
      type: String,
      default: "",
    },
    pointPresence: {
      type: Object,
    },
  },
  components: {
    AsyncMultiSelect,
    PresencePosteEditorDialog,
    CessationServiceEditorDialog,
    PriseDeServiceEditorDialog,
    CAutoComplete,
    DemandeurProfilSelectorDialog,
    CandidatureTransmissionDialog,
  },
  mixins: [paginatorMixin, authMixin],
  data() {
    return {
      selected: [],
      search: "",
      activePriseDeService: {},
      activeCessationService: {},
      activePresencePoste: {},
      participationPlacement: null,
      dateDeb: null,
      dateFin: null,
      errorMessage: null,
      showAdvancedFilter: false,
      placementValidation: {},
      stat: {},
      currentOffre: {},
      activeTransmission: {},
      viewMode: localStorage.getItem("viewMode") || "table", //card, table
      activeRecommandation: {
        demandeur: [],
      },
      statusOptions: [
        { value: null, label: "Tous" },
        { value: "NON_CONTROLE", label: "NON_CONTROLE" },
        { value: "CONTROLE", label: "CONTROLE" },
        { value: "CAS_IRREGULIER", label: "CAS_IRREGULIER" },
      ],
      status: null,
      sexeOptions: [
        { value: null, label: "Tous" },
        { value: "M", label: "Masculin" },
        { value: "F", label: "Féminin" },
      ],
      sexeDe: null,
      exportActions: [
        {
          label: "PDF",
          icon: "pi pi-file-pdf",
          command: () => {
            this.mxExportToPDF({
              meta: {
                title: "Liste des présences au poste",
                subtitle: "Liste des présences au poste",
              },
              pathParams: {
                name: "common.export",
                query: {
                  apiPath: "presencePostes/exportToPdf",
                  queryParams: this.mxGetQueryBuilder(),
                  fileName: "Liste des présences au poste.pdf",
                },
              },
            });
          },
        },
        {
          label: "Excel",
          icon: "pi  pi-file-excel",
          command: () => {
            this.mxExportToExcel({
              meta: {
                title: "Liste des présences au poste",
                subtitle: "Liste des présences au poste",
              },
              pathParams: {
                name: "common.export",
                query: {
                  apiPath: "presencePostes/exportToExcel",
                  queryParams: this.mxGetQueryBuilder(),
                  fileName: "Liste des présences au poste.xlsx",
                },
              },
            });
          },
        },
      ],
      searchFields: ["beneficiaire"],
      searchFieldsOptions: [
        { label: "Bénéficiaire", value: "beneficiaire", visible: true },
        { label: "Téléphone", value: "beneficiairePhone", visible: true },
      ],
      filterOptions: [
        {
          column: "entreprise.id",
          value: [],
          label: "Dénomination Entreprise",
          dropdown: true,
          labelField: "raisonSocial",
          displayMode: "classic",
          valueKey: "id",
          queryUrl: "entreprises",
          visible: true,
        },
        {
          column: "entreprise.id",
          value: [],
          label: "Ifu Entreprise",
          dropdown: true,
          labelField: "ifu",
          displayMode: "classic",
          valueKey: "ifu",
          queryUrl: "entreprises",
          visible: true,
        },
        // {
        //   column: "reference",
        //   value: [],
        //   label: "Référence placement",
        //   dropdown: true,
        //   labelField: "reference",
        //   displayMode: "classic",
        //   valueKey: "participationPlacement.placement.referenc",
        //   queryUrl: "placements",
        //   visible: true,
        // },
      ],
      filters: {
        skills: [],
        availabilities: [],
        price_min: null,
        price_max: null,
        departement: null,
        _queryOptions: [
          {
            column: "departement",
            value: null,
            label: "Département",
          },
          {
            column: "commune",
            value: null,
            label: "Commune",
          },
        ],
        ordering: "",
        // _orderingOptions: {
        // }
      },
      activeCandidature: {},
      activeCandidature: {},
      breadcrumbs: [
        {
          text: "Admin",
          href: "#",
        },
        {
          text: "Paramètres",
          href: "#",
        },
        {
          text: "Demandeurs",
          active: true,
        },
      ],
    };
  },
  created() {
    this.makeQuery();
  },
  watch: {
    viewMode() {
      localStorage.setItem("viewMode", this.viewMode);
    },
  },
  computed: {
    ...mapGetters({
      dossier: "demandeur/demandeur",
      statutDossiers: "setting/statutDossiers",
      // demandeurs: 'demandeur/demandeurs',
      pagination: "demandeur/pagination",
    }),
    tableFields() {
      return [
        "#",
        { key: "beneficiaireNom", label: "Nom" },
        { key: "beneficiairePrenom", label: "Prénom" },
        { key: "beneficiaireSexe", label: "Sexe" },
        { key: "beneficiairePhone", label: "Phone" },
        // { key: "participationPlacement.beneficiaire", label: "Prénom" },
        { key: "participationPlacement.posteOccupe", label: "Poste" },
        {
          key: "participationPlacement.typePlacement.libelle",
          label: "Type.Emploi",
        },
        { key: "dateDemarrage", label: "Date.Démarrage" },
        { key: "status", label: "Statut" },
        {
          key: "nombreDeJourDeTravailDepuisDemarrage",
          label: "Nbre Jours Ouvrés",
        },
        { key: "nombreAbsenceSansMotif", label: "Nbre Absences" },
        "actions",
      ];
    },
    tableData() {
      return this.mxFilteredItems.map((item) => {
        return { ...item };
      });
    },
    // currentOffre() {
    //   const offre = this.mxFilteredItems[0] != null? this.mxFilteredItems[0].offre : {}
    //   return offre
    // }
  },
  methods: {
    ...mapActions({
      fetchPresencePosteCountStatistics: "statistic/fetchPresencePosteCountStatistics",
      fetchAzoliStatistics: "statistic/fetchAzoliStatistics",
      fetchDemandeurs: "demandeur/fetchDemandeurs",
      fetchOffre: "offre/getOneOffre",
      createOrUpdateDemandeur: "demandeur/createOrUpdateDemandeur",
      deleteDemandeur: "demandeur/deleteDemandeur",
      downloadAzoli: "demandeur/downloadAzoli",
      fetchDossier: "demandeur/fetchDossier",
      validerDossier: "demandeur/validerDossier",
      transfererVersConseiller: "transfert/transfererVersConseiller",
      accepterPropostionCandidature: "candidature/accepterPropostionCandidature",
      exigerPropostionCandidature: "candidature/exigerPropostionCandidature",
      transmettrePropositionCandidatures:
        "candidature/transmettrePropositionCandidatures",
      retirerRecommanation: "candidature/retirerRecommanationCandidature",
      fetchCandidatureNonEvaluesCount: "offre/fetchCandidatureNonEvaluesCount",
      createOrUpdatePriseService: "priseService/createOrUpdatePriseService",
      createOrUpdateArretContrat: "arretContrat/createOrUpdateArretContrat",
      createOrUpdatePresencePoste: "presencePoste/createOrUpdatePresencePoste",
      generateListeDePresence: "presencePoste/genererListeDePresence",
      recommanderProfils: "offre/recommanderProfils",
    }),
    showCloturerPointPresence(pointPresence) {
      if (!this.checkCanModifyPointPresence()) return;
      this.$emit("cloturer", pointPresence);
    },
    showValiderPointPresence(pointPresence) {
      if (!this.checkCanModifyPointPresence()) return;
      this.$emit("valider", pointPresence);
    },
    genererListeDePresence() {
      this.generateListeDePresence({
        pointPresence: this.pointPresence,
      }).then((data) => {
        this.makeQuery();
      });
    },
    checkCanModifyPointPresence() {
      if (this.pointPresence.status == "VALIDE") {
        this.$toast.error(
          `Ce point de présence est déjà VALIDE. Il ne peut plus être modifié.`,
          {
            position: "top-right",
            duration: 8000,
          }
        );
        return false;
      }
      return true;
    },
    showPresencePosteEditorDialog(presencePoste) {
      if (!this.checkCanModifyPointPresence()) return;
      if (
        presencePoste.datePriseService != null &&
        presencePoste.dateCessationService == null
      ) {
        this.activePresencePoste = { ...presencePoste };
        this.$refs.presencePosteEditorDialog.show();
      } else if (presencePoste.dateCessationService != null) {
        this.$toast.info(`Le DE ${presencePoste.beneficiaire} a cessé les stage.`, {
          position: "top-right",
          duration: 8000,
        });
      } else if (presencePoste.datePriseService == null) {
        this.$toast.info(
          `Vous n'avez pas encore signalé la prise de service de ${
            presencePoste.beneficiaire
          }. Signalez sa prise de service avant de procéder au renseignement de sa présence au poste pour le mois de ${
            this.pointPresence != null && this.pointPresence.dateMoisPointPresence != null
              ? this.$dayjs(this.pointPresence.dateMoisPointPresence).format("MMM YYYY")
              : ""
          }.`,
          {
            position: "top-right",
            duration: 8000,
          }
        );
      }
    },
    showPriseDeServiceEditorDialog({ participationPlacement }) {
      if (!this.checkCanModifyPointPresence()) return;

      // this.activeParticipationPlacement = { ...participationPlacement }
      this.activePriseDeService = { participationPlacement };
      this.$refs.priseDeServiceEditorDialog.show();
    },
    showCessationServiceEditorDialog(participationPlacement) {
      if (!this.checkCanModifyPointPresence()) return;

      this.activeCessationService = { participationPlacement };
      this.$refs.cessationServiceEditorDialog.show();
    },
    savePriseDeService(priseDeService) {
      this.createOrUpdatePriseService(priseDeService)
        .then((data) => {
          this.$toast.success("Prise de service sauvegardée avec succès!", {
            position: "top-right",
            duration: 8000,
          });
          this.makeQuery();
        })
        .catch((err) => err);
    },
    savePresencePoste(presencePoste) {
      this.createOrUpdatePresencePoste(presencePoste)
        .then((data) => {
          this.$toast.success("Présence au poste sauvegardée avec succès!", {
            position: "top-right",
            duration: 8000,
          });
          this.makeQuery();
        })
        .catch((err) => err);
    },
    saveCessationService(cessationService) {
      this.createOrUpdateArretContrat(cessationService)
        .then((data) => {
          this.$toast.success("Cessation de service sauvegardée avec succès!", {
            position: "top-right",
            duration: 8000,
          });
          this.makeQuery();
        })
        .catch((err) => err);
    },
    statisticBlockSelected(status) {
      this.mxSetOptionValue("status", status, true);
    },
    getStatusPresencePosteColor(status) {
      let color = "primary";
      if (status == "PAS_PRIS_SERVICE") {
        color = "warning";
      } else if (status == "CONTROLE") {
        color = "success";
      } else if (status == "CAS_IRREGULIER") {
        color = "primary";
      } else if (status == "ABANDON") {
        color = "danger";
      }

      return color;
    },
    queryStatistics() {
      this.mxInitializePaginator({
        queryModel: "presencePoste",
        search: this.search,
        fetcherMethod: "presencePoste/fetchPresencePostes",
        dataGetter: "presencePoste/presencePostes",
        paginationGetter: "presencePoste/pagination",
        autoSubmitQuery: false,
        pagination: {
          sortBy: "id",
          descending: false,
          page: 0,
          rowsPerPage: 10,
          sortingParam: `sort=id,desc`,
        },
        filterOptions: this.filterOptions,
        extraQueryArgsParamBuilder: () => {
          const dateDeb = (this.dateDeb || new Date("2021-12-31"))
            .toISOString()
            .substring(0, 10);
          const dateFin = (this.dateFin || new Date("2080-12-31"))
            .toISOString()
            .substring(0, 10);

          let paramPlus = this.mxAddQueryParam("beneficiaireSexe", this.sexeDe);
          paramPlus = this.mxAddQueryParam("status", this.status, paramPlus);

          let params = `${this.extraQueryArg}&${paramPlus}`;

          return params;
        },
        searchFields: this.searchFields,
      });
    },
    makeQuery() {
      this.queryStatistics();

      this.fetchPresencePosteCountStatistics({
        extraQueryArg: this.mxGetQueryBuilder(),
      }).then((data) => {
        this.stat = data;
      });
    },
    selectionChanged(evt, data) {
      console.log(evt.target.checked, data);

      if (evt.target.checked) {
        this.$refs.table.selectRow(data.index);
      } else {
        this.$refs.table.unselectRow(data.index);
      }
    },
    onRowSelected(items) {
      this.selected = items;
    },
    selectAll(evt) {
      if (evt.target.checked) {
        this.$refs.table.selectAllRows();
      } else {
        this.$refs.table.clearSelected();
      }
    },
    editDemandeur(demandeur) {
      this.activeCandidature = { ...demandeur };
      this.$router.push({ name: "espace.conseiller.de.edit" });
    },
    savePlacementValidation(placementValidation) {
      if (placementValidation && placementValidation.candidature) {
        console.log(placementValidation);
        if (placementValidation.candidature.status == "RECALE") {
          this.exigerPropostionCandidature({
            candidatureId: placementValidation.candidature.id,
            placementValidation,
          }).then((data) => {
            this.makeQuery();
          });
        } else if (placementValidation.candidature.status == "PROPOSE") {
          this.accepterPropostionCandidature({
            candidatureId: placementValidation.candidature.id,
            placementValidation,
          }).then((data) => {
            this.makeQuery();
          });
        }
      }
    },
  },
};
</script>

<style>
.ellipsis {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
</style>

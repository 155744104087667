<template>
  <div class="container-">
    <RapportEditorDialog
      @save="saveAudit"
      v-if="activeItem != null"
      :title="
        activeItem != null && activeItem.id == null
          ? 'Ajouter un rapport'
          : 'Editer un rapport'
      "
      ref="editor"
      :item="activeItem"
    />
    <JoindreFichierUploader
      @save="fichierRecommandationUploaded"
      title="Joindre le fichier de recommandation"
      ref="fileUploader"
    />

    <AuditeurSelectorDialog
      ref="auditeurSelectorDialog"
      :title="'Sélectionner les auditeurs/commentateurs pour transmission'"
      @save="transmettreRapport"
    />
    <div class="row">
      <div class="col-md-12 mb-4">
        <template v-if="loading">
          <div class="card shadow-0 border-0 px-3">
            <div class="field col-12 md:col-6 mt-2" v-for="i in 3" :key="i">
              <PSkeleton class="mb-2"></PSkeleton>
              <PSkeleton width="10rem" class="mb-2"></PSkeleton>
              <PSkeleton width="5rem" class="mb-2"></PSkeleton>
              <PSkeleton height="2rem" class="mb-2"></PSkeleton>
              <PSkeleton width="10rem" class="mb-2" height="2rem"></PSkeleton>
            </div>
          </div>
        </template>
        <div v-else>
          <PMessage
            :sererity="'danger'"
            v-if="audits.length > 0 && audits[0].status === 'TRANSMIT_POUR_CORRECTION'"
            >Ce rapport a été soumis pour des remarques et commentaires. Vous devez lier
            le fichier de retour des corrections avant de pouvoir effectuer des
            modifications sur cet audit.<br />
            Pour lier le fichier des recommandations, cliquez sur le boutton suivant.
            <a
              @click.prevent="joindreRecommandation()"
              href="#"
              class="style_btn btn btn-warning"
              >Joindre le fichier des recommandations</a
            >
          </PMessage>
          <div
            class="card-booking-completed pa-5 mb-5 d-flex flex-column justify-content-center"
            :height="550"
          >
            <div>
              <template>
                <div
                  class="d-flex flex-wrap mb-4 justify-content-between align-items-center"
                >
                  <div class="my-1 flex-fill">
                    <h4>
                      Mission d' évaluation
                      <PTag
                        class="rounded"
                        rounded
                        :severity="getStatusColor(audits[0].status)"
                        v-if="audits.length > 0"
                        :value="audits[0].status"
                      />
                    </h4>
                  </div>
                  <div class="btn-group" v-if="audits.length == 0">
                    <a
                      @click.prevent="addAudit()"
                      href="#"
                      class="style_btn btn btn-warning"
                      >Ajouter un audit</a
                    >
                  </div>

                  <div class="btn-group ms-4">
                    <a
                      @click.prevent="cloturerCetAudit()"
                      href="#"
                      v-if="
                        audits.length > 0 && audits[0].status == 'APPLICATION_CORRECTION'
                      "
                      class="style_btn btn btn-danger"
                      >Clôturer l'audit</a
                    >
                    <a
                      v-if="
                        audits.length > 0 &&
                        ['NON_TRANSMIT', null].includes(audits[0].status)
                      "
                      @click.prevent="showTransmissionRapportDialog()"
                      href="#"
                      class="style_btn btn btn-warning"
                      >Transmettre le rapport</a
                    >
                    <PSplitButton
                      label="Imprimer"
                      :model="exportActions"
                      class="p-button-outlined p-button-primary"
                    />
                  </div>
                </div>
              </template>

              <div
                class="card mb-3 border-1"
                v-for="(audit, index) in audits"
                :key="index"
              >
                <div class="card-header" :class="audit.codeCouleurCritique">
                  <!-- bg-white -->
                  <h5
                    class="card- d-flex align-items-center justify-content-between text-white"
                  >
                    AUDIT
                    <slot name="title">
                      <div>
                        <!-- <span><b-btn class="me-2" size="xs" @click.prevent="downloadFiche(de)" variant="outline-success"><i class="bi bi-printer"></i></b-btn></span> -->
                        <span>
                          <b-btn
                            size="xs"
                            @click.prevent="updateAudit(audit)"
                            v-tooltip.top="'Modifier la mission d\'évaluation'"
                            variant="success"
                            class="m-1"
                            ><i class="bi bi-pencil"></i>
                          </b-btn>
                          <b-btn
                            size="xs"
                            @click.prevent="updateRapport(audit)"
                            v-tooltip.top="'Conclusion du rapport'"
                            variant="info"
                            class="m-1"
                            ><i class="bi bi-card-text"></i>
                          </b-btn>
                          <b-btn
                            size="xs"
                            @click.prevent="telechargerRapportAudit(audit)"
                            v-tooltip.top="'Télécharger PDF'"
                            variant="light"
                            class="m-1"
                            ><i class="bi bi-printer"></i>
                          </b-btn>
                          <b-btn
                            size="xs"
                            @click.prevent="doTelechargerRapportAuditDocx(audit)"
                            v-tooltip.top="'Télécharger Word'"
                            variant="outline-light"
                            outline
                            class="m-1"
                            ><i class="bi bi-file-earmark-word"></i>
                          </b-btn>
                          <b-btn
                            size="xs"
                            @click.prevent="doTelechargerPlanActionAudit(audit)"
                            v-tooltip.top="'Télécharger plan d\'action en PDF'"
                            variant="warning"
                            class="m-1"
                            ><i class="bi bi-file-earmark-word"></i>
                          </b-btn>
                        </span>
                      </div>
                    </slot>
                  </h5>
                  <p-progress-bar
                    v-if="downloading"
                    style="height: 0.33em"
                    mode="indeterminate"
                  />
                </div>
                <div class="card-body">
                  <div class="row">
                    <div class="col">
                      <div class="row">
                        <div class="col-sm-5">
                          <h6 class="mb-0">Nom du dirigeant :</h6>
                        </div>
                        <div class="col-sm-7 text-secondary">
                          {{ audit.nomDirigeant || "-" }}
                        </div>
                      </div>

                      <hr />
                      <div class="row">
                        <div class="col-sm-5">
                          <h6 class="mb-0">Lieu :</h6>
                        </div>
                        <div class="col-sm-7 text-secondary">
                          {{ audit.lieu || "-" }}
                        </div>
                      </div>
                      <hr />
                      <div class="row">
                        <div class="col-sm-5">
                          <h6 class="mb-0">Date début :</h6>
                        </div>
                        <div class="col-sm-7 text-secondary">
                          {{ audit.dateDebut | dayjs("DD-MM-YYYY") }}
                        </div>
                      </div>
                      <hr />
                      <div class="row">
                        <div class="col-sm-5">
                          <h6 class="mb-0">Date fin :</h6>
                        </div>
                        <div class="col-sm-7 text-secondary">
                          {{ audit.dateFin | dayjs("DD-MM-YYYY") }}
                        </div>
                      </div>
                      <hr />
                      <div class="row">
                        <div class="col-sm-5">
                          <h6 class="mb-0">Adresse du siège :</h6>
                        </div>
                        <div class="col-sm-7 text-secondary">
                          {{ audit.participants || "-" }}
                        </div>
                      </div>
                      <hr />
                      <div class="row">
                        <div class="col-sm-5">
                          <h6 class="mb-0">Commentaire :</h6>
                        </div>
                        <div class="col-sm-7 text-secondary">
                          {{ audit.commentaire || "-" }}
                        </div>
                      </div>
                      <hr />
                      <div class="row">
                        <div class="col-sm-5">
                          <h6 class="mb-0">Criticité :</h6>
                        </div>
                        <div class="col-sm-7 text-secondary">
                          <PTag class="badge" :class="audit.codeCouleurCritique">{{
                            audit.noteCriticite
                          }}</PTag>
                        </div>
                      </div>
                      <hr />
                      <div class="row">
                        <div class="col-sm-5">
                          <h6 class="mb-0">Risque :</h6>
                        </div>
                        <div class="col-sm-7 text-secondary">
                          <PTag class="badge" :class="audit.codeColeurRisque">{{
                            audit.noteRisque
                          }}</PTag>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="card mb-3 border-1" v-show="constats.length !== 0">
                  <div class="card-header bg-white">
                    <h5 class="card-title d-flex align-items-center">
                      Constats
                      <span>
                        <b-btn size="xs" v-if="false" variant="outline-warning"
                          ><i class="bi bi-edit"></i
                        ></b-btn>
                      </span>
                    </h5>
                  </div>
                  <div class="card-body">
                    <div class="row">
                      <div class="col-sm-12">
                        <div class="col-sm-12 text-secondary">
                          <table class="table table-borderless">
                            <thead class="border-bottom border-top">
                              <tr>
                                <th scope="col">Domaine</th>
                                <th scope="col">Constat</th>

                                <th scope="col">Criticité</th>
                                <th scope="col">Risque</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr v-for="(constat, index) in constats" :key="index">
                                <td>
                                  <span>
                                    {{ constat.domaineAnalyse.libelle || "-" }}</span
                                  >
                                </td>
                                <td>
                                  <span> {{ constat.libelle || "-" }}</span>
                                </td>

                                <td>
                                  <PTag
                                    class="badge"
                                    :class="constat.codeCouleurCritique"
                                    >{{ constat.noteCriticite }}</PTag
                                  >
                                </td>
                                <td>
                                  <PTag class="badge" :class="constat.codeColeurRisque">{{
                                    constat.noteRisque
                                  }}</PTag>
                                </td>

                                <!-- <table class="table table-borderless">
                            <thead class="border-bottom border-top">
                              <tr>
                                <th scope="col">Critères</th>
                                <th scope="col">Note D</th>
                                <th scope="col">Note O</th>
                                <th scope="col">Note S</th>
                              </tr>
                            </thead>
                           
                            <tbody>
                              <tr v-for="(
                          evaluation, index
                                ) in evaluationsConsat" :key="index">
                                <td>
                                  <span>
                                    {{ evaluation.libelle || "-" }}</span>

                                    
                                </td>
                                <td>
                                  <PTag class="badge" :class="evaluation.codeCouleurValiable">{{
                                      evaluation.note_d
                                  }}</PTag>

                                </td>
                                <td>
                                  <PTag class="badge" :class="evaluation.codeCouleurValiable">{{
                                      evaluation.note_o
                                  }}</PTag>

                                </td>
                                <td>
                                  <PTag class="badge" :class="evaluation.codeCouleurValiable">{{
                                      evaluation.note_s
                                  }}</PTag>

                                </td>
                              </tr>
                            </tbody>
                          </table> -->
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="card mb-3 border-1" v-show="repPlanactions.length !== 0">
                  <div class="card-header bg-white">
                    <h5 class="card-title d-flex align-items-center">
                      PLAN D’ACTION POUR LA LEVEE DES NON-CONFORMITES DE LA MISSION
                      D’APPUI-CONSEIL ENVIRONNEMENTAL ET SOCIAL
                      <span>
                        <b-btn size="xs" v-if="false" variant="outline-warning"
                          ><i class="bi bi-edit"></i
                        ></b-btn>
                      </span>
                    </h5>
                  </div>

                  <div class="card-body">
                    <div class="row">
                      <div class="col-sm-12">
                        <div class="col-sm-12 text-secondary">
                          <table class="table table-borderless">
                            <thead class="border-bottom border-top">
                              <tr>
                                <th scope="col">Constats(non-conformités)</th>
                                <th scope="col">Actions correctives</th>
                                <th scope="col">Date début</th>
                                <th scope="col">Echéancier de mise en œuvre</th>
                                <th scope="col">Responsable</th>

                                <th scope="col">Etat d’exécution</th>
                                <th scope="col">Commentaire</th>
                              </tr>
                            </thead>

                            <tbody v-for="(plan, index) in repPlanactions" :key="index">
                              <tr>
                                <td colspan="12" style="background-color: aqua">
                                  <strong>
                                    {{ plan.domaineAnalyse.libelle || "-" }}
                                    {{
                                      plan.domaineAnalyse.textLoi.libelle || "-"
                                    }}</strong
                                  >
                                </td>
                              </tr>
                              <tr v-for="(plan2, index) in plan.planActions" :key="index">
                                <td>
                                  <span>
                                    {{
                                      plan2.actionRequise ? plan2.constat.libelle : "-"
                                    }}</span
                                  >
                                </td>
                                <td>
                                  <span>
                                    {{
                                      plan2.actionRequise
                                        ? plan2.actionRequise.libelle
                                        : "-"
                                    }}</span
                                  >
                                </td>
                                <td>
                                  {{ plan2.dateMiseEnOeuvre || dayjs("DD-MM-YYYY") }}
                                </td>
                                <td>
                                  {{ plan2.echeance || dayjs("DD-MM-YYYY") }}
                                </td>
                                <td>
                                  <span> {{ plan2.responsable || "-" }}</span>
                                </td>

                                <td>
                                  <span> {{ plan2.etatMiseEnOeuvre || "-" }}</span>
                                </td>
                                <td>
                                  <span> {{ plan2.commentaire || "-" }}</span>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="card mb-3 border-1" v-show="atouts.length !== 0">
                  <div class="card-header bg-white">
                    <h5 class="card-title d-flex align-items-center">
                      LES ATOUTS
                      <span>
                        <b-btn size="xs" v-if="false" variant="outline-warning"
                          ><i class="bi bi-edit"></i
                        ></b-btn>
                      </span>
                    </h5>
                  </div>

                  <div class="card-body">
                    <div class="row">
                      <div class="col-sm-12">
                        <div class="col-sm-12 text-secondary">
                          <table class="table table-borderless">
                            <thead class="border-bottom border-top">
                              <tr>
                                <!-- <th scope="col">Constat</th> -->
                                <th scope="col">Libelle</th>

                                <th scope="col">Status</th>
                              </tr>
                            </thead>
                            <tbody v-for="(atout, index) in atouts" :key="index">
                              <tr>
                                <!-- <td>
                                  <span> {{ atout.constat || "-" }}</span>
                                </td> -->
                                <td>
                                  <span> {{ atout.libelle || "-" }}</span>
                                </td>
                                <td>
                                  <span> {{ atout.typeAtout || "-" }}</span>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="card mb-3 border-1" v-show="equipements.length !== 0">
                  <div class="card-header bg-white">
                    <h5 class="card-title d-flex align-items-center">
                      LISTE DES EQUIPEMENTS
                      <span>
                        <b-btn size="xs" v-if="false" variant="outline-warning"
                          ><i class="bi bi-edit"></i
                        ></b-btn>
                      </span>
                    </h5>
                  </div>

                  <div class="card-body">
                    <div class="row">
                      <div class="col-sm-12">
                        <div class="col-sm-12 text-secondary">
                          <table class="table table-borderless">
                            <thead class="border-bottom border-top">
                              <tr>
                                <th scope="col">Libelle</th>
                                <th scope="col">Quantite</th>

                                <th scope="col">Observation</th>
                                <th scope="col">Type d'equipement</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr v-for="(equipement, index) in equipements" :key="index">
                                <td>
                                  <span> {{ equipement.libelle || "-" }}</span>
                                </td>
                                <td>
                                  <span> {{ equipement.quantite || "-" }}</span>
                                </td>
                                <td>
                                  <span> {{ equipement.observation || "-" }}</span>
                                </td>
                                <td>
                                  <span> {{ equipement.typeEquipement || "-" }}</span>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- <div class="card mb-3 border-1">
                <div class="card-header bg-white">
                  <h5 class="card-title d-flex align-items-center">
                    ACTIONS REQUISES
                    <span>
                      <b-btn size="xs" v-if="false" variant="outline-warning"
                        ><i class="bi bi-edit"></i
                      ></b-btn>
                    </span>
                  </h5>
                </div>

                <div class="card-body">
                  <div class="row">
                    <div class="col-sm-12">
                      <div class="col-sm-12 text-secondary">
                        <table class="table table-borderless">
                          <thead class="border-bottom border-top">
                            <tr>
                              <th scope="col">Constat</th>
                              <th scope="col">Libelle</th>

                              <th scope="col">Type d'action</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr
                              v-for="(action, index) in actionrequises"
                              :key="index"
                            >
                              <td>
                                <span>
                                  {{ action.constat.libelle || "-" }}</span
                                >
                              </td>
                              <td>
                                <span> {{ action.libelle || "-" }}</span>
                              </td>

                              <td>
                                <span> {{ action.typeAction || "-" }}</span>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- </div> -->
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import YesOrNoBadge from "@/components/common/YesOrNoBadge.vue";
import AuditEditorDialog from "../../../components/espace/entreprise/audit/editor/dialog/AuditEditorDialog.vue";
import RapportEditorDialog from "../../../components/espace/entreprise/audit/editor/dialog/RapportEditorDialog.vue";
import JoindreFichierUploader from "../../../components/espace/entreprise/audit/editor/dialog/JoindreFichierUploader.vue";
import AuditeurSelectorDialog from "@/components/espace/entreprise/audit/editor/AuditeurSelectorDialog.vue";

export default {
  props: ["entrepriseId", "value"],
  components: {
    YesOrNoBadge,
    AuditEditorDialog,
    RapportEditorDialog,
    JoindreFichierUploader,
    AuditeurSelectorDialog,
  },
  data() {
    return {
      entreprise: this.value,
      audits: [],
      loading: true,
      downloading: false,
      activeItem: {},
      planactions: [],
      repPlanactions: [],
      codeCouleurCritique: "",
      exportActions: [
        {
          label: "PDF",
          icon: "pi pi-file-pdf",
          command: () => {
            // this.telechargerRapportAudit();
          },
        },
      ],
      exportActions: [
        {
          label: "Document Word",
          icon: "pi pi-file-docx",
          command: () => {},
        },
      ],
    };
  },
  created() {
    // fetchRapportsAudit;
    // this.entreprise = localStorage.getItem('subscription') != undefined? JSON.parse(localStorage.getItem('subscription')) : this.entreprise
    // this.currentStep = localStorage.getItem('currentStep') != undefined? localStorage.getItem('currentStep') : this.currentStep
  },
  watch: {
    entrepriseId: {
      handler() {
        if (this.entrepriseId != null) {
          this.loadData();
        }
      },
      immediate: true,
    },
  },
  computed: {
    ...mapGetters({
      //   audits: "audit/audits",
      constats: "constat/constats",
      dossier: "entreprise/entreprise",
      raisonEmploisMap: "setting/raisonEmploisMap",
      plageSalairesMap: "setting/plageSalairesMap",
      typeEmploisMap: "setting/typeEmploisMap",
      atouts: "atout/atouts",
      actionrequises: "actionrequise/actionrequises",
      // planactions: "planaction/planactions",
      evaluationsConsat: "evaluationConstat/evaluations",
      equipements: "equipement/equipements",
    }),
    de() {
      return this.entreprise;
      // eslint-disable-next-line no-unreachable
      // console.log(this.entreprise);
    },
  },
  methods: {
    ...mapActions({
      fetchEntrepriseAudits: "audit/fetchEntrepriseAudits",
      fetchRapportsAudit: "rapport/fetchRapportsAudit",
      // fetchDossier: "entreprise/fetchDossier",
      createOrUpdateAudit: "audit/createOrUpdateAudit",
      getEntrepriseConstat: "constat/getEntrepriseConstat",
      fetchActionRequisesByConstat: "actionrequise/fetchActionRequisesByConstat",
      fetchPlanactionsConstat: "planaction/fetchPlanactionsConstat",
      fetchEvaluationsByConstat: "evaluationConstat/fetchEvaluationsByConstat",
      fetchPlanactionsEntreprise: "planaction/fetchPlanactionsEntreprise",
      fetchPlanactionsEntrepriseDomaine: "planaction/fetchPlanactionsEntrepriseDomaine",
      fetchActionRequisesByEntreprise: "actionrequise/fetchActionRequisesByEntreprise",
      getAtoutsByEntreprise: "atout/getAtoutsByEntreprise",
      fetchEntrepriseEquipements: "equipement/fetchEntrepriseEquipements",
      fetchActionRequisesByConstat: "actionrequise/fetchActionRequisesByConstat",
      telechargerRapportAudit: "audit/telechargerRapportAudit",
      telechargerRapportAuditDocx: "audit/telechargerRapportAuditDocx",
      telechargerPlanActionAudit: "audit/telechargerPlanActionAudit",
      transmettreAuditPourCorrection: "audit/transmettreAuditPourCorrection",
      joindreFichierRecommandation: "audit/joindreFichierRecommandation",
      cloturerAudit: "audit/cloturerAudit",
    }),
    loadData() {
      if (this.entrepriseId) {
        this.loading = true;
        this.fetchEntrepriseAudits(this.entrepriseId).then(async (data) => {
          this.audits = data.content || [];

          this.getEntrepriseConstat(this.entrepriseId);
          this.fetchPlanactionsEntrepriseDomaine(this.entrepriseId).then((dataP) => {
            this.repPlanactions = dataP;
            // this.planactions = data;
          });
          // this.fetchActionRequisesByEntreprise(this.entrepriseId);
          this.getAtoutsByEntreprise(this.entrepriseId);
          this.fetchEntrepriseEquipements(this.entrepriseId);

          // this.fetchActionRequisesByConstat(this.const);
          // this.fetchActionRequisesByConstat(this.constat)
          // this.fetchPlanactionsConstat(this.constat.id)
          this.loading = false;
          // this.audit = { ...data };
        });
      }
    },
    doTelechargerRapportAudit(audit) {
      this.downloading = true;
      this.telechargerRapportAudit(audit)
        .then(() => {
          this.downloading = false;

          this.$toast.success("Téléchargement effectué avec succès!", {
            position: "bottom-right",
            duration: 8000,
          });
        })
        .catch((err) => {
          this.downloading = false;
          this.$toast.error("Une erreur est survenue au cours de l'opération. ", {
            position: "bottom-right",
            duration: 8000,
          });
        });
    },
    doTelechargerRapportAuditDocx(audit) {
      this.downloading = true;

      this.telechargerRapportAuditDocx(audit)
        .then(() => {
          this.downloading = false;

          this.$toast.success("Téléchargement effectué avec succès!", {
            position: "bottom-right",
            duration: 8000,
          });
        })
        .catch((err) => {
          this.downloading = false;
          this.$toast.error("Une erreur est survenue au cours de l'opération. ", {
            position: "bottom-right",
            duration: 8000,
          });
        });
    },
    joindreRecommandation() {
      this.$refs.fileUploader.show();
    },
    fichierRecommandationUploaded(fileUrl) {
      this.joindreFichierRecommandation({
        ...this.audits[0],
        fichierRecommandationUrl: fileUrl,
      });
    },
    showTransmissionRapportDialog() {
      this.$refs.auditeurSelectorDialog.show();
    },
    saveFile(filename, blob) {
      // see: https://stackoverflow.com/questions/19327749/javascript-blob-filename-without-link

      // get downloadable url from the blob
      const blobUrl = URL.createObjectURL(blob);

      // create temp link element
      let link = document.createElement("a");
      link.download = filename;
      link.href = blobUrl;

      // use the link to invoke a download
      document.body.appendChild(link);
      link.click();

      // remove the link
      setTimeout(() => {
        link.remove();
        window.URL.revokeObjectURL(blobUrl);
        link = null;
      }, 0);
    },
    editerDossier() {
      this.$emit("edit");
    },
    fileChanged(e) {
      console.log(e);
      this.entreprise.urlPhoto = e.previewUrl;
    },
    getStatusColor(status) {
      let color = "primary";
      if (status == "CLOTURE") {
        color = "danger";
      }
      if (status == "APPLICATION_CORRECTION") {
        color = "warning";
      }

      return color;
    },
    async transmettreRapport({ auditeurs }) {
      console.log(auditeurs);
      await this.$confirm.require({
        group: "confirmDialog",
        message: `Voulez-vous vraiment soumettre le rapport pour correction?`,
        header: "Confirmation",
        icon: "pi pi-exclamation-triangle",
        accept: () => {
          this.transmettreAuditPourCorrection({
            auditId: this.audits[0].id,
            auditeurs,
          });
        },
      });
    },
    async cloturerCetAudit() {
      await this.$confirm.require({
        group: "confirmDialog",
        message: `Voulez-vous vraiment clôturer l'audit?`,
        header: "Confirmation",
        icon: "pi pi-exclamation-triangle",
        accept: () => {
          this.cloturerAudit({
            ...this.audits[0],
          })
            .then(() => {
              this.$toast.success("Audit clôturé avec succès!", {
                position: "bottom-right",
                duration: 8000,
              });
            })
            .catch((err) => {
              this.$toast.error("Echec ", {
                position: "bottom-right",
                duration: 8000,
              });
            });
        },
      });
    },
    saveAudit(payload) {
      payload = {
        ...payload,
        entreprise: this.entreprise,
      };

      this.createOrUpdateAudit(payload)
        .then(() => {
          this.$toast.success("Enregistré ", {
            position: "bottom-right",
            duration: 8000,
          });
        })
        .catch((err) => {
          this.$toast.error("Echec ", {
            position: "bottom-right",
            duration: 8000,
          });
        });
    },
    saveRapport(payload) {
      payload = {
        ...payload,
        entreprise: this.entreprise,
      };
      console.log(payload);

      this.createOrUpdateAudit(payload)
        .then(() => {
          this.$toast.success("Enregistré ", {
            position: "bottom-right",
            duration: 8000,
          });
        })
        .catch((err) => {
          this.$toast.error("Echec ", {
            position: "bottom-right",
            duration: 8000,
          });
        });
    },
    addAudit() {
      this.activeItem = {};
      this.$refs.editor.show();
    },
    updateAudit(payload) {
      this.activeItem = { ...payload };
      this.$refs.editor.show();
    },
    updateRapport(payload) {
      this.activeItem = { ...payload };
      this.$refs.editor.show();
    },
    profileUpdate(e) {
      console.log(e);
      this.entreprise = {
        ...this.entreprise,
        fullPhotoUrl: e.fullPhotoUrl,
      };
    },
  },
};
</script>

<style>
.card-title {
  color: #1a32c8 !important;
  /* color: #1A32C8 !important; */
  text-transform: uppercase;
}

hr {
  color: #bfa9a9;
  margin: 10px 5px;
}

.vert {
  background-color: #00561b;
  /* color: rgb(5, 0, 0); */
}

.jaune {
  background-color: #ffff00;
  color: black;
}

.orange {
  background-color: #faa401;
}

.rouge {
  background-color: #ff0000;
}
</style>
